import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, SelectChangeEvent } from '@mui/material';
import { RcFile } from 'antd/lib/upload';

import { showAxiosError } from '@utils';
import { useWindowSize } from '@hooks';
import { productHttp, s3http } from '@network';
import { productInvoiceHttp } from '@network/product-invoice-http';
import { CreditInvoiceFile, OrderCompositionProduct } from '@types';
import { FileImage } from '@components/FileControlled/file-image';
import { InputSelect } from '@components/inputs/InputSelect';
import { InputText } from '@components/inputs/InputText';
import { FileUpload, Scroll } from '@components';
import cls from './product-creator.module.css';

const initialInvoiceProduct = {
  name: '',
  externalShop: '',
  brand: '',
  category: '',
  subcategory: '',
  description: '',
  pdfLink: '',
};

type FormValues = typeof initialInvoiceProduct;

interface Props {
  setIsActive: (value: boolean) => void;
  addPosition: (arg: OrderCompositionProduct) => void;
  fetchProducts: () => Promise<void>;
  externalShop?: string;
}

export const ProductCreator = ({
  setIsActive, addPosition, fetchProducts, externalShop,
}: Props) => {
  const [formValues, setFormValues] = useState<FormValues>(initialInvoiceProduct);
  const [brands, setBrands] = useState<FormSelectOption[]>([]);
  const [categories, setCategories] = useState<FormSelectOption[]>([]);
  const [subCategories, setSubCategories] = useState<FormSelectOption[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<CreditInvoiceFile | null>(null);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const { height } = useWindowSize();

  const uploadPicture = async (rcFile: RcFile) => {
    setLoading(true);
    try {
      const file = await s3http.uploadFile(rcFile as File, true);

      setImage(file);

      return file.adminUrl;
    } catch (err: any) {
      showAxiosError(err);

      return '';
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | null>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const submit = async () => {
    const brandId = brands.find(i => i.name === formValues.brand)?.id;
    const categoryId = categories.find(i => i.name === formValues.category)?.id;
    const subcategoryId = subCategories.find(i => i.name === formValues.subcategory)?.id;

    const params = {
      externalShop: externalShop || formValues.externalShop,
      image: image?.url,
      name: formValues.name,
      description: formValues.description,
      pdfLink: formValues.pdfLink,
      brandId: +(brandId || 0),
      categoryId: +(categoryId || 0),
      subcategoryId: +(subcategoryId || 0),
    };

    if (!Object.values(params).every(Boolean)) {
      setShowErrors(true);
      return;
    }

    if (!!brandId && !!categoryId && !!subcategoryId && !!image) {
      try {
        const product = await productHttp.create(params);
        await fetchProducts();
        setIsActive(false);
        addPosition({
          id: product.id,
          productId: product.id,
          qty: 1,
          productPrice: 0,
          externalProduct: product.name,
          externalShop: product.externalShop || '',
          isExternal: true,
          deliveryId: null,
          product,
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    void (async () => {
      try {
        const fetchCategories = productInvoiceHttp.getCategories();
        const fetchSubCategories = productInvoiceHttp.getSubCategories();
        const fetchBrands = productInvoiceHttp.getBrands();
        const [loadedCategories, loadedSubCategories, loadedBrands] =
          await Promise.all([fetchCategories, fetchSubCategories, fetchBrands]);

        setCategories(loadedCategories?.map((i: any) => ({ id: i.id, name: i.name })) || []);
        setSubCategories(loadedSubCategories?.map((i: any) => ({ id: i.id, name: i.name })) || []);
        setBrands(loadedBrands?.map((i: any) => ({ id: i.id, name: i.name })) || []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const showNameError = formValues.name.length === 0 && showErrors;
  const showExternalShopError = formValues.externalShop.length === 0 && showErrors;
  const showBrandError = formValues.brand.length === 0 && showErrors;
  const showCategoryError = formValues.category.length === 0 && showErrors;
  const showSubcategoryError = formValues.subcategory.length === 0 && showErrors;
  const showDescriptionError = formValues.description.length === 0 && showErrors;
  const showPdfLinkError = formValues.pdfLink.length === 0 && showErrors;
  const showFileError = !image && showErrors;

  return (
    <Scroll height={height}>
      <div className={cls._} id="wrap">
        <div className={cls.wrap}>
          <div className={cls.close} onClick={() => setIsDialogOpen(true)} />
          <div className={cls.title}>Product creator</div>

          {image ? (
            <div className={cls.uploadedBox}>
              <FileImage
                url={image.adminUrl}
                width={86}
                height={105}
                className={cls.image}
              />
            </div>
          ) : (
            <FileUpload uploadAction={uploadPicture} className={cls.uploadBox} type="image" />
          )}

          {showFileError && (
            <div className={cls.fileError}>Please, upload an image</div>
          )}

          <div className={cls.form}>
            <div className={cls.row}>
              <InputText
                name="name"
                label="Name"
                onChange={onChange}
                value={formValues.name}
                error={showNameError ? 'This field is required' : ''}
              />
            </div>
            <div className={cls.row}>
              <InputText
                name="externalShop"
                label="External shop"
                onChange={onChange}
                value={formValues.externalShop}
                error={showExternalShopError ? 'This field is required' : ''}
              />
            </div>
            <div className={cls.row}>
              <InputSelect
                name="brand"
                label="Brand"
                onChange={onChange}
                values={brands.map(b => b.name)}
                selectedValue={formValues.brand}
                error={showBrandError ? 'This field is required' : ''}
              />
            </div>
            <div className={cls.row}>
              <InputSelect
                name="category"
                label="Category"
                onChange={onChange}
                values={categories.map(b => b.name)}
                selectedValue={formValues.category}
                error={showCategoryError ? 'This field is required' : ''}
              />
            </div>
            <div className={cls.row}>
              <InputSelect
                name="subcategory"
                label="Subategory"
                onChange={onChange}
                values={subCategories.map(b => b.name)}
                selectedValue={formValues.subcategory}
                error={showSubcategoryError ? 'This field is required' : ''}
              />
            </div>
            <div className={cls.row}>
              <InputText
                name="description"
                label="Description"
                onChange={onChange}
                value={formValues.description}
                error={showDescriptionError ? 'This field is required' : ''}
              />
            </div>
            <div className={cls.row}>
              <InputText
                name="pdfLink"
                label="PDF Link"
                onChange={onChange}
                value={formValues.pdfLink}
                error={showPdfLinkError ? 'This field is required' : ''}
              />
            </div>
          </div>

          <div className={cls.actions}>
            <Button variant="contained" className={cls.submit} onClick={submit}>Add product</Button>
          </div>
        </div>

        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          Are you sure?
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)}>
            No
            </Button>
            <Button onClick={() => setIsActive(false)} autoFocus>
            Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className={cls.overlay} onClick={() => setIsDialogOpen(true)} />
    </Scroll>
  );
};

interface FormSelectOption {
  id: number;
  name: string
}
