import { useNotify } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface Props {
  url?: string | null;
  text?: string | null;
}

export const ProfileLink = ({ url, text }: Props) => {
  const cls = useStyles();
  const notify = useNotify();
  const isLink = url && url.length > 10;

  const onLinkClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (url) {
      navigator.clipboard.writeText(url);
      notify('URL-address is copied to Clipboard');
    }
  };

  const Content = (
    <Button variant="outlined" color="secondary" className={cls.btn}>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'block' }}
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none">
        <path d="M19 0C8.50929 0 0 8.50929 0 19C0 29.4907 8.50929 38 19 38C29.4907 38 38 29.4907 38 19C38 8.50929 29.4907 0 19 0ZM19 9.5C23.275 9.5 26.7493 12.9743 26.7493 17.2493C26.7493 21.5243 23.275 24.9986 19 24.9986C14.725 24.9986 11.2507 21.5243 11.2507 17.2493C11.2507 12.9743 14.725 9.5 19 9.5ZM19 35.5571C15.1457 35.5571 11.59 34.2271 8.76714 32.0014C9.77143 30.5629 11.4271 29.6943 13.1914 29.6943H24.8086C26.5729 29.6943 28.2286 30.5629 29.2329 32.0014C26.41 34.2271 22.8543 35.5571 19 35.5571Z"
          fill="#042E6B" />
      </svg>
      <span className={cls.url}>{text || url || '–'}</span>

      <IconButton onClick={onLinkClick} className={cls.copyBtn}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ display: 'block' }}
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none">
          <circle cx="19" cy="19" r="19" fill="#002A77" />
          <path d="M21.9872 20.8821C22.3037 21.1986 22.8151 21.1986 23.1316 20.8821L24.7548 19.2589C26.4186 17.5951 26.4186 14.9005 24.7548 13.2448C23.091 11.5891 20.3964 11.581 18.7408 13.2448L17.1175 14.868C16.801 15.1846 16.801 15.6959 17.1175 16.0124C17.4341 16.329 17.9454 16.329 18.2619 16.0124L19.8851 14.3892C20.9159 13.3584 22.5797 13.3584 23.6104 14.3892C24.6412 15.4199 24.6412 17.0838 23.6104 18.1145L21.9872 19.7377C21.6707 20.0543 21.6707 20.5656 21.9872 20.8821Z"
            fill="white" />
          <path d="M12.2479 25.7516C13.9117 27.4155 16.6062 27.4155 18.2619 25.7516L19.8852 24.1284C20.2017 23.8119 20.2017 23.3006 19.8852 22.984C19.5686 22.6675 19.0573 22.6675 18.7408 22.984L17.1175 24.6073C16.0868 25.638 14.423 25.638 13.3922 24.6073C12.3615 23.5765 12.3615 21.9127 13.3922 20.882L15.0155 19.2587C15.332 18.9422 15.332 18.4309 15.0155 18.1144C14.6989 17.7978 14.1876 17.7978 13.8711 18.1144L12.2479 19.7376C10.584 21.4014 10.584 24.096 12.2479 25.7516Z"
            fill="white" />
          <path d="M16.6386 22.5054C16.3221 22.8219 15.8108 22.8219 15.4942 22.5054C15.1777 22.1888 15.1777 21.6775 15.4942 21.361L20.3639 16.4913C20.6805 16.1748 21.1918 16.1748 21.5083 16.4913C21.8248 16.8078 21.8248 17.3192 21.5083 17.6357L16.6386 22.5054Z"
            fill="white" />
        </svg>
      </IconButton>
    </Button>
  );

  return isLink ? (<a href={url} rel="noreferrer" target="_blank">{Content}</a>) : Content;
};

const useStyles = makeStyles({
  btn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 52,
    borderRadius: 26,
    padding: '7px 8px',
  },
  url: {
    color: '#000',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'none',
  },
  copyBtn: {
    marginRight: -9,
  },
});
