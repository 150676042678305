import { useEffect, useState } from 'react';
import { useRefresh, useUpdate } from 'react-admin';
import { PaymentOutlined } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { InputNumber, Modal, Form } from 'antd';
import { useController } from 'react-hook-form';

import { useRequest } from '@hooks';
import { Order } from '@types';
import { showAxiosError } from '@utils';

export const RequestPaymentButton = ({ order }: {order: Order}) => {
  const refresh = useRefresh();
  const {
    field: { value: composition },
  } = useController({ name: 'composition' });
  const {
    field: { value: paymentLivesHours },
  } = useController({ name: 'paymentLivesHours' });
  const [requestHours, setRequestHours] = useState(paymentLivesHours || 24);

  const changeHours = (num: any) => {
    setRequestHours(num);
  };

  const data = {
    ...order,
    composition,
    requestHours,
  };
  const [update, { error }] = useUpdate('order', { id: order?.id, data });
  const cls = useStyles();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    error && showAxiosError(error as any);
  }, [error]);

  const { loading, submit } = useRequest(async () => {
    await update();
    setIsModalVisible(false);
    refresh();
  });

  if (!order) {
    return null;
  }

  return (
    <>
      <Button
        className={cls.button}
        variant="outlined"
        size="small"
        type="button"
        onClick={() => setIsModalVisible(true)}
        color="primary"
        aria-label="Cancel"
      >
        <PaymentOutlined className={cls.icon} />
        Request Quotation
      </Button>

      <Modal
        title="Request Quotation"
        open={isModalVisible}
        onOk={submit}
        onCancel={() => setIsModalVisible(false)}
        okText="Yes"
        cancelText="No"
        confirmLoading={loading}
      >
        <Form.Item label="Quotation prices available hours">
          <InputNumber value={requestHours} onChange={changeHours} />
        </Form.Item>
        <p>
          Do you really want to save product prices and make a request for a quotation confirmation?
        </p>
      </Modal>
    </>
  );
};

const useStyles = makeStyles({
  button: {
    color: 'green',
    marginRight: 15,
  },
  icon: {
    fontSize: 22,
    color: 'green',
    marginRight: 6,
  },
});
