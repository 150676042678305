import { Http } from './http';
import { SurveyQuestion } from '@types';

class SurveyHttp extends Http {

  async getCreditSurvey(creditId: number): Promise<GetCreditSurveyResponse> {
    const res = await this.get<GetCreditSurveyResponse>(`/survey/${creditId}`);

    return res.data;
  }

  async updateCreditSurvey(
    creditId: number, questionId: number, params: UpdateSurveyDTO,
  ): Promise<GetCreditAnswerResponse> {
    const url = params.rows ? 'answer-table' : 'answer';
    const res = await this.put<GetCreditAnswerResponse>(`/survey/${creditId}/${url}/${questionId}`, params);

    return res.data;
  }
}

export const surveyHttp = new SurveyHttp();

interface GetCreditSurveyResponse {
  surveyQuestions: SurveyQuestion[];
  answeredDate: Date;
  isFixed: boolean;
}

interface GetCreditAnswerResponse {
  surveyQuestions: SurveyQuestion;
  answeredDate: Date;
}

export interface CreditSurveyTableColProps {
  answerId?: number;
  customAnswer?: string;
}

export interface CreditSurveyTableRowProps {
  cols: CreditSurveyTableColProps[];
}

interface UpdateSurveyDTO {
  customAnswer?: string;
  answerIds?: number[];
  rows?: CreditSurveyTableRowProps[]
}
