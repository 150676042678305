import React from 'react';
import { clsx } from 'clsx';
import { Form, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { marriageOptions } from '@types';
import { MonthYearInput } from '@components';

import cls from '@pages/credit/CreditEdit/CreditGuarantor/credit.guarantor.module.css';

export const PHONE_CODE_REG_EXP = /^\+52|^\+7/;

interface CreditGuarantorFormProps {
  onRemove: () => void;
}

export const CreditGuarantorForm = ({ onRemove }: CreditGuarantorFormProps) => {
  return (
    <div className={cls.info}>
      <div className={cls.infoBody}>
        <div className={cls.infoTitle}>Information</div>
        <div className={cls.infoProps}>
          <Form.Item
            label="First name"
            name="firstName"
            className={cls.row}
            rules={[
              { required: true, message: 'First name is mandatory field' },
              { max: 25, message: 'First name should be less then 25 characters' },
            ]}
          >
            <Input className={cls.input} />
          </Form.Item>
          <Form.Item
            label="Middle name"
            name="middleName"
            className={cls.row}
            rules={[
              { required: true, message: 'Middle name is mandatory field' },
              { max: 25, message: 'Middle name should be less then 25 characters' },
            ]}
          >
            <Input className={cls.input} />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="lastName"
            className={cls.row}
            rules={[
              { required: true, message: 'Last name is mandatory field' },
              { max: 25, message: 'Last name should be less then 25 characters' },
            ]}
          >
            <Input className={cls.input} />
          </Form.Item>
          <Form.Item
            label="Civil status"
            name="marriage"
            className={cls.row}
            rules={[
              { required: true, message: 'Civil status is mandatory field' },
            ]}
          >
            <Select>
              {marriageOptions.map(opt => (
                <Select.Option key={opt.id} value={opt.id}>{opt.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            className={cls.row}
            rules={[
              () => ({
                validator(_, value) {
                  const phone = value?.replace(PHONE_CODE_REG_EXP, '').replace(/\D/g, '') || '';
                  if (phone.length < 10) {
                    return Promise.reject(new Error('Type in a correct phone'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <MaskedInput mask="+52 (000) 000-0000" className={cls.input} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            className={cls.row}
            rules={[
              () => ({
                validator(_, value) {
                  const reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/gi;
                  if (!reg.test(value)) {
                    return Promise.reject(new Error('Type in a correct email'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input className={cls.input} />
          </Form.Item>
        </div>
      </div>
      <div className={cls.aside}>
        <div className={cls.asideProps}>
          <Form.Item
            label="Relation with creditor"
            name="relation"
            className={clsx(cls.row, cls.rowAside)}
            rules={[
              { required: true, message: 'Relation with creditor is mandatory field' },
              { max: 100, message: 'Relation should be less then 100 characters' },
            ]}
          >
            <Input className={cls.input} />
          </Form.Item>
          <Form.Item
            label="Profession"
            name="profession"
            className={clsx(cls.row, cls.rowAside)}
            rules={[
              { required: true, message: 'Profession is mandatory field' },
              { max: 100, message: 'Profession should be less then 100 characters' },
            ]}
          >
            <Input className={cls.input} />
          </Form.Item>
          <Form.Item
            label="Work since"
            name="workSince"
            className={clsx(cls.row, cls.rowAside)}
            rules={[
              { required: true, message: 'Work since is mandatory field' },
            ]}
          >
            <MonthYearInput name="workSince" />
          </Form.Item>
        </div>
        <div className={cls.asideActions}>
          <Button variant="contained" color="secondary" className={cls.submit} type="submit">
            <SaveIcon /> Save
          </Button>
          <Button variant="contained" color="secondary" className={cls.remove} onClick={onRemove}>
              Delete
          </Button>
        </div>
      </div>
    </div>
  );
};
