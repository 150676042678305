import { Spin, Space } from 'antd';
import { makeStyles } from '@mui/styles';

export const Loader = ({ center, mini }: {center?: boolean, mini?: boolean}) => {
  const cls = useStyles();

  if (center) {
    return (
      <div className={cls.center}>
        <Spin size={mini ? 'small' : 'large'} />
      </div>
    );
  }

  return (
    <Space size="middle">
      <Spin size={mini ? 'small' : 'large'} />
    </Space>
  );
};

const useStyles = makeStyles({
  center: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
