import { FunctionField, FunctionFieldProps } from 'react-admin';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Button } from 'antd';

import { dateFormatter, moneyFormatter } from '@utils';
import { resolvePath } from '@components';

interface Transaction {
  id: number;
  title: string;
  type: 'income' | 'outcome';
  createdAt: string;
  amount: number;
  partnerClass: string;
  partnerId?: number;
  adminName?: string;
  balanceBefore: number;
  balanceAfter: number;
}

const TransactionItem = ({ item }: {item: Transaction}) => {
  const cls = useStyles();

  return (
    <div className={cls.item}>
      <div className={cls.row}>
        <span className={cls.timestamp}>{dateFormatter.toDateTime(item.createdAt)}</span>
        <span className={cls.title}>{item.title}</span>
        {item.adminName && <span className={cls.adminName}>by {item.adminName}</span>}
      </div>
      <div className={cls.row}>
        {item.type === 'income' && (
          <Chip color="primary" size="small" label={`+ ${ moneyFormatter.format(item.amount)}`} />)
        }
        {item.type === 'outcome' && (
          <Chip size="small" label={`- ${ moneyFormatter.format(item.amount)}`} />)
        }
        <div className={cls.statuses}>
          {moneyFormatter.format(item.balanceBefore)} → {moneyFormatter.format(item.balanceAfter)}
        </div>
      </div>
      <div>
      </div>
    </div>
  );
};

export const Transactions = ({ transactions }: {transactions: Transaction[]}) => {
  const cls = useStyles();
  const [isAll, setIsAll] = useState(false);
  const show = () => setIsAll(true);

  if (!Array.isArray(transactions) || transactions.length === 0) {
    return null;
  }

  const maxVisible = 4;
  const visibleTransactions = transactions.slice(0, maxVisible);
  const hiddenTransactions = transactions.slice(maxVisible);

  return (
    <div className={cls.box} onClick={e => e.stopPropagation()}>
      {visibleTransactions.map(item => <TransactionItem key={item.id} item={item} />)}
      {hiddenTransactions.length > 0 && (
        <>
          {!isAll && (
            <Button size="small" onClick={show}>
              show previous ({hiddenTransactions.length})
            </Button>
          )}
          {isAll && hiddenTransactions.map(item => <TransactionItem key={item.id} item={item} />)}
        </>
      )}
    </div>
  );
};

export const OrderTransactionsField = (props: Partial<FunctionFieldProps>) => {
  return (
    <FunctionField
      sortable={false}
      {...props}
      render={(record: any) => (
        <Transactions transactions={resolvePath(record, props.source || '')} />
      )}
    />
  );
};

const useStyles = makeStyles({
  box: {
    paddingBottom: 15,
  },
  item: {
    marginBottom: 15,
  },
  row: {
    display: 'flex',
    fontSize: 12,
    marginBottom: 5,
    lineHeight: '1.1em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  timestamp: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
    fontSize: 10,
    lineHeight: '1em',
    padding: '3px 5px',
    backgroundColor: 'beige',
    border: '1px solid #CCC',
    whiteSpace: 'nowrap',
  },
  statuses: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    fontSize: '13px',
    lineHeight: '13px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '1.1em',
    color: '#002a77',
  },
  adminName: {
    marginLeft: 5,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '1.1em',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    color: 'blueviolet',
  },
});
