import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useResourceContext } from 'react-admin';
import { useController } from 'react-hook-form';

import { http } from '@network';

interface Props {
  source: string;
  label?: string;
}

export const LoadAutocompleteInput = ({ source, label }: Props) => {
  const {
    fieldState: { isTouched, error },
    field: { value, onChange },
  } = useController({ name: source });
  const resource = useResourceContext();

  // Options
  const [options, setOptions] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      const url = `${resource}/options/${source}`;
      const response = await http.get<string[]>(url);
      setOptions(response.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputLabel = label || source.substr(0, 1).toUpperCase().concat(source.substr(1));

  return (
    <FormControl fullWidth>
      <Autocomplete
        fullWidth
        freeSolo
        options={options}
        onSelect={onChange}
        value={value}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            name={source}
            onChange={onChange}
            label={inputLabel}
            error={!!(isTouched && error)}
            helperText={isTouched && error}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            size="small"
          />
        )}
      />
      <FormHelperText> </FormHelperText>
    </FormControl>
  );
};
