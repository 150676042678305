import jsonExport from 'jsonexport/dist';
import { downloadCSV, Exporter, useResourceContext } from 'react-admin';

import { moneyFormatter, dateFormatter, LocalStorage } from '@utils';

type TransformFn = (value: string | number | any) => string;

interface ExportOption {
  label?: string;
  prop?: string;
  transform?: 'money' | 'date' | 'datetime' | 'phone' | 'boolean'
    | 'uppercase' | 'percent' | 'files' | 'spaced' | TransformFn;
  manyProp?: string;
  hide?: boolean;
}

interface Options {
  props: {[key: string]: ExportOption};
  filename: string;
}

export const useExporter = ({ props, filename }: Options): Exporter => {
  const resource = useResourceContext();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5777';

  const exporter: Exporter = async (records: any[]) => {
    const selection = LocalStorage.get(`${resource}`);
    const selectionLength = Object.keys(selection || {}).length;

    const mappedRecords = records.map(record => {
      const exportObj: {[key: string]: string | number} = {};

      for (const [key, option] of Object.entries(props)) {
        if (option.hide) continue;
        if (selectionLength === 0 || selection[key]) {
          let value = recompose(record, option.prop || key);
          if (typeof option.manyProp === 'string') {
            value = (value || [])
              .map((v: any) => v[option.manyProp as string])
              .join(', ');
          }
          if (option.transform === 'money') {
            value = moneyFormatter.format(value || 0);
          }
          if (option.transform === 'date') {
            value = value ? dateFormatter.toDateSlash(value) : null;
          }
          if (option.transform === 'datetime') {
            value = value ? dateFormatter.toDateSlash(value, true) : null;
          }
          if (option.transform === 'phone') {
            value = value ? phoneTransformer(value) : null;
          }
          if (option.transform === 'boolean') {
            value = value ? 'yes' : 'no';
          }
          if (option.transform === 'uppercase') {
            const strValue = `${value}`;
            value = strValue.charAt(0).toUpperCase() + strValue.slice(1);
          }
          if (option.transform === 'percent') {
            value = `${value}%`;
          }
          if (option.transform === 'files' && Array.isArray(value)) {
            const links: string[] = [];
            value.forEach((v: any) => {
              if (v?.adminUrl) {
                links.push(`${apiUrl}${v.adminUrl}`);
              } else if (v?.file?.adminUrl) {
                links.push(`${apiUrl}${v.file.adminUrl}`);
              }
            });
            value = links.join('\n');
          }
          if (option.transform === 'spaced' && value) {
            value = `="${value}"`;
          }
          if (typeof option.transform === 'function') {
            value = option.transform(value);
          }
          const exportKey = option.label || key;
          exportObj[exportKey] = value;
        }
      }

      return exportObj;
    });

    jsonExport(mappedRecords, {}, (err, csv) => {
      downloadCSV(csv, filename);
    });
  };

  return exporter;
};

function recompose(obj: any, string: any): any {
  if (obj === null || obj === undefined) {
    return null;
  }

  const parts = string.split('.');
  const newObj = obj[parts[0]];

  if (parts[1]) {
    parts.splice(0, 1);
    const newString = parts.join('.');

    return recompose(newObj, newString);
  }

  return newObj;
}

function phoneTransformer(phone: any): string | null {
  if (!phone) {
    return null;
  }

  const countryCode = phone.slice(0, phone.length - 10);
  let f = phone.replace(/\D/g, '').slice(phone.length - 11);

  if (f[9] !== undefined) {
    f = `${countryCode
    } (${f[0]}${f[1]}${f[2]}) ${f[3]}${f[4]}${f[5]}-${
      f[6]}${f[7]}${f[8]}${f[9]}`;
  }

  return f;
}
