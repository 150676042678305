import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import { ImageField as RaImageField, ImageFieldProps } from 'react-admin';
import 'react-medium-image-zoom/dist/styles.css';

interface Extra {
  width?: number;
  round?: boolean;
  maxHeight?: number;
}

export const ImageField = (props: ImageFieldProps & Extra) => {
  const { width, round, maxHeight, ...restProps } = props;
  const useStyles = makeStyles({
    imageField: {
      '& img': {
        maxWidth: width || 200,
        maxHeight: maxHeight || 250,
        borderRadius: round ? '50%' : undefined,
      },
    },
  });
  const cls = useStyles();

  const [isZoomed, setIsZoomed] = useState(false);
  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);
  const onClick = useCallback(e => {
    e.stopPropagation();
    setIsZoomed(!isZoomed);
  }, [isZoomed]);

  return (
    <div onClick={onClick}>
      <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        <RaImageField {...restProps} className={cls.imageField} />
      </ControlledZoom>
    </div>
  );
};
