import React, { useEffect, useState } from 'react';
import { useNotify, useRedirect, useRefresh } from 'react-admin';
import { clsx } from 'clsx';
import moment from 'moment-timezone';
import 'moment/locale/es';

import { axiosErrorToString } from '@utils';
import { creditHttp } from '@network';
import { InitialPage } from '@components';
import { Credit, CreditInvoice, CreditInvoiceStatusType, OrderCompositionProduct } from '@types';
import { CreditInvoiceStatus } from '@pages/credit/CreditEdit/CreditInvoices/CreditInvoiceStatus';
import { CreditInvoiceNote } from '@pages/credit/CreditEdit/CreditInvoices/CreditInvoiceNotes';
import { CreditInvoiceUser } from '@pages/credit/CreditEdit/CreditInvoices/CreditInvoiceUser';
import { CreditInvoiceCompany } from '@pages/credit/CreditEdit/CreditInvoices/CreditInvoiceCompany';
import { CreditInvoiceProducts } from '@pages/credit/CreditEdit/CreditInvoices/CreditInvoiceProducts';
import cls from './credit-invoices.module.css';

const initialFormValues = {
  company: '',
  state: '',
  comment: '',
  status: CreditInvoiceStatusType.PENDING,
};

type FormValues = typeof initialFormValues;

interface CreditInvoiceDetailProps {
  credit: Credit;
  invoiceId: number;
}

export const CreditInvoicesDetail = ({ credit, invoiceId }: CreditInvoiceDetailProps) => {
  moment.locale('en');

  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();

  const [invoice, setInvoice] = useState<CreditInvoice | null>(null);
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [composition, setComposition] = useState<OrderCompositionProduct[]>([]);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);

  const date = invoice ? moment(invoice.createdAt).format('DD MMM YYYY, HH:mm') : '';
  const commentedDate = invoice ? moment(invoice.commentedAt).format('DD MMM YYYY, HH:mm') : '';

  const updateValues = (e: any): void => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const updateStatus = (status: CreditInvoiceStatusType): void => {
    setFormValues({
      ...formValues,
      status,
    });
  };

  const submit = async (creditId?: number | undefined) => {
    if (formValues.company === '' || formValues.state === '') {
      setShowErrors(true);

      return;
    }

    try {
      await creditHttp.updateInvoice(invoiceId, {
        ...formValues,
        composition: composition.map(position => ({
          ...position,
          externalShop: formValues.company,
        })),
        creditId,
      });
      redirect(`/credit/${credit.id}?invoices`);
      refresh();
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  useEffect(() => {
    setFormValues({
      company: invoice?.company || '',
      state: invoice?.state || '',
      status: invoice?.status as CreditInvoiceStatusType || CreditInvoiceStatusType.PENDING,
      comment: invoice?.comment || '',
    });
  }, [invoice]);

  useEffect(() => {
    void (async () => {
      try {
        const loadedInvoice = await creditHttp.getInvoiceById(invoiceId);
        setInvoice(loadedInvoice);
        setComposition(loadedInvoice.positions.map((position, index) => ({
          id: index,
          productId: position.product.id,
          qty: position.qty,
          productPrice: position.productPrice,
          isExternal: true,
          externalProduct: position.product.name,
          product: position.product,
        })));
      } catch (e) {
        notify(axiosErrorToString(e));
      }
    })();
  }, []);

  const backTo = `/credit/${credit.id}?invoices`;

  if (!invoice) {
    return null;
  }

  return (
    <InitialPage title="Invoice validator" backTo={backTo} backText="Back to Invoice manager">
      <div className={clsx(cls.detail, 'invoice-validator')}>
        <div className={cls.detailCard}>
          <div className={cls.detailCardHead}>
            <div className={cls.detailCardTitle}>Invoice of</div>
            <div className={cls.detailCardDate}>Update {date}</div>
          </div>
          <div className={cls.detailCardContent}>
            <CreditInvoiceUser credit={credit} />
          </div>
        </div>
        <div className={cls.detailCard}>
          <div className={cls.detailCardHead}>
            <div className={cls.detailCardTitle}>External Shop</div>
          </div>
          <div className={cls.detailCardContent}>
            <CreditInvoiceCompany
              company={formValues.company}
              state={formValues.state}
              updateValues={updateValues}
              showErrors={showErrors}
            />
          </div>
        </div>
        <div className={clsx(cls.detailCard, cls.fullWidth)}>
          <div className={cls.detailCardHead}>
            <div className={cls.detailCardTitle}>On the invoice</div>
          </div>
          <div className={cls.detailCardContent}>
            <CreditInvoiceProducts
              composition={composition}
              setComposition={setComposition}
              externalShop={formValues.company}
              setInvoiceTotal={setInvoiceTotal}
            />
          </div>
        </div>
        <div className={clsx(cls.detailCard, cls.detailCardNote)}>
          <div className={cls.detailCardHead}>
            <div className={cls.detailCardTitle}>Invoice note</div>
          </div>
          <div className={cls.detailCardContent}>
            <CreditInvoiceNote
              invoice={invoice}
              creditCode={credit.code}
              file={invoice.file}
              date={commentedDate}
              comment={formValues.comment}
              updateValues={updateValues}
            />
          </div>
        </div>
        <div className={cls.detailCard}>
          <CreditInvoiceStatus
            currentStatus={formValues.status as CreditInvoiceStatusType}
            updateStatus={updateStatus}
            submit={submit}
            credit={credit}
            invoiceTotal={invoiceTotal}
          />
        </div>
      </div>
    </InitialPage>
  );
};
