import { Col, Row } from 'antd';
import { CreditRisk } from '@types';
import { makeStyles } from '@mui/styles';
import { moneyFormatter } from '@utils';
import { clsx } from 'clsx';
import React from 'react';

const toPercentage = (value: number): string => `${+(value || 0).toFixed(2)}%`;

export const RiskOther = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const { otherVariables, addemVariables, totalVariables } = risk;
  const totals = (totalVariables?.values || []).reduce((acc, cur) => {
    acc[cur.key] = cur.value;
    return acc;
  }, {} as any);

  const otherTableRender = (): JSX.Element[] => {
    return risk.isLegacy ? (otherVariables?.values || []).map(variable => (
      <tr key={variable.key}>
        <td>{otherTitles[variable.key]}</td>
        <td>{variable.value}</td>
      </tr>
    )) : (risk.replyOther || []).map(variable => (
      <tr key={variable.position}>
        <td>{variable.title}</td>
        <td>{variable.value || '---'}</td>
      </tr>
    ));
  };

  return (
    <>
      <div className={clsx(cls.title, cls.titleLeft)}>Otras variables</div>
      <Row gutter={[20, 20]}>
        <Col md={12} sm={24}>
          <table className={cls.table}>
            <tbody>{otherTableRender()}</tbody>
          </table>
        </Col>
        <Col md={12} sm={24}>
          {risk.showAddemVariables && (
            <table className={cls.table}>
              <tbody>
                <tr>
                  <td colSpan={2}>ADDEM Variables</td>
                </tr>
                {(addemVariables?.values || []).map(variable => (
                  <tr key={variable.key}>
                    <td>{addemTitles[variable.key]}</td>
                    <td>{moneyFormatter.format(+variable.value || 0)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <table className={cls.tableStriped}
            style={{ marginTop: risk.showAddemVariables ? undefined : 0 }}>
            <tbody>
              <tr>
                <td>Total activo</td>
                <td>{moneyFormatter.format(+totals.totalAssets || 0)}</td>
                <td>Endeudamiento</td>
                <td>{toPercentage(totals.debt)}</td>
              </tr>
              <tr>
                <td>Total passivo</td>
                <td>{moneyFormatter.format(+totals.totalLiabilities || 0)}</td>
                <td>Apalancamiento</td>
                <td>{toPercentage(totals.leverage)}</td>
              </tr>
              <tr>
                <td>Capital contable</td>
                <td>{moneyFormatter.format(+totals.stockholdersEquity || 0)}</td>
                <td>ROE</td>
                <td>{toPercentage(totals.roe)}</td>
              </tr>
              <tr>
                <td>Utilidad neta</td>
                <td>{moneyFormatter.format(+totals.netProfit || 0)}</td>
                <td colSpan={2}></td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
};

const useStyles = makeStyles({
  title: {
    margin: '25px 0 5px',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#000',
  },
  titleLeft: {
    textAlign: 'left',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& td': {
      width: '50%',
      borderTop: '1px solid #042E6B',
      borderLeft: '1px solid #042E6B',
      position: 'relative',
      padding: '3px 8px',
      fontSize: 10,
      fontWeight: 400,
      color: '#042E6B',
      '&:last-child': {
        fontWeight: 500,
        backgroundColor: '#F0F3FB',
        borderRight: '1px solid #042E6B',
        textAlign: 'center',
      },
      '&[colspan]': {
        backgroundColor: '#042E6B',
        color: '#FFF',
      },
    },
    '& tr:first-child td:first-child': {
      borderTopLeftRadius: 5,
    },
    '& tr:first-child td:last-child': {
      borderTopRightRadius: 5,
    },
    '& tr:last-child td:first-child': {
      borderBottomLeftRadius: 5,
      borderBottom: '1px solid #042E6B',
    },
    '& tr:last-child td:last-child': {
      borderBottomRightRadius: 5,
      borderBottom: '1px solid #042E6B',
    },
  },
  tableStriped: {
    marginTop: 20,
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& td': {
      borderTop: '1px solid #042E6B',
      borderLeft: '1px solid #042E6B',
      fontSize: 10,
      fontWeight: 400,
      color: '#042E6B',
      width: '30%',
      padding: '3px 8px',
      backgroundColor: 'transparent',
      '&:last-child': {
        borderRight: '1px solid #042E6B',
      },
      '&[colspan]': {
        backgroundColor: '#042E6B',
      },
      '&:nth-child(even)': {
        width: '20%',
        backgroundColor: '#F0F3FB',
      },
    },
    '& tr:first-child td': {
      '&:first-child': {
        borderTopLeftRadius: 5,
      },
      '&:last-child': {
        borderTopRightRadius: 5,
      },
    },
    '& tr:last-child td': {
      borderBottom: '1px solid #042E6B',
      '&:first-child': {
        borderBottomLeftRadius: 5,
      },
      '&:last-child': {
        borderBottomRightRadius: 5,
      },
    },
  },
});

const otherTitles: Record<string, string> = {
  'retainersWebsite': 'Página de web de la retenedora',
  'certification': 'Certificación',
  'irrigationPermit': 'Permiso de riego',
  'nicheAgriculture': 'Agricultura de nicho',
  'directExporter': 'Exportan directamente',
  'producerAndTrader': 'Producen y comercializan',
  'extraSourceOfIncome': 'Fuente extra de ingresos',
  'staggeredCrops': 'Cultivos escalonados',
  'solarPanels': 'Paneles solares',
  'belongingToCluster': 'Pertenecen a un cluster',
};

const addemTitles: Record<string, string> = {
  'liquidity': 'Liquidez',
  'leverage': 'Apalancamiento',
  'roe': 'ROE',
};
