import React, { useEffect, useState } from 'react';
import { ListProps, TextField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  CheckboxField,
  MoneyField,
  SelectInput,
  DateField,
  DateRangeInput,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { FinanceDepositOptions, purposeOptions } from '@types';
import { financeWithdrawalHttp } from '@network';
import { FinanceDocsField } from '@pages/finance-withdrawal/FinanceDocsField';
import cls from './finance-withdrawal-list.module.css';

const clientFilterOptions = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

const sentFilterOptions = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

const receivedFilterOptions = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

export const FinanceWithdrawalList = (props: ListProps) => {
  const rbacList = useRbacList();
  const exporter = useExporter({
    filename: 'Withdrawals',
    props: {
      'id': {
        label: 'ID',
      },
      'code': {
        label: 'Concept',
      },
      'accountAccount': {
        label: 'Account',
      },
      'creditCode': {
        label: 'Credit Code',
      },
      'amount': {
        label: 'Amount',
        transform: 'money',
      },
      'paymentDate': {
        label: 'Payment Date',
        transform: 'date',
      },
      'creditAccredited': {
        label: 'Accredited',
      },
      'purpose': {
        label: 'Purpose',
      },
      'details': {
        label: 'Details',
      },
      'sent': {
        label: 'Sent',
        transform: 'boolean',
      },
      'received': {
        label: 'Received',
        transform: 'boolean',
      },
      'receipts': {
        label: 'Receipts',
        transform: 'files',
      },
      'invoices': {
        label: 'Invoices',
        transform: 'files',
      },
    },
  });

  const [withdrawalConceptsOptions, setWithdrawalConceptsOptions] =
    useState<{id: string; name: string;}[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const withdrawalOptions: FinanceDepositOptions = await financeWithdrawalHttp.options();

        if (!withdrawalOptions) {
          throw new Error('The lists of options has not been received');
        }

        setWithdrawalConceptsOptions((withdrawalOptions?.concept || []).map(option => ({
          id: option,
          name: option,
        })));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      exporter={exporter}
      filters={[
        <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 220 }} />,
        <SelectInput key="purpose" source="purpose" choices={purposeOptions} label="Purpose" />,
        <SelectInput key="concept"
          source="concept"
          choices={withdrawalConceptsOptions}
          label="Concept" />,
        <SelectInput key="creditCode"
          source="creditCode"
          choices={clientFilterOptions}
          label="Client" />,
        <SelectInput key="sent" source="sent" choices={sentFilterOptions} label="Sent" />,
        <SelectInput key="received"
          source="received"
          choices={receivedFilterOptions}
          label="Received" />,
        <DateRangeInput key="createdAt" source="createdAt" label="Created At"
          defaultValue={false} />,
      ]}
      sort={{ field: 'id', order: 'DESC' }}
      className={cls.page}
    >
      <Grid
        rowClick="edit"
        className={cls.grid}
        defaultColumns={'code amount paymentDate purpose sent receipts'.split(' ')}
      >
        <TextField source="id" label="ID" />
        <TextField source="code" label="Concept" />
        <TextField source="accountAccount" label="Account" />
        <TextField source="creditCode" label="Credit code" />
        <MoneyField source="amount" />
        <DateField source="paymentDate" />
        <TextField source="creditAccredited" label="Accredited" />
        <TextField source="purpose" />
        <TextField source="details" />
        <CheckboxField source="sent" />
        <CheckboxField source="received" />
        <FinanceDocsField source="receipts" label="Receipts" modelName="Withdrawal" />
        <FinanceDocsField source="invoices" label="Invoices" modelName="Withdrawal" />
        <DateField
          source="createdAt"
          label="Created at"
          locales="es-ES"
          options={{ timeZone: 'UTC' }}
        />
      </Grid>
    </GridList>
  );
};
