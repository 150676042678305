import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Modal } from 'antd';
import { Button } from '@mui/material';
import { useNotify, useRefresh } from 'react-admin';

import { Credit, CreditMoney, CreditMoneyType } from '@types';
import { useRequest } from '@hooks';
import { creditHttp } from '@network';
import { AmountsWidget } from './AmountsWidget';
import { MoneyWidget } from './MoneyWidget';
import { BankAmount } from './BankAmount';
import { MembershipPercent } from './MembershipPercent';
import { CreditOrdersWidget } from './CreditOrdersWidget';
import { BalanceAmount } from './BalanceAmount';
import { rbac } from '@utils/rbac';

interface Amount {
  title: string;
  amount: number;
  group: CreditMoneyType;
}

export const CreditAmounts = (props: {credit: Credit}) => {
  const cls = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [credit, setCredit] = useState(props.credit);
  const [group, setGroup] = useState<CreditMoneyType>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [money, setMoney] = useState<CreditMoney[]>(credit.money || []);
  const [bankAmount, setBankAmount] = useState<number>(credit.bankApproval || 0);
  const [balanceAmount, setBalanceAmount] = useState<number>(credit.balance || 0);
  const [isManualBalance, setManualBalance] = useState<boolean>(credit.isManualBalance);
  const [membershipPercent, setMembershipPercent]
    = useState(credit.membershipPercent);
  const [membershipCompanyPercent, setMembershipCompanyPercent]
    = useState(credit.membershipCompanyPercent);

  useEffect(() => {
    setMoney(credit.money || []);
    setMembershipPercent(credit.membershipPercent);
    setMembershipCompanyPercent(credit.membershipCompanyPercent);
    setBankAmount(credit.bankApproval);
  }, [credit]);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const hideModal = () => {
    setGroup(undefined);
    setIsModalVisible(false);
  };

  const changeGroup = (nextGroup: CreditMoneyType) => {
    setGroup(nextGroup);
    showModal();
  };

  const amounts: Amount[] = [
    { title: 'Credit', amount: credit.bankApproval || 0, group: 'bank_approval' },
    { title: 'Balance', amount: credit.balance || 0, group: 'balance' },
    { title: 'Farmer Deposit', amount: credit.farmerDeposit || 0, group: 'farmer_deposit' },
    { title: 'Membership', amount: credit.membership || 0, group: 'membership' },
    { title: 'Withdrawal fee', amount: credit.creditFee || 0, group: 'credit_fee' },
    { title: 'Interest', amount: credit.interest || 0, group: 'interest' },
    { title: 'Insurance', amount: credit.insurance || 0, group: 'insurance' },
    { title: 'Refunds', amount: credit.refunds || 0, group: 'refunds' },
    { title: 'External', amount: credit.manual || 0, group: 'manual' },
    { title: 'Retention', amount: credit.retention || 0, group: 'retention' },
    { title: 'Repayment', amount: credit.repayment || 0, group: 'repayment' },
    { title: 'Total orders', amount: credit.ordersAmount || 0, group: 'orders' },
  ];

  const editable = rbac.hasCreditAdminRole();
  const isSuperadmin = rbac.isSuperadmin();

  const showCreditOrders = group === 'orders';
  const showBalance = group === 'balance' && isSuperadmin;
  const showMembership = group === 'membership';
  const showBankAmount = group === 'bank_approval';
  const showMoneyWidget = group &&
    !['balance', 'bank_approval', 'orders', 'membership'].includes(group);

  const { loading, submit } = useRequest(async () => {
    const hasBlankTitle = money.find(m => !m.title);
    const hasBlankAmount = money.find(m => !(+m.amount >= 0));
    const hasBlankDate = money.find(m => !m.date);

    if (hasBlankTitle) {
      return notify(group === 'insurance' ? 'Policy number is required' : 'Concept is required');
    } else if (hasBlankAmount) {
      return notify('Amount is required');
    } else if (hasBlankDate) {
      return notify('Date is required');
    }
    const updatedCredit = await creditHttp.saveMoney(credit.id, {
      money, membershipPercent, membershipCompanyPercent,
      bankAmount, isManualBalance, balanceAmount,
    });
    setCredit(updatedCredit);
    refresh();
    notify('Saved');
  });

  return (
    <>
      <AmountsWidget amounts={amounts} changeGroup={changeGroup} group={group} />
      <Modal
        open={isModalVisible}
        title={false}
        onCancel={hideModal}
        width={1000}
        closeIcon={<CloseIcon />}
        footer={editable ? (
          <div className={cls.modalActions}>
            <Button variant="contained" color="secondary" disabled={loading}
              onClick={submit} className={cls.btn}>
              <SaveIcon /><span>Save</span>
            </Button>
          </div>
        ) : null}
      >
        <AmountsWidget amounts={amounts} changeGroup={changeGroup} group={group} />
        {showMembership && <MembershipPercent
          percent={membershipPercent}
          setPercent={setMembershipPercent}
          percentCompany={membershipCompanyPercent}
          setPercentCompany={setMembershipCompanyPercent}
          bankAmount={bankAmount}
          editable={editable}
        />}
        {showCreditOrders && <CreditOrdersWidget orders={credit.orders || []} />}
        {showBankAmount && <BankAmount
          amount={bankAmount}
          setAmount={setBankAmount}
          editable={editable}
        />}
        {showBalance && <BalanceAmount
          amount={balanceAmount}
          setAmount={setBalanceAmount}
          isManualBalance={isManualBalance}
          setManualBalance={setManualBalance}
          editable={showBalance}
        />}
        {showMoneyWidget && <MoneyWidget
          group={group}
          money={money}
          setMoney={setMoney}
          editable={editable}
        />}
      </Modal>
    </>
  );
};

const useStyles = makeStyles({
  modalActions: {
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    borderRadius: 8,
    paddingLeft: 50,
    paddingRight: 50,
    marginRight: 15,
    textTransform: 'none',
    '& svg': {
      marginRight: 10,
    },
  },
  btnText: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 400,
    color: '#E1E5ED',
  },
});


const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
    style={{ display: 'inline-block', marginTop: -10, marginRight: -40 }}
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none">
    <path d="M17.5039 0C7.83826 0 0 7.83826 0 17.5039C0 27.1695 7.83826 35.0078 17.5039 35.0078C27.1695 35.0078 35.0078 27.1695 35.0078 17.5039C34.9922 7.83826 27.1617 0.00777605 17.5039 0Z"
      fill="#E1E5ED" />
    <path d="M24.0903 10.9101C23.787 10.6068 23.2971 10.6068 22.9938 10.9101L17.5039 16.4L12.0141 10.9101C11.7108 10.6068 11.2209 10.6068 10.9176 10.9101C10.6144 11.2133 10.6144 11.7032 10.9176 12.0065L16.4075 17.4964L10.9176 22.9863C10.6144 23.2895 10.6144 23.7794 10.9176 24.0827C11.2209 24.386 11.7108 24.386 12.0141 24.0827L17.5039 18.5928L22.9938 24.0827C23.2971 24.386 23.787 24.386 24.0903 24.0827C24.3935 23.7794 24.3935 23.2895 24.0903 22.9863L18.6004 17.4964L24.0903 12.0065C24.3935 11.7032 24.3935 11.2133 24.0903 10.9101Z"
      fill="#042E6B" />
  </svg>
);

const SaveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'inline-block' }}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none">
    <g clipPath="url(#clip0_3843_4772)">
      <path d="M14.8964 1.75077L13.2321 0.10257C13.1643 0.0389059 13.075 0 12.9786 0H2.25C2.21429 0 2.18571 0 2.15 0C2.15 0 2.14643 0 2.14286 0H0.357143C0.160714 0 0 0.159161 0 0.35369V14.6463C0 14.8408 0.160714 15 0.357143 15H14.6429C14.8393 15 15 14.8408 15 14.6463V2.00189C15 1.90639 14.9607 1.81797 14.8964 1.75077ZM7.5 11.8486C6.11786 11.8486 5 10.7416 5 9.37279C5 8.00401 6.11786 6.89696 7.5 6.89696C8.88214 6.89696 10 8.00401 10 9.37279C10 10.7416 8.88214 11.8486 7.5 11.8486ZM11.4286 3.90474C11.4286 4.49187 10.95 4.96581 10.3571 4.96581H4.64286C4.05 4.96581 3.57143 4.49187 3.57143 3.90474V1.40769C3.57143 1.22377 3.72143 1.07522 3.90714 1.07522L7.91071 1.06814C8.07857 1.06814 8.21429 1.20255 8.21429 1.36878V3.47324C8.21429 3.70667 8.40357 3.89413 8.63929 3.89413H9.575C9.81071 3.89413 10 3.70667 10 3.47324V1.36171C10 1.19547 10.1357 1.06107 10.3036 1.06107H11.0929C11.2786 1.06107 11.4286 1.20962 11.4286 1.39354V3.90474Z"
        fill="#E1E5ED" />
    </g>
    <defs>
      <clipPath id="clip0_3843_4772">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
