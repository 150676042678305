import React from 'react';
import { Edit, EditProps, SimpleForm } from 'react-admin';

import { useEditProps, useRbacEdit } from '@hooks';
import { Forbidden, InitialPage } from '@components';
import { FinanceCreditForm, validate } from './FinanceCreditForm';

export const FinanceCreditEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacEdit = useRbacEdit();

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} {...editProps} title="Update Credit">
      <InitialPage
        title="Credit"
        backTo="/finance-credit"
        backText="Back to other Credits"
      >
        <SimpleForm validate={validate}>
          <FinanceCreditForm />
        </SimpleForm>

      </InitialPage>
    </Edit>
  );
};
