import { makeStyles } from '@mui/styles';
import { Col, Row } from 'antd';
import { maxLength, minLength, ReferenceInput } from 'react-admin';

import { useWindowSize } from '@hooks';
import { DealerType, genderOptions, marriageOptions, User } from '@types';
import {
  ActivationCpl,
  DateInput,
  InitialCard,
  InitialLine,
  ManyInput,
  ManyTagsInput,
  PhoneMaskedInput,
  SelectInput,
  SuggestsInput,
  SwitchInput,
  TextInput,
} from '@components';
import { UserTags } from './UserTags';
import { UserStatusForm } from './UserStatusForm';

export const inactivityReasons = [
  { id: 'Not answering anymore', name: 'Not answering anymore' },
  { id: 'No longer interested', name: 'No longer interested' },
  { id: 'We are late', name: 'We are late' },
  { id: 'No follow up', name: 'No follow up' },
  { id: 'Unqualified', name: 'Unqualified' },
];

export const UserInfo = ({ user }: {user: User}) => {
  const cls = useStyles();
  const { width } = useWindowSize();

  const isSmallScreen = (width || 0) < 1700;
  const rfcLength = 13;
  const rfcCompanyLength = 12;
  const validateRfc = [minLength(rfcLength), maxLength(rfcLength)];
  const validateRfcCompany = [minLength(rfcCompanyLength), maxLength(rfcCompanyLength)];

  return (
    <InitialCard autoHeight extraPadding>
      <Row gutter={[24, 24]} className={cls.box}>
        <Col sm={24} md={12}>
          {/* LEFT */}
          <div className={cls.leftBox}>
            {/* LEFT HEADER */}
            <div className={cls.avatar}>
              {user.avatar ? (
                <img alt="avatar" src={user.avatar} className={cls.avatarImg} />
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none">
                  <path d="M40 0C17.9143 0 0 17.9143 0 40C0 62.0857 17.9143 80 40 80C62.0857 80 80 62.0857 80 40C80 17.9143 62.0857 0 40 0ZM40 20C49 20 56.3143 27.3143 56.3143 36.3143C56.3143 45.3143 49 52.6286 40 52.6286C31 52.6286 23.6857 45.3143 23.6857 36.3143C23.6857 27.3143 31 20 40 20ZM40 74.8571C31.8857 74.8571 24.4 72.0571 18.4571 67.3714C20.5714 64.3429 24.0571 62.5143 27.7714 62.5143H52.2286C55.9429 62.5143 59.4286 64.3429 61.5429 67.3714C55.6 72.0571 48.1143 74.8571 40 74.8571Z"
                    fill="#042E6B" />
                </svg>
              )}
            </div>
            <div className={cls.header}>
              <InitialLine title="Verqor code:">{user.code}</InitialLine>
              <InitialLine title="Tags:">
                <UserTags />
              </InitialLine>
            </div>

            {/* LEFT FORM */}
            <UserStatusForm hasLogged={user.hasLogged} />
            <InitialLine title="Reason for inactivity:" full={isSmallScreen}>
              <SelectInput label="" source="inactiveReason" choices={inactivityReasons} />
            </InitialLine>
            <InitialLine title="First Name:">
              <TextInput label="" source="firstName" required inputProps={{ maxLength: 25 }} />
            </InitialLine>
            <InitialLine title="Middle Name:">
              <TextInput label="" source="middleName" inputProps={{ maxLength: 25 }} />
            </InitialLine>
            <InitialLine title="Last Name:">
              <TextInput label="" source="lastName" required inputProps={{ maxLength: 25 }} />
            </InitialLine>
            <InitialLine title="Gender:">
              <SelectInput label="" source="gender" choices={genderOptions} />
            </InitialLine>
            <InitialLine title="Civil status:">
              <SelectInput label="" source="marriage" choices={marriageOptions} />
            </InitialLine>
            <InitialLine title="Birthday:">
              <DateInput label="" source="birthday" />
            </InitialLine>
            <InitialLine title="Phone:">
              <PhoneMaskedInput source="phone" />
            </InitialLine>
            <InitialLine title="Email:" right={(
              <SwitchInput source="isEmailActivated" label="activated" />
            )}>
              <TextInput label="" source="email" type="email" />
            </InitialLine>
            <InitialLine title="RFC:">
              <TextInput
                source="rfc"
                label=""
                inputProps={{ maxLength: rfcLength, onInput: transformNumberInput }}
                validate={validateRfc}
                format={v => (v || '').toUpperCase()}
              />
            </InitialLine>
            <InitialLine title="CURP:">
              <TextInput
                source="curp"
                label=""
                inputProps={{ maxLength: 18, onInput: transformNumberInput }}
                format={v => (v || '').toUpperCase()}
              />
            </InitialLine>
            <InitialLine title="CIEC:">
              <TextInput
                source="ciec"
                label=""
                inputProps={{ maxLength: 8, onInput: transformCiecInput }}
              />
            </InitialLine>
            <InitialLine title="Owner:" widthTitle={6} widthContent={10}>
              <SuggestsInput
                source="contactOwnerId"
                reference="admin"
                className={cls.suggetsInput}
              />
            </InitialLine>
          </div>
        </Col>
        <Col sm={24} md={12} className={cls.rightCol}>
          <div>
            <InitialLine title="Zip codes:" widthTitle={6} widthContent={18}>
              <TextInput source="zipCodes" label="" />
            </InitialLine>
            <InitialLine title="State:" widthTitle={6} widthContent={18}>
              <ReferenceInput source="stateId" reference="state" label="">
                <SelectInput optionText="name" label="" />
              </ReferenceInput>
            </InitialLine>
            <InitialLine title="Crops:" widthTitle={6} widthContent={18}>
              <ManyTagsInput source="cropIds" reference="crop" />
            </InitialLine>
            <InitialLine title="Company:" right={(
              <SwitchInput source="isCompany" label="is company" />
            )}>
              <TextInput label="" source="companyName" />
            </InitialLine>
            <InitialLine title="RFC (Company)" widthTitle={6} widthContent={18}>
              <TextInput
                source="rfcCompany"
                label=""
                inputProps={{ maxLength: rfcCompanyLength, onInput: transformNumberInput }}
                validate={validateRfcCompany}
                format={v => (v || '').toUpperCase()}
              />
            </InitialLine>
            <InitialLine title="Buyers:" widthTitle={6} widthContent={18}>
              <ManyTagsInput source="buyerIds" mainSource="mainBuyerId"
                reference={`dealer/by-type/${DealerType.BUYER}`} optionLabel="nameTier" />
              <SwitchInput source="generalBuyer" label="Venta al público en general"
                className={cls.buyerSwitcher} />
            </InitialLine>
            <InitialLine title="Retailers:" widthTitle={6} widthContent={18}>
              <ManyTagsInput source="retailerIds"
                reference={`dealer/by-type/${DealerType.RETAILER}`} />
            </InitialLine>
            <InitialLine title="Associates:" widthTitle={6} widthContent={18}>
              <ManyTagsInput source="associateIds"
                reference={`dealer/by-type/${DealerType.ASSOCIATE}`} />
            </InitialLine>
            <InitialLine title="Channel:" widthTitle={6} widthContent={18}>
              <ReferenceInput source="source" reference="user/sources" label="">
                <SelectInput optionText="name" label="" />
              </ReferenceInput>
            </InitialLine>
            <InitialLine title="Manager:" widthTitle={6} widthContent={18}>
              <ReferenceInput source="responsibleAdminId" reference="admin/managers" label="">
                <SelectInput optionText="name" label="" />
              </ReferenceInput>
            </InitialLine>
            <InitialLine title="Activation:" widthTitle={6} widthContent={18}>
              <SuggestsInput source="activationId"
                reference="finance-activation" mui nullable />
            </InitialLine>
            <InitialLine title="Hubspot ID:" widthTitle={6} widthContent={18}>
              <TextInput source="hubspotId" label="" />
            </InitialLine>
          </div>
          <div>
            <ActivationCpl className={cls.activationCpl} />
          </div>
        </Col>
      </Row>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
    maxWidth: '100%',
  },
  leftBox: {
    borderRadius: '42px 8px 8px 8px',
    border: '1px solid #042E6B',
    background: '#E1E5ED',
    padding: '0 10px 22px 30px',
    position: 'relative',
  },
  header: {
    paddingLeft: 70,
  },
  avatar: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 80,
    height: 80,
    borderRadius: 40,
    overflow: 'hidden',
  },
  avatarImg: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  buyerSwitcher: {
    marginTop: 10,
  },
  suggetsInput: {
    color: '#000',
    fontSize: '16px !important',
    fontWeight: 400,
    lineHeight: 'normal',

    '& > .ant-select-selector': {
      padding: '0 !important',
      backgroundColor: 'transparent !important',
      border: 'none !important',
      borderBottom: '1px solid rgba(0, 42, 119, 0.10) !important',
      boxShadow: 'none !important',
    },
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  activationCpl: {
    marginLeft: 'auto',
  },
});

const transformNumberInput = (e: any) => {
  if (e.target && e.target.value) {
    e.target.value = e.target.value.toUpperCase().replace(/[^0-9A-Z]/g, '');
  }
};

const transformCiecInput = (e: any) => {
  if (e.target && e.target.value) {
    e.target.value = e.target.value.replace(/[^0-9A-Za-z]/g, '');
  }
};
