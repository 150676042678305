import React, { MutableRefObject, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Modal, Row, Col } from 'antd';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

import { Pill } from './Pill';
import { CreditRisk } from '@types';
import { creditHttp } from '@network';
import { RiskBuro } from './RiskBuro';
import { RiskHero } from './RiskHero';
import { dateFormatter } from '@utils';
import { RiskScore } from './RiskScore';
import { RiskOther } from './RiskOther';
import { BlueButton } from '@components';
import { RiskFiscal } from './RiskFiscal';
import { RiskOfftaker } from './RiskOfftaker';
import { RiskSupplier } from './RiskSupplier';
import { VerqorLogo } from './icons/VarqorLogo';
import { RiskTermsLegacy } from './RiskTermsLegacy';
import { DownloadFileIcon } from './icons/DownloadFileIcon';
import { Geography } from '../analysis/components/Geography';
import { RiskSupplierAmounts } from './RiskSupplierAmounts';
import { RiskVariablesLegacy } from './RiskVariablesLegacy';
import { RiskTaxesLegacy } from '@pages/credit-risk/export/RiskTaxesLegacy';

interface Props {
  creditId: number;
  isOpened: boolean;
  setOpened: (nextOpened: boolean) => void;
  isPrepared?: boolean;
  reportNodeRef?: MutableRefObject<ReactNode>;
}

const RiskReport = ({ risk, isExporting, cropTechnification }: any) => {
  const cls = useStyles();

  return (
    <div id="export-risk" className={clsx(cls.box, isExporting && cls.boxExporting)}>
      {/* PAGE 1 */}
      <div className={clsx(isExporting && cls.page)}>
        <Header page={isExporting ? 1 : undefined}>
            Fecha {dateFormatter.toDateSlash(new Date())}
        </Header>
        {/* INFO (Hero) */}
        <RiskHero risk={risk}/>

        {/* Variables Financieras */}
        <div className={cls.title}>Variables Financieras</div>
        <RiskVariablesLegacy risk={risk}/>

        {/* Condiciones de crédito */}
        <div className={cls.title}>Condiciones de crédito</div>
        <RiskTermsLegacy risk={risk}/>
      </div>

      {/* PAGE 2 */}
      <div className={clsx(isExporting && cls.page)}>
        {isExporting && <Header page={2} marginTop={5}/>}
        {/* Ingresos/Gastos tributarios generales */}
        <div className={cls.title}>Ingresos/Gastos tributarios generales</div>
        <RiskTaxesLegacy risk={risk}/>

        {/* Fiscal & Supplier */}
        <Row gutter={[20, 20]}>
          <Col md={12} sm={24}>
            <div className={cls.title}>Los ingresos fiscales del cultivo a financiar</div>
            <RiskFiscal risk={risk}/>
          </Col>
          <Col md={12} sm={24}>
            <div className={cls.title}>Ingresos proveedor(s) insumos</div>
            <RiskSupplierAmounts risk={risk}/>
          </Col>
        </Row>

        {/* Buro de Crédito */}
        <div className={cls.title}>Buro de Crédito</div>
        <RiskBuro risk={risk}/>

        {/* Buro de Crédito */}
        <div className={cls.title}>Score</div>
        <RiskScore risk={risk}/>
      </div>

      {/* PAGE 3 */}
      <div className={clsx(isExporting && cls.page)}>
        {isExporting && <Header page={3} marginTop={10}/>}
        {/* Geography & Crops */}
        <Row gutter={[20, 20]}>
          <Col md={12} sm={24}>
            <div className={cls.title}>Geografía</div>
            <Geography geo={risk.geography} tiny/>
          </Col>
          <Col md={12} sm={24}>
            <div className={cls.title}>Tecnificación de cultivo</div>
            <Pill title="Tecnificación agrícola" info={cropTechnification}/>
          </Col>
        </Row>

        {/* Retenedora (Off-taker) */}
        <div className={clsx(cls.title, cls.titleLeft)}>Retenedora</div>
        <RiskOfftaker risk={risk}/>

        {/* Proveedor (Supplier) */}
        <div className={clsx(cls.title, cls.titleLeft)}>Proveedor</div>
        <RiskSupplier risk={risk}/>

        {/* Otras variables (other) */}
        <div className={clsx(cls.title, cls.titleLeft)}>Otras variables</div>
        <RiskOther risk={risk}/>
      </div>
    </div>
  );
};

export const ExportRiskLegacy = ({ creditId, isOpened, setOpened, isPrepared }: Props) => {
  const [risk, setRisk] = useState<CreditRisk>();
  const [isExporting, setExporting] = useState(false);
  const cls = useStyles();

  const hideModal = () => setOpened(false);
  const cropTechnification = risk?.cropTechnification;
  const pdfFilename = `credit_analysis_${risk?.credit?.code}.pdf`;

  useEffect(() => {
    creditId && (isOpened || isPrepared) && (async () => {
      const nextRisk = await creditHttp.getRisk(creditId);
      setRisk(nextRisk);
    })();
  }, [creditId, isOpened, isPrepared]);

  const downloadPdfDocument = async () => {
    setExporting(true);
    await new Promise((resolve) => setTimeout(resolve, 250));
    const div = document.getElementById('export-risk');

    try {
      div && html2canvas(div).then((canvas) => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }
        doc.save(pdfFilename);
      });
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      {risk && (
        <RiskReport
          risk={risk}
          isExporting={isExporting}
          cropTechnification={cropTechnification}
        />
      )}
      <Modal
        open={isOpened}
        title={(
          <div className={cls.modalTitle}>
            <BlueButton
              onClick={downloadPdfDocument}
              className={cls.downloadButton}
            ><DownloadFileIcon/> Download PDF</BlueButton>
            <div>File name: {pdfFilename}</div>
          </div>
        )}
        footer={null}
        onCancel={hideModal}
        width={800}
        style={{ top: 20 }}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
        closeIcon={<Close style={{ marginTop: 14 }} />}
      >
        {isOpened && !risk && (
          <div className={cls.box}>
            <h3>Risk Manager for this credit is not found</h3>
          </div>
        )}
        {isOpened && risk && (
          <RiskReport
            risk={risk}
            isExporting={isExporting}
            cropTechnification={cropTechnification}
          />
        )}
      </Modal>
    </>
  );
};

const pageHeight = 1125;
const useStyles = makeStyles({
  modalTitle: {
    display: 'flex',
    '& > div': {
      padding: '10px 30px',
      fontSize: 14,
    },
  },
  box: {
    marginTop: -20,
    padding: '20px 20px',
    fontFamily: 'Outfit, sans-serif',
    color: '#000',
    background: 'url("/bg/export-risk-bg.png") repeat-y top left',
  },
  boxExporting: {
    height: pageHeight * 3 + 20,
  },
  page: {
    height: pageHeight,
  },
  title: {
    margin: '25px 0 5px',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#000',
  },
  titleLeft: {
    textAlign: 'left',
  },
  header: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 14,
  },
  headerText: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    fontSize: 13,
    fontWeight: 400,
  },
  headerPage: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 10,
    fontWeight: 400,
    color: '#042E6B',
  },
  downloadButton: {
    width: 'auto !important',
    display: 'flex',
    alignItems: 'center',
  },
});

type HeaderProps = {page?: number, marginTop?: number};
const Header = ({ children, page, marginTop }: PropsWithChildren<HeaderProps>) => {
  const cls = useStyles();

  return (
    <div className={cls.header} style={{ marginTop }}>
      {page && <div className={cls.headerPage}>Page {page}</div>}
      <div className={cls.headerText}>{children}</div>
      <VerqorLogo />
    </div>
  );
};

