import { FunctionField, FunctionFieldProps } from 'react-admin';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Button } from 'antd';

import { dateFormatter } from '@utils';
import { resolvePath } from './resolve-path';

interface History {
  id: number;
  title: string;
  statusBefore: string | null;
  statusAfter: string | null;
  adminName?: string | null;
  createdAt: string;
}

const HistoryItem = ({ item }: {item: History}) => {
  const cls = useStyles();
  const text = item.statusBefore ? `${item.statusBefore} → ${item.statusAfter} ` : `${item.statusAfter} `;

  return (
    <div className={cls.item}>
      <div className={cls.row}>
        <span className={cls.timestamp}>{dateFormatter.toDateTime(item.createdAt)}</span>
        <span className={cls.title}>{item.title}</span>
        {item.adminName && <span className={cls.adminName}>by {item.adminName}</span>}
      </div>
      {(item.statusBefore || item.statusAfter) && (
        <div className={cls.row}>
          <Chip size="small" className={cls.chip} title={text} label={text} />
        </div>
      )}
    </div>
  );
};

export const HistoryItems = ({ history }: {history: History[]}) => {
  const cls = useStyles();
  const [isAll, setIsAll] = useState(false);
  const show = () => setIsAll(true);

  if (!Array.isArray(history) || (history || []).length === 0) {
    return null;
  }

  const maxVisible = 4;
  const visibleHistory = history.slice(0, maxVisible);
  const hiddenHistory = history.slice(maxVisible);

  return (
    <div className={cls.box} onClick={e => e.stopPropagation()}>
      {visibleHistory.map(item => <HistoryItem key={item.id} item={item} />)}
      {hiddenHistory.length > 0 && (
        <>
          {!isAll && (
            <Button size="small" onClick={show}>
              show previous ({hiddenHistory.length})
            </Button>
          )}
          {isAll && hiddenHistory.map(item => <HistoryItem key={item.id} item={item} />)}
        </>
      )}
    </div>
  );
};

export const HistoryField = (props: Partial<FunctionFieldProps>) => {
  return (
    <FunctionField
      sortable={false}
      {...props}
      render={(record: any) => (
        <HistoryItems history={resolvePath(record, props.source || '')} />
      )}
    />
  );
};

const useStyles = makeStyles({
  box: {
    paddingBottom: 15,
  },
  item: {
    marginBottom: 15,
  },
  row: {
    display: 'flex',
    fontSize: 12,
    marginBottom: 5,
    lineHeight: '1.1em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  timestamp: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
    fontSize: 10,
    lineHeight: '1em',
    padding: '3px 5px',
    backgroundColor: 'beige',
    border: '1px solid #CCC',
    whiteSpace: 'nowrap',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '1.1em',
    color: '#002a77',
  },
  adminName: {
    marginLeft: 5,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '1.1em',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    color: 'blueviolet',
  },
  chip: {
    maxWidth: 270,
    fontSize: 11,
    lineHeight: '1.1em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});
