import { OrderPosition } from '@types';

export interface CreditInvoice {
  id: number;
  creditId: number;
  company: string;
  state: string;
  status: CreditInvoiceStatusType;
  file: CreditInvoiceFile;
  comment: string;
  createdAt: Date;
  commentedAt: Date;
  positions: OrderPosition[];
  positionsCost: number;
  isPending: boolean;
  isApproved: boolean;
  isDenied: boolean;
  extension: string | null;
  userId: number | null;
  managerId: number | null;
  managerName: string | null;
  adminId: number | null;
  adminName: number | null;
}

export enum CreditInvoiceStatusType {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
}

export interface CreditInvoiceFile {
  id: number;
  adminUrl: string;
  isPdf: boolean;
  url?: string;
  originalName?: string;
  createdAt: string;
  updatedAt?: string;
}
