import { Upload } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactChild, useState } from 'react';
import { RcFile } from 'antd/lib/upload';
import { clsx } from 'clsx';

import { beforeUpload, beforeUploadType, showAxiosError } from '@utils';
import cls from './file-upload.module.css';

interface Props {
  uploadAction: (rcFile: RcFile) => Promise<string>;
  className?: string;
  type?: 'pdf' | 'image';
  content?: ReactChild;
  size?: number;
}

export const FileUpload = ({ uploadAction, className, type, content, size }: Props) => {
  const [loading, setLoading] = useState(false);

  const onUpload = async (rcFile: RcFile) => {
    try {
      setLoading(true);
      const url = await uploadAction(rcFile);

      return url;
    } catch (err: any) {
      showAxiosError(err);

      return '';
    } finally {
      setLoading(false);
    }
  };

  let accept;
  if (type === 'pdf') {
    accept = 'application/pdf';
  }
  if (type === 'image') {
    accept = 'image/jpeg,image/jpg,image/png';
  }

  return (
    <div>
      <Upload
        listType="picture-card"
        showUploadList={false}
        beforeUpload={type ? beforeUploadType(type, size) : beforeUpload}
        customRequest={async ({ onSuccess }) => setTimeout(() => {
          onSuccess && onSuccess('ok');
        }, 0)}
        action={onUpload}
        onPreview={() => {}}
        className={clsx(cls.upload, className)}
        accept={accept}
      >
        <div>
          {loading ? (
            <LoadingOutlined className={cls.iconLoading} />
          ) : (
            <>
              {content ? content : <span className={clsx(cls.iconPlus, 'iconPlus')}>+</span>}
            </>
          )}
        </div>
      </Upload>
    </div>
  );
};
