import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5percent from '@amcharts/amcharts5/percent';
import * as am5 from '@amcharts/amcharts5';

import { PieReport, reportsHttp } from '@network';
import { InitialCard } from '@components';

export const UserCropsReport = ({ range }: {range: string}) => {
  const [numbers, setNumbers] = useState<PieReport[]>();
  const cls = useStyles();
  const chartId = 'user-crops-report';

  const loadNumbers = async () => {
    try {
      const nextNumbers = await reportsHttp.userCrops({ range });
      setNumbers(nextNumbers);
    } catch (e) {}
  };

  useEffect(() => void loadNumbers(), [range]);

  useLayoutEffect(() => {
    if (!numbers) {
      return;
    }
    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
        layout: root.horizontalLayout,
        innerRadius: am5.percent(60),
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        endAngle: 270,
      }),
    );

    series.set('colors', am5.ColorSet.new(root, {
      colors: [
        am5.color(0x73556E),
        am5.color(0x9FA1A6),
        am5.color(0xF2AA6B),
        am5.color(0xF28F6B),
        am5.color(0xA95A52),
        am5.color(0xE35B5D),
        am5.color(0xFFA446),
      ],
    }));

    const gradient = am5.RadialGradient.new(root, {
      stops: [
        { color: am5.color(0x000000) },
        { color: am5.color(0x000000) },
        {},
      ],
    });

    series.slices.template.setAll({
      fillGradient: gradient,
      strokeWidth: 2,
      stroke: am5.color(0xffffff),
      cornerRadius: 10,
      shadowOpacity: 0.1,
      shadowOffsetX: 2,
      shadowOffsetY: 2,
      shadowColor: am5.color(0x000000),
      fillPattern: am5.GrainPattern.new(root, {
        maxOpacity: 0.2,
        density: 0.5,
        colors: [am5.color(0x000000)],
      }),
    });

    series.slices.template.states.create('hover', {
      shadowOpacity: 1,
      shadowBlur: 10,
    });

    series.ticks.template.setAll({
      strokeOpacity: 0.4,
      strokeDasharray: [2, 2],
    });

    series.states.create('hidden', {
      endAngle: -90,
    });

    series.data.setAll(numbers);
    series.labels.template.set('visible', false);
    series.ticks.template.set('visible', false);
    series.slices.template.setAll({
      tooltipText: '[bold]Crop:[/] {category}\n[bold]Values:[/] {value}\n[bold]Percent:[/] {percent}%',
    });

    // legend
    const legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(60),
      x: am5.percent(80),
      y: 10,
      height: 445,
      width: 600,
      marginLeft: 15,
    }));
    legend.markerRectangles.template.adapters.add('fillGradient', function () {
      return undefined;
    });
    legend.data.setAll(series.dataItems);
    legend.labels.template.set('width', 95);
    legend.labels.template.set('fontSize', 13);

    // center text
    chart.seriesContainer.children.push(am5.Label.new(root, {
      textAlign: 'center',
      centerY: am5.p50,
      centerX: am5.p50,
      text: numbers && numbers.length > 0 ? '[bold fontSize:28px]Crops[/]' : undefined,
    }));

    // show/dispose
    series.appear(1000, 100);

    return () => void root.dispose();
  }, [chartId, numbers]);

  if (!numbers) {
    return null;
  }
  const height = numbers && numbers.length > 0 ? 475 : 100;

  return (
    <InitialCard left="User crops" autoHeight boxClass={cls.box}>
      <div className={cls.chart}>
        <div id={chartId} style={{ height }} />
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    paddingBottom: '0 !important',
  },
  chart: {
    marginTop: -40,
  },
});
