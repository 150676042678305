import { makeStyles } from '@mui/styles';
import { CreditRisk } from '@types';
import { moneyFormatter } from '@utils';

export const RiskTaxesLegacy = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const { taxExpenditures } = risk;
  const taxes = taxExpenditures.values || [];
  const totals: Record<string, number> = {};

  for (const tax of taxes) {
    totals.iEmitted = (tax.iEmitted || 0) + tax.iEmitted;
    totals.iCancelled = (tax.iCancelled || 0) + tax.iCancelled;
    totals.iCurrent = (tax.iCurrent || 0) + tax.iCurrent;
    totals.eReceived = (tax.eReceived || 0) + tax.eReceived;
    totals.eCancelled = (tax.eCancelled || 0) + tax.eCancelled;
    totals.eCurrent = (tax.eCurrent || 0) + tax.eCurrent;
    totals.margin = (tax.margin || 0) + tax.margin;
  }

  return (
    <table className={cls.table}>
      <tbody>
        <tr>
          <td></td>
          <td colSpan={3}>Ingresos</td>
          <td></td>
          <td colSpan={3}>Gastos</td>
        </tr>
        <tr>
          <td>Periodo</td>
          <td>Emitido</td>
          <td>Cancelado</td>
          <td>Vigente</td>
          <td>Recibido</td>
          <td>Cancelado</td>
          <td>Vigente</td>
          <td>Margen</td>
        </tr>
        {taxes.map(tax => (
          <tr key={tax.year}>
            <td>{tax.year}</td>
            <td>{moneyFormatter.format(tax.iEmitted || 0)}</td>
            <td>{moneyFormatter.format(tax.iCancelled || 0)}</td>
            <td>{moneyFormatter.format(tax.iCurrent || 0)}</td>
            <td>{moneyFormatter.format(tax.eReceived || 0)}</td>
            <td>{moneyFormatter.format(tax.eCancelled || 0)}</td>
            <td>{moneyFormatter.format(tax.eCurrent || 0)}</td>
            <td>{moneyFormatter.format(tax.margin || 0)}</td>
          </tr>
        ))}
        <tr>
          <td>Total</td>
          <td>{moneyFormatter.format(totals.iEmitted || 0)}</td>
          <td>{moneyFormatter.format(totals.iCancelled || 0)}</td>
          <td>{moneyFormatter.format(totals.iCurrent || 0)}</td>
          <td>{moneyFormatter.format(totals.eReceived || 0)}</td>
          <td>{moneyFormatter.format(totals.eCancelled || 0)}</td>
          <td>{moneyFormatter.format(totals.eCurrent || 0)}</td>
          <td>{moneyFormatter.format(totals.margin || 0)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const useStyles = makeStyles({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& td': {
      borderTop: '1px solid #042E6B',
      borderLeft: '1px solid #042E6B',
      fontSize: 12,
      fontWeight: 400,
      color: '#042E6B',
      width: '12.5%',
      padding: '3px 8px',
      backgroundColor: '#F0F3FB',
      '&:last-child': {
        borderRight: '1px solid #042E6B',
      },
      '&[colspan]': {
        textAlign: 'center',
      },
    },
    '& tr:first-child td': {
      backgroundColor: '#042E6B',
      color: '#FFF',
      fontSize: 12,
      fontWeight: 500,
      borderLeft: '1px solid white',
      '&:first-child': {
        borderTopLeftRadius: 5,
        borderLeft: '1px solid #042E6B',
      },
      '&:last-child': {
        borderTopRightRadius: 5,
      },
    },
    '& tr:nth-child(2) td': {
      backgroundColor: 'transparent',
      color: '#042E6B',
    },
    '& tr:last-child td': {
      borderBottom: '1px solid #042E6B',
      '&:first-child': {
        borderBottomLeftRadius: 5,
      },
      '&:last-child': {
        borderBottomRightRadius: 5,
      },
    },
  },
});
