import { clsx } from 'clsx';
import { Link } from 'react-admin';

import { Order } from '@types';
import { formatMoney } from '@utils';
import cls from './OrderPositions.module.css';

export const OrderPositions = ({ data }: { data: Order }) => {
  if (!Array.isArray(data.positions) || data.positions.length === 0) {
    return null;
  }

  const orderedPositions = (data.positions || []).sort((a, b) => (a.id || 0) - (b.id || 0));

  return (
    <table className={cls.table}>
      <thead>
        <tr>
          <th className={clsx(cls.th, cls.thImg)}>Image</th>
          <th className={clsx(cls.th, cls.thProduct)}>Product</th>
          <th className={clsx(cls.th, cls.thDistributor)}>Distributor</th>
          <th className={clsx(cls.th, cls.thCategory)}>Category</th>
          <th className={clsx(cls.th, cls.thCost)}>Cost</th>
          <th className={clsx(cls.th, cls.thForm)}>Form</th>
          <th className={clsx(cls.th, cls.thPack)}>Pack</th>
          <th className={clsx(cls.th, cls.thUnits)}>Units</th>
          <th className={clsx(cls.th, cls.thTotalCost)}>Total cost</th>
        </tr>
      </thead>
      <tbody>
        {orderedPositions.map(pos => (
          <tr key={pos.id} className={clsx(cls.row, cls.rowProducts)}>
            <td className={clsx(cls.td, cls.tdImg)}>
              {pos.product?.image ? (
                <img
                  src={pos.product.image}
                  alt={pos.product.name}
                  className={cls.image}
                />
              ) : (
                <span className={cls.noimg}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 17 21" fill="none">
                    <g clipPath="url(#clip0_503_5615)">
                      <path
                        d="M5.69531 10.515C5.71083 10.9289 5.85829 11.3268 6.11829 11.6483C6.42873 11.9256 6.82067 12.0863 7.23201 12.1024C7.2126 11.6845 7.06126 11.2826 6.79739 10.9651C6.49082 10.6918 6.10277 10.5351 5.69919 10.515H5.69531Z"
                        fill="white"/>
                      <path
                        d="M8.1898 12.1064C9.02023 12.1184 9.83127 11.8251 10.4677 11.2745C11.0032 10.6114 11.2865 9.77553 11.2748 8.91552C10.4444 8.90346 9.63336 9.19683 8.99695 9.7474C8.46144 10.4105 8.17816 11.2464 8.1898 12.1064Z"
                        fill="white"/>
                      <path
                        d="M15.5982 17.2662C15.5594 17.222 15.5283 17.1778 15.4895 17.1336C16.4674 12.7853 16.4674 8.26025 15.4895 3.90797C15.5128 3.88386 15.5322 3.85572 15.5555 3.83161L16.0794 3.11226C16.2113 2.89123 16.32 2.65412 16.4015 2.40898C16.6071 1.77402 16.5373 1.23551 16.2074 0.893922C15.606 0.271019 14.3758 0.588498 13.3087 1.62533C10.1499 0.664854 6.78549 0.664854 3.62673 1.62533C2.5557 0.588498 1.32945 0.271019 0.727961 0.893922C0.398115 1.23551 0.328266 1.77402 0.533934 2.40898C0.615426 2.65011 1.42258 3.88386 1.44586 3.91199C0.499009 8.26025 0.499009 12.7773 1.44586 17.1296C1.40318 17.1778 1.37213 17.222 1.32945 17.2702L0.813333 17.9936C0.336027 18.8456 0.273938 19.6734 0.727961 20.1436C0.945272 20.3606 1.24019 20.4732 1.54288 20.4611C2.33839 20.3848 3.08345 20.007 3.63061 19.4042C6.80101 20.2722 10.1344 20.2722 13.3048 19.4042C14.3758 20.449 15.6021 20.7665 16.2074 20.1436C16.6615 19.6734 16.5994 18.8416 16.1182 17.9896L15.5982 17.2662ZM11.1239 11.9655C10.1654 12.9622 8.68695 13.0827 8.02726 13.0827H7.50727C6.75832 13.1109 6.02878 12.8456 5.45834 12.3393C4.6279 11.4833 4.74432 10.0566 4.74432 9.99232C4.76372 9.75924 4.94223 9.57437 5.1673 9.55428C5.22939 9.55428 6.61862 9.42166 7.41801 10.2777C7.4607 10.3219 7.49563 10.362 7.53443 10.4103C7.69741 9.90793 7.96517 9.44577 8.32218 9.06801C9.56395 7.78202 11.6672 7.96286 11.7565 7.96286C11.9815 7.98296 12.16 8.16782 12.1794 8.4009C12.1794 8.49334 12.3696 10.6715 11.1239 11.9615V11.9655Z"
                        fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_503_5615">
                        <rect width="16.1004" height="19.8887" fill="white" transform="translate(0.417969 0.57666)"/>
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              )}
            </td>
            <td
              onClick={e => e.stopPropagation()}
              className={clsx(cls.td, cls.tdProduct)}
            >
              <b>{pos.distributor}</b> |&nbsp;
              <Link to={{ pathname: `/product/${pos.productId}` }}>
                <span className={cls.link}>{pos.product.name}</span>
              </Link>
            </td>
            <td className={clsx(cls.td, cls.tdDistributor)}>{pos.distributor}</td>
            <td className={clsx(cls.td, cls.tdCategory)}>{pos.product.category}</td>
            <td className={clsx(cls.td, cls.tdPrice)}>
              <div>{formatMoney(pos.productPrice)}</div>
              <div><small><b>{pos.isPesoCost ? 'Peso' : 'USD'}</b></small></div>
            </td>
            <td className={clsx(cls.td, cls.tdForm)}>{pos.product.form}</td>
            <td className={clsx(cls.td, cls.tdPack)}>{pos.product.pack}</td>
            <td className={clsx(cls.td, cls.tdUnits)}>{pos.qty}</td>
            <td className={clsx(cls.td, cls.tdTotalCost)}>
              <div>{formatMoney(pos.totalCost)}</div>
              <div><small><b>{pos.isPesoCost ? 'Peso' : 'USD'}</b></small></div>
            </td>
          </tr>
        ))}
        <tr className={cls.total}>
          <td className={cls.td}>Total</td>
          <td className={cls.td} colSpan={7}/>
          <td className={cls.td}>{formatMoney(data.totalCost)}</td>
        </tr>
      </tbody>
    </table>
  );
};
