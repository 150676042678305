import { PropsWithChildren } from 'react';
import { useRedirect } from 'react-admin';
import { Lock } from '@mui/icons-material';
import { clsx } from 'clsx';

import cls from './initial-card.module.css';
import { LightTooltip } from '@components/LightTooltip';

interface Props {
  left?: string | JSX.Element | false;
  center?: string | JSX.Element | false;
  right?: string | JSX.Element | false;
  link?: string;
  boxClass?: string;
  wrapperClass?: string;
  contentClass?: string;
  autoHeight?: boolean;
  mt?: number;
  mb?: number;
  fullHeight?: boolean;
  extraPadding?: boolean;
  mediumPadding?: boolean;
  riskPadding?: boolean;
  lockText?: string | boolean | null | undefined;
  minHeight?: number;
}

export const InitialCard = ({
  children, left, center, right, link, boxClass,
  wrapperClass, contentClass, autoHeight, mt, mb, fullHeight,
  extraPadding, mediumPadding, riskPadding, lockText, minHeight,
}: PropsWithChildren<Props>) => {
  const showHeader = left || right || center;
  const redirect = useRedirect();

  const onClick = () => link && redirect(link);

  return (
    <div onClick={onClick} className={clsx(
      wrapperClass,
      'InitialCard',
      cls.card,
      boxClass,
      autoHeight && cls.autoHeight,
      link && cls.cardWithLink,
      !showHeader && cls.cardWithoutHeader,
      extraPadding && cls.extraPadding,
      mediumPadding && cls.mediumPadding,
      riskPadding && cls.riskPadding,
      fullHeight && cls.fullHeight,
    )} style={{ marginTop: mt, marginBottom: mb, minHeight }}>
      {showHeader ? (
        <div className={clsx(
          cls.cardHeader,
          center && cls.center,
        )}>
          {left && <div>{left}</div>}
          {center && <div>{center}</div>}
          {right && <div>{right}</div>}
          {lockText && (
            <LightTooltip extraWidth title={lockText}>
              <Lock className={cls.lockIcon} />
            </LightTooltip>
          )}
        </div>
      ) : null}
      <div className={contentClass}>
        {children}
      </div>
    </div>
  );
};
