import React, { ReactNode, useRef } from 'react';
import { DatePicker, DatePickerProps } from 'antd';
import moment from 'moment-timezone';
import IMask from 'imask';
import { useController } from 'react-hook-form';
import { FormHelperText, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CalendarFilled } from '@ant-design/icons';

const DATE_FORMAT = 'DD.MM.YYYY';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: new Date().getFullYear() },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

interface ExtraProps {
  source: string;
  label?: string | null;
  disabledFutureDates?: boolean;
  suffixIcon?: ReactNode;
  width?: number;
}

export const AntdDateInput = (props: DatePickerProps & ExtraProps) => {
  const {
    field: { value, onChange },
  } = useController({ name: props.source });
  const ref = useRef<any>();
  const cls = useStyles();
  const { width, suffixIcon, placeholder } = props;

  return (
    <div className={!props.label ? 'ant-form-item-control-input--without-label without-label' : ''}>
      {props.label && (
        <InputLabel shrink>{props.label || props.source}</InputLabel>
      )}
      <DatePicker
        className={cls.picker}
        bordered={false}
        value={value ? moment(value) : null}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          const input = event.target as HTMLInputElement;
          input.value = MASKED.resolve(input.value);
          setTimeout(() => {
            const momentDate = moment(input.value, DATE_FORMAT, true);
            onChange && momentDate.isValid() && onChange(momentDate.format('YYYY-MM-DD'));
          }, 200);
        }}
        onChange={selectedValue => {
          const momentDate = moment(selectedValue, DATE_FORMAT, true);
          momentDate.isValid() && onChange(momentDate.format('YYYY-MM-DD'));
        }}
        picker="date"
        format={DATE_FORMAT}
        disabledDate={props.disabledFutureDates
          ? (current) => current && current > moment().endOf('day')
          : undefined
        }
        suffixIcon={suffixIcon ? suffixIcon : (
          <CalendarFilled className={cls.calendarIcon} />
        )}
        pickerRef={ref}
        placeholder={placeholder || ''}
        style={{ width }}
      />
      <FormHelperText>&nbsp;</FormHelperText>
    </div>
  );
};

const useStyles = makeStyles({
  picker: {
    '& .ant-picker-input': {
      borderBottom: '1px solid rgba(0, 42, 119, 0.10) !important',
    },
  },
  calendarIcon: {
    color: '#777',
  },
});
