import { Credit } from './Credit';
import { RevenueDataProps } from '@pages/credit-risk/analysis/components/RevenueTable';
import { TaxCropDataProps } from 'src/pages/credit-risk/analysis/components/TaxCropTable';
import { AddemVariablesProps } from '@pages/credit-risk/analysis/components/AddemVariablesTable';
import { DropdownInfoDataProps } from '@pages/credit-risk/analysis/components/DropdownInfoWidget';
import { TotalVariablesProps } from '@pages/credit-risk/analysis/components/TotalVariablesTable';
import { CreditBureauData } from '@pages/credit-risk/analysis/components/CreditBureau';
import { ScoreTableData } from '@pages/credit-risk/analysis/components/ScoreTable';
import { RiskQuestionType } from './Risk';
import {
  CreditReportTypes,
  TableBureauHeader, TableBureauScoreHeader,
  TableCreditBureauRow,
  TableCreditBureauScoreRow, TableCropHeader, TableCropRow, TableFiscalHeader, TableFiscalRow,
  TablePastCycleRow,
  TableProjectedCycleRow,
} from '@network';

export interface CreditReport {
  id: number,
  type: CreditReportTypes,
  creditId: number,
  adminId: number,
  fileId: number,
  file: File | null,
}

export interface IFinancialTableRow {
  key: string;
  title: string;
  value: number;
  percent?: number;
}

export type FinancialResType = 'VERIFIABLE' | 'NON_VERIFIABLE';

export enum FinancialResult {
  VERIFIABLE = 'VERIFIABLE',
  NON_VERIFIABLE = 'NON_VERIFIABLE',
}

export interface IFinancialResOption {
  label: string;
  key: FinancialResType;
}

export interface IFinancialTable {
  title: string;
  key: string;
  result: {options: IFinancialResOption[]; selected: FinancialResType};
  values: IFinancialTableRow[];
}

export interface RiskGeography {
  category: 'A' | 'B' | 'C' | 'D';
  grade: number;
  name: string;
}

export interface ReplyAnswer {
  id: number;
  progressPercent: number | null;
  title: string;
}

export interface ReplyCategory {
  id: number;
  position: number;
  title: string;
}

export interface ReplyQuestion {
  answerId: number | null;
  answers: ReplyAnswer[];
  category: ReplyCategory | null;
  division: string;
  questionId: number;
  title: string;
  titleMx: string;
  type: RiskQuestionType;
  isFixed: boolean;
  tag: string;
  value: string | null;
  position: number;
  progressPercent: number | null;
}

export interface CreditRisk {
  id: number;
  creditId: number;
  buyer: string;
  supplier: '' | null;
  credit: Credit;
  state: CreditRiskState;
  riskScore: number | null;
  riskStatus: CreditRiskStatus;
  approvedAmount: number;
  creditTerm: string;
  interestRate: number;
  membership: number;
  pagaComission: number;
  notaryFees: number;
  insurance: number;
  specialConditions: string;
  createdAt: string;
  updatedAt: string;
  financialPastTable: IFinancialTable;
  financialProjectedTable: IFinancialTable;
  taxExpenditures: RevenueDataProps;
  taxCrop: TaxCropDataProps;
  geography: RiskGeography;
  offTakerBusinessAge: DropdownInfoDataProps,
  offTakerPaymentsDso: DropdownInfoDataProps,
  offTakerLegalIncidences: DropdownInfoDataProps,
  offTakerLegalTimeWorking: DropdownInfoDataProps,
  supplierScore: DropdownInfoDataProps,
  supplierDpo: DropdownInfoDataProps,
  supplierLegalIncidences: DropdownInfoDataProps,
  supplierTimeWorking: DropdownInfoDataProps,
  otherVariablesTable: any;
  addemVariablesTable: AddemVariablesProps;
  totalVariablesTable: TotalVariablesProps;
  creditBureau: CreditBureauData;
  scoreCashRatio: ScoreTableData;
  scoreTerm: RiskInfo;
  scoreAmount: DropdownInfoDataProps;
  cropTechnification: RiskInfo;
  otherVariables: RiskValues;
  addemVariables: RiskValues;
  totalVariables: TotalVariablesProps;
  replyTerms: ReplyQuestion[];
  replyOther: ReplyQuestion[];
  replyScore: ReplyQuestion[];
  isFixed: boolean;
  isLegacy: boolean;
  incomeSuppliers: {
    avgDpo: number;
    values: {
      period: number;
      emitted: number;
    }[]
  };
  tablePastCycle: {rows: TablePastCycleRow[]};
  tableProjectedCycle: {rows: TableProjectedCycleRow[]};
  tableFiscal: {headers: TableFiscalHeader[], rows: TableFiscalRow[]};
  tableCrop: {headers: TableCropHeader[], rows: TableCropRow[]};
  tableCreditBureau: {headers: TableBureauHeader[], rows: TableCreditBureauRow[]};
  tableCreditBureauScore: {headers: TableBureauScoreHeader[], rows: TableCreditBureauScoreRow[]};
  showAddemVariables: boolean;
  buyerNames: string | null;
  retailerNames: string | null;
  associateNames: string | null;
}

export interface RiskValues {
  values: {
    key: string;
    title: string;
    value: string
  }[];
}

export interface RiskInfo {
  selected: string;
  value: number;
  extra: number;
}

export enum CreditRiskState {
  PENDING = 'pending',
  COMPLETE = 'complete',
}

export enum CreditRiskStatus {
  DENIED = 'denied',
  APPROVED = 'approved',
}

export const fixedRiskText = 'This record can\'t be edited because the related credit has statuses Signed, Delay, Restructure, Denied or this risk analysis record has the "Complete" status of "Underwritting" value.';
