import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Col, DatePicker, Input, Row, Select, TimePicker } from 'antd';
import { useRequest } from '@hooks';
import { useNotify } from 'react-admin';
import { useListContext } from 'ra-core';
import moment from 'moment-timezone';
import IMask from 'imask';

import { adminHttp, userHttp, cropHttp, stateHttp } from '@network';
import { BlueButton, BlueOutlineButton } from '@components';
import { useModalContext } from '@providers';
import { inactivityReasons } from '@pages/user/UserEdit/UserInfo';
import { financeSettingsHttp } from '@network/finance-settings-http';

const propsList = ['Users status', 'Crops', 'State',
  'Manager', 'Civil status', 'Gender', 'Reason for inactivity', 'Activations'];
type PropType = 'Users status' | 'Crops' | 'State' | 'Manager' | 'Civil status' | 'Gender' | 'Reason for inactivity' | 'Activations';
const genders = ['Hombre', 'Mujer', 'Prefiero no decir'];
type GenderType = 'Hombre' | 'Mujer' | 'Prefiero no decir';
const marriageOptions = ['married', 'divorced', 'widow', 'single'];
type MarriageType = 'married' | 'divorced' | 'widow' | 'single';
const userStatuses = ['Active', 'Inactive', 'Set reminder'];
type UserStatus = 'Active' | 'Inactive' | 'Set reminder';
const userInactivityReasons = inactivityReasons.map(i => i.name);

export const UserListActions = () => {
  const { selectedIds, refetch } = useListContext();
  const [prop, setProp] = useState<PropType>();
  const { hideModal } = useModalContext();
  const notify = useNotify();
  const cls = useStyles();

  const [userStatus, setUserStatus] = useState<UserStatus>();
  const [adminNote, setAdminNote] = useState<string>();

  const reminderDate = useRef<string>('');
  const reminderTime = useRef<string>('12:00');

  const [cropOptions, setCropOptions] = useState<{value: number; label: string}[]>([]);
  const [cropIds, setCropIds] = useState<number[]>([]);

  const [stateOptions, setStateOptions] = useState<{value: number; label: string}[]>([]);
  const [stateId, setStateId] = useState<number>();

  const [managerOptions, setManagerOptions] = useState<{value: number; label: string}[]>([]);
  const [managerId, setManagerId] = useState<number>();

  const [activationOptions, setActivationOptions] = useState<{value: number; label: string}[]>([]);
  const [activationId, setActivationId] = useState();

  const [gender, setGender] = useState<GenderType>();
  const [marriage, setMarriage] = useState<MarriageType>();
  const [inactiveReason, setInactiveReason] = useState();

  const resetValues = () => {
    setUserStatus(undefined);
    setAdminNote(undefined);
    reminderDate.current = '';
    reminderTime.current = '12:00';
    setCropIds([]);
    setStateId(undefined);
    setManagerId(undefined);
    setGender(undefined);
    setMarriage(undefined);
    setInactiveReason(undefined);
  };

  const onCancel = () => {
    setProp(undefined);
    resetValues();
    hideModal();
  };

  const { loading, submit } = useRequest(async () => {
    if (!Array.isArray(selectedIds) || selectedIds.length === 0) {
      return notify('No Users are selected');
    }
    if (prop === 'Crops' && cropIds.length === 0) {
      return notify('No Crops are selected');
    }
    if (prop === 'State' && !stateId) {
      return notify('No State is selected');
    }
    if (prop === 'Manager' && !managerId) {
      return notify('No manager is selected');
    }
    if (prop === 'Gender' && !gender) {
      return notify('No Gender is selected');
    }
    if (prop === 'Civil status' && !marriage) {
      return notify('No Civil status is selected');
    }
    if (prop === 'Users status' && !userStatus) {
      return notify('No User status is selected');
    }
    if (prop === 'Users status' && userStatus === 'Set reminder' && !reminderDate.current) {
      return notify('No Reminder date is selected');
    }
    if (prop === 'Reason for inactivity' && !inactiveReason) {
      return notify('No Reason for inactivity is selected');
    }

    const updatedTotal = await userHttp.updateBulk({
      userIds: selectedIds,
      cropIds,
      stateId,
      managerId,
      activationId,
      gender,
      marriage,
      inactiveReason,
      userStatus,
      adminNote,
      reminderDate: reminderDate.current || undefined,
      reminderTime: reminderTime.current || undefined,
    });

    notify(`Updated ${updatedTotal} users`);
    onCancel();
    refetch();
  });

  useEffect(() => void resetValues(), [prop]);

  useEffect(() => {
    // load crops
    (async () => {
      const crops = await cropHttp.getAll();
      const sortedCrops = crops.map(crop => ({
        value: crop.id,
        label: crop.nameMx,
      })).sort((a, b) => a.label > b.label ? 1 : -1);
      setCropOptions(sortedCrops);
    })();
    // load states
    (async () => {
      const states = await stateHttp.getAll();
      const sortedStates = states.map(state => ({
        value: state.id,
        label: state.name,
      })).sort((a, b) => a.label > b.label ? 1 : -1);
      setStateOptions(sortedStates);
    })();
    // load managers
    (async () => {
      const managers = await adminHttp.getResponsibleManagers();
      const sortedManagers = managers.map(manager => ({
        value: manager.id,
        label: manager.name,
      })).sort((a, b) => a.label > b.label ? 1 : -1);
      setManagerOptions(sortedManagers);
    })();
    // load activations
    (async () => {
      const activations = await financeSettingsHttp.getActivations();
      const sortedActivations = activations.map(a => ({
        value: a.id,
        label: a.activation,
      })).sort((a, b) => a.label > b.label ? 1 : -1);
      setActivationOptions(sortedActivations);
    })();
  }, []);

  const selectReminderDate = (date: any) => {
    reminderDate.current = date.format('YYYY-MM-DD');
  };

  const selectReminderTime = (date: any) => {
    reminderTime.current = date.format('HH:mm');
  };

  const showTitleProp = prop && prop !== 'Users status';
  const isStatusContact = userStatus === 'Set reminder';

  return (
    <div className={cls.box}>
      <div className={cls.title}>Properties to update</div>
      <Select value={prop} onChange={v => setProp(v)} className={cls.select}
        placeholder="Select a property to edit">
        {propsList.map(propItem => (
          <Select.Option key={propItem} value={propItem}>
            {propItem}
          </Select.Option>
        ))}
      </Select>

      <div className={cls.content}>
        {showTitleProp && <div className={cls.title}>{prop}</div>}
        {prop === 'Crops' && (
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Select crops"
            className={cls.select}
            onChange={values => setCropIds((values || []).filter(Number))}
            options={cropOptions}
            optionFilterProp="children"
            filterOption={(input, option) =>
              `${option?.label}`.toLowerCase().includes(input.toLowerCase())}
            autoFocus
          />
        )}
        {prop === 'State' && (
          <Select value={stateId}
            onChange={v => setStateId(v)}
            className={cls.select}
            placeholder="Select state"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) => {
              return option.props?.children?.toString()
                .toLowerCase().includes(input.toLowerCase());
            }}
          >
            {stateOptions.map(stateOption => (
              <Select.Option key={stateOption.value} value={stateOption.value}>
                {stateOption.label}
              </Select.Option>
            ))}
          </Select>
        )}
        {prop === 'Manager' && (
          <Select value={managerId}
            onChange={v => setManagerId(v)}
            className={cls.select}
            placeholder="Select manager"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) => {
              return option.props?.children?.toString()
                .toLowerCase().includes(input.toLowerCase());
            }}
          >
            {managerOptions.map(managerOption => (
              <Select.Option key={managerOption.value} value={managerOption.value}>
                {managerOption.label}
              </Select.Option>
            ))}
          </Select>
        )}
        {prop === 'Gender' && (
          <Select value={gender}
            onChange={v => setGender(v)}
            className={cls.select}
            placeholder="Select gender"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) => {
              return option.props?.children?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {genders.map(gender => (
              <Select.Option key={gender} value={gender}>
                {gender}
              </Select.Option>
            ))}
          </Select>
        )}
        {prop === 'Civil status' && (
          <Select value={marriage}
            onChange={v => setMarriage(v)}
            className={cls.select}
            placeholder="Select civil status"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) => {
              return option.props?.children?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {marriageOptions.map(marriage => (
              <Select.Option key={marriage} value={marriage}>
                {marriage}
              </Select.Option>
            ))}
          </Select>
        )}
        {prop === 'Users status' && (
          <>
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={isStatusContact ? 12 : 24}>
                <div className={cls.title}>Update to</div>
                <Select value={userStatus}
                  onChange={v => setUserStatus(v)}
                  className={cls.select}
                  placeholder="Select User status"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) => {
                    return option.props?.children?.toString()
                      .toLowerCase().includes(input.toLowerCase());
                  }}
                >
                  {userStatuses.map(status => (
                    <Select.Option key={status} value={status}>
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              {isStatusContact && (
                <Col xs={24} sm={12}>
                  <div className={cls.title}>Reminder</div>
                  <div className={cls.reminder}>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      className={cls.reminderDay}
                      suffixIcon={null}
                      allowClear={false}
                      disabledDate={(current) => current.isBefore(moment().subtract(1, 'day'))}
                      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        const input = event.target as HTMLInputElement;
                        input.value = MASKED.resolve(input.value);
                        setTimeout(() => {
                          const momentDate = moment(input.value, DATE_FORMAT, true);
                          momentDate.isValid() && selectReminderDate(momentDate);
                        }, 200);
                      }}
                      onChange={selectedValue => {
                        const momentDate = moment(selectedValue, DATE_FORMAT, true);
                        momentDate.isValid() && selectReminderDate(momentDate);
                      }}
                      picker="date"
                      format={DATE_FORMAT}
                    />
                    <TimePicker
                      onChange={selectReminderTime}
                      format="HH:mm"
                      placeholder="00:00"
                      className={cls.reminderTime}
                      defaultValue={moment('12:00', [moment.ISO_8601, 'HH:mm'])}
                      suffixIcon={null}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <div className={cls.group}>
              <div className={cls.title}>Admin note</div>
              <Input.TextArea
                className={cls.textarea}
                onChange={e => setAdminNote(e.target.value)}
                placeholder="Add admin note."
              />
            </div>
          </>
        )}
        {prop === 'Reason for inactivity' && (
          <Select
            value={inactiveReason}
            onChange={v => setInactiveReason(v)}
            className={cls.select}
            placeholder="Select reason for inactivity"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) => {
              return option.props?.children?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {userInactivityReasons.map(reason => (
              <Select.Option key={reason} value={reason}>
                {reason}
              </Select.Option>
            ))}
          </Select>
        )}
        {prop === 'Activations' && (
          <Select
            value={activationId}
            onChange={v => setActivationId(v)}
            className={cls.select}
            placeholder="Select activation"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) => {
              return option.props?.children?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {activationOptions.map(a => (
              <Select.Option key={a.value} value={a.value}>
                {a.label}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>

      <div className={cls.controls}>
        <BlueButton className={cls.btn} onClick={submit} disabled={loading}>
          Update
        </BlueButton>
        <BlueOutlineButton className={cls.btn} onClick={onCancel}>
          Cancel
        </BlueOutlineButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  box: {},
  group: {
    marginTop: 15,
  },
  title: {
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 500,
  },
  select: {
    marginTop: 10,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E7EE',
    backgroundColor: '#F3F7F9',
    '& .ant-select-selector': {
      backgroundColor: 'transparent !important',
    },
  },
  content: {
    marginTop: 15,
  },
  controls: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    margin: '0 7px',
    padding: '5px 29px !important',
    height: 34,
    borderRadius: 8,
    textTransform: 'none',
  },
  textarea: {
    marginTop: 10,
    padding: '10px 19px 12px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
  },
  reminder: {
    display: 'flex',
    marginTop: 10,
    height: 32,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E7EE',
    backgroundColor: '#F3F7F9',
    overflow: 'hidden',
    '& > div': {
      height: '100% !important',
      borderRadius: 8,
      border: 'none',
      backgroundColor: '#F3F7F9 !important',
      '& .ant-picker-input': {
        borderBottom: 'none !important',
        '& > input': {
          textAlign: 'center',
        },
      },
    },
  },
  reminderDay: {
    flex: 2,
    '& input': {
      textAlign: 'left !important',
      marginLeft: 25,
    },
  },
  reminderTime: {
    flex: 1,
    borderLeft: '1px solid #E0E7EE !important',
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
  },
});

const DATE_FORMAT = 'DD/MM/YYYY';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: new Date().getFullYear() },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});
