import { MutableRefObject, ReactNode, useCallback, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { BlueButton, InitialCard } from '@components';
import { creditRiskHttp } from '@network';

interface Props {
  risk: CreditRisk;
  forwardRef: MutableRefObject<ReactNode>;
}

export const RiskTapeFile = ({ risk, forwardRef }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [counter, setCounter] = useState<number | null>(null);

  const cls = useStyles();

  const downloadHandler = useCallback(async () => {
    setIsLoading(true);

    const div = forwardRef.current;

    const pdfFilename = `credit_analysis_${risk?.credit?.code}.pdf`;
    await new Promise((resolve) => setTimeout(resolve, 250));

    const downloadReport = async (div: any) => {
      if (div) {
        const canvas = await html2canvas(div);

        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }

        // blob = doc.output('blob');
        // TODO отправить blob на сервер, чтобы упаковать в zip

        doc.save(pdfFilename);
      }
    };

    try {
      await downloadReport(div);
      await creditRiskHttp.downloadFiles({ riskId: risk.id });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [forwardRef]);

  useEffect(() => {
    if (risk) {
      (async () => {
        try {
          const response = await creditRiskHttp.downloadFilesCounter({ riskId: risk.id });

          if (response) {
            setCounter(response);
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [risk]);

  return (
    <InitialCard center="Loan Tape File" contentClass={cls.content} wrapperClass={cls.wrapper}>
      <BlueButton
        onClick={downloadHandler}
        className={cls.button}
        disabled={isLoading || !counter}
      >
        {isLoading ? 'Loading' : 'Download File'}
        {counter && <span className={cls.counter}>{counter}</span>}
      </BlueButton>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  wrapper: {
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  button: {
    width: '100%',
    padding: '12px 15px 15px',
    marginTop: 16,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  info: {
    borderRadius: 7,
    backgroundColor: '#042E6B',
    marginTop: -44,
    height: 87,
    width: '100%',
    padding: 25,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoText: {
    color: '#FFF',
  },
  counter: {
    color: '#042E6B',
    backgroundColor: '#fff',
    width: 20,
    height: 20,
    borderRadius: '50%',
    lineHeight: '20px',
  },
});
