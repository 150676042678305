import { clsx } from 'clsx';
import { RightCircleFilled } from '@ant-design/icons';

import { centsFormatter } from '@utils';
import { InitialCard, LightTooltip } from '@components';
import { Credit, CreditInvoiceStatusType, CreditStatus } from '@types';
import cls from './CreditInvoiceWidget.module.css';

const DelayDays = ({ credit }: { credit: Credit }) => {
  let variant = 1;

  if (!credit.delayDays || credit.status !== CreditStatus.DELAY) variant = 1;
  else if (credit.delayDays > 90) variant = 5;
  else if (credit.delayDays >= 61 && credit.delayDays <= 90) variant = 4;
  else if (credit.delayDays >= 31 && credit.delayDays <= 60) variant = 3;
  else if (credit.delayDays >= 1 && credit.delayDays <= 30) variant = 2;

  return (
    <LightTooltip title={!credit.delayDays ? '' : `delay up to ${credit.delayDays} days`} placement="top-end">
      <div className={clsx(cls.parIcon, cls[`variant_${variant}`])}/>
    </LightTooltip>
  );
};

export const CreditInvoiceWidget = ({ credit }: { credit: Credit }) => {
  const newInvoices = credit.invoices?.length
    ? credit.invoices.filter((i) => i.status === CreditInvoiceStatusType.PENDING).length
    : 0;
  const checkedInvoices = credit.invoices?.length
    ? credit.invoices.filter((i) => i.status !== CreditInvoiceStatusType.PENDING).length
    : 0;

  return (
    <InitialCard
      left="Invoice and Credit Manager"
      right={<RightCircleFilled className={cls.headerIcon} />}
      link={`/credit/${credit.id}?invoices`}
      contentClass={cls.content}
      wrapperClass={cls.cardWrapper}
    >
      <div className={cls.wrap}>
        <div className={cls.balanceValue}>
          <span className={cls.balanceValueText}>
            <span className={cls.balanceText}>Balance</span>
            {centsFormatter.format(credit.balance)}
          </span>
          <DelayDays credit={credit}/>
        </div>

        <div className={cls.invoices}>
          <div className={clsx(cls.newInvoices, cls.invoice)}>
            <div className={cls.invoiceTitle}>
              <span className={cls.invoiceIndicator}/>New invoices
            </div>
            <div className={cls.invoiceCounter}>{newInvoices} invoices</div>
          </div>
          <div className={clsx(cls.checkedInvoices, cls.invoice)}>
            <div className={cls.invoiceTitle}>
              <span className={clsx(cls.invoiceIndicator, cls.invisible)}/>Checked invoices
            </div>
            <div className={cls.invoiceCounter}>{checkedInvoices} invoices</div>
          </div>
        </div>
      </div>
    </InitialCard>
  );
};
