import { Crop, State, Admin } from '@types';

export interface LeadFormProps {
  name: string,
  phone: string,
  email: string,
  stage: string,
  stateId: number | null,
  cropIds: number[],
  responsibleAdminId: number | null,
  activationId: number | null,
}

export interface Lead {
  addedByUserId: number;
  comment: string | null;
  company: string | null;
  crops: Crop[];
  email: string;
  hubspotAt: string;
  hubspotId: string;
  id: number;
  name: string;
  phone: string;
  responsibleAdmin: Admin | null;
  stage: string | null;
  state: State | null;
  activationId: number | null,
}

export const leadStageOptions = [
  { id: 'contacting', name: 'Contacting' },
  { id: 'profiling', name: 'Profiling' },
  { id: 'quote', name: 'Quote' },
  { id: 'rejected', name: 'Rejected' },
];
