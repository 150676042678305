import { NumberFieldProps, NumberField, useRecordContext } from 'react-admin';

import { OrderPay } from './OrderPay';
import { OrderTransactionPartner } from '@types';
import { moneyFormatter } from '@utils';

interface ExtraProps {
  partner: OrderTransactionPartner;
  isIncome?: boolean;
}

export const OrderPayField = (props: NumberFieldProps & ExtraProps) => {
  const record = useRecordContext();
  const { partner, isIncome, ...restProps } = props;

  if (!record) {
    return null;
  }

  return (
    <div onClick={e => void e.stopPropagation()}>
      <NumberField
        options={{ style: 'currency', currency: 'USD' }}
        {...restProps}
      />
      <OrderPay
        modalTitle={`Order #${record.id} — Current paid:${moneyFormatter.format(record.balance)}  Operation:${partner.toUpperCase()}`}
        orderId={+record.id}
        partner={partner}
        isIncome={isIncome}
      />
    </div>
  );
};
