import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNotify } from 'react-admin';
import { InputNumber, Select } from 'antd';
import { Button } from '@mui/material';

import { moneyFormatter } from '@utils';
import { OrderCompositionProduct, Product } from '@types';
import { productInvoiceHttp } from '@network/product-invoice-http';
import { ProductCreator } from '@components/ProductCreator';
import cls from './order-products.module.css';

interface OrderProductProps {
  index: number;
  product: OrderCompositionProduct | null;
  productsSuggests: Product[];
  removeProduct: (arg: number) => void;
  onProductSelect: (index: number, productId: number | string, option: any) => void;
  onQtyChange: (index: number, qty: number) => void;
  onProductPriceChange: (index: number, productPrice: any) => void;
}

const OrderProduct = ({
  product,
  index,
  productsSuggests,
  removeProduct,
  onProductSelect,
  onQtyChange,
  onProductPriceChange,
}: OrderProductProps) => {
  const options = productsSuggests.map(p => ({
    value: p.id.toString(),
    label: p.pack ? `${p.name} - ${p.pack}` : p.name,
  }));

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const productName = product?.product?.pack ? `${product.product?.name} - ${product.product?.pack}` : product?.product?.name;

  return (
    <div className={cls.product}>
      <div className={cls.productContent}>
        <div className={cls.productHead}>
          <div className={cls.name}>Product</div>
          <div className={cls.quantity}>Quantity (1 or 1.225)</div>
          <div className={cls.price}>Requested Price</div>
          <div className={cls.remove}>Remove</div>
        </div>
        <div className={cls.productInfo}>
          <div className={cls.number}>{index + 1}</div>
          <div className={cls.name}>
            <Select
              onChange={(productId, option) => onProductSelect(index, productId, option)}
              value={productName}
              className={cls.input}
              showSearch
              placeholder="Select product"
              options={[
                { value: 'Add product', label: 'Add product' },
                ...options.sort((a, b) => a.label.localeCompare(b.label)),
              ]}
              filterOption={filterOption}
              disabled={!product}
            />
          </div>
          <div className={cls.quantity}>
            <InputNumber
              disabled={!product?.productId}
              className={cls.input}
              value={product?.qty}
              onChange={qty => onQtyChange(index, qty || 1)}
              defaultValue={1}
              step={1}
              maxLength={5}
            />
          </div>
          <div className={cls.price}>
            <InputNumber
              disabled={!product?.productId}
              className={cls.input}
              value={product?.productPrice || 0}
              onChange={price => onProductPriceChange(index, price)}
              defaultValue={0}
              min={0}
              step={100}
              maxLength={7}
            />
          </div>
          <div className={cls.remove} onClick={() => removeProduct(index)}>
            <div className={cls.removeIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z" fill="#FF4D4F"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      {product?.product?.image ? (
        <div className={cls.productImage}>
          <img src={product?.product.image} alt="" />
        </div>
      ) : (
        <div className={cls.productImageEmpty}>
          no image
        </div>
      )}
    </div>
  );
};

interface OrderProductsProps {
  composition: OrderCompositionProduct[];
  setComposition: Dispatch<SetStateAction<OrderCompositionProduct[]>>;
  setInvoiceTotal: Dispatch<SetStateAction<number>>;
}

export const OrderProducts = ({
  composition, setComposition, setInvoiceTotal,
}: OrderProductsProps) => {
  const notify = useNotify();

  const [isProductCreatorActive, setIsProductCreatorActive] = useState<boolean>(false);
  const [productsSuggests, setProductsSuggests] = useState<Product[]>([]);

  const selectedProductsIds = useRef<number[]>([]);

  const addPosition = (position: OrderCompositionProduct) => {
    const addedPosition: OrderCompositionProduct = {
      externalProduct: position.externalProduct,
      externalShop: position.externalShop,
      id: position?.id || null,
      productId: position?.productId || null,
      qty: 1,
      productPrice: position.productPrice,
      isExternal: position.isExternal,
      product: position.product,
    };
    if (composition.find(c => c.id === null)) {
      notify('Fill the last product info before you add the new one.', { type: 'error' });
      return;
    }
    setComposition([...composition, addedPosition]);
  };

  const removePosition = (index: number) => {
    const nextComposition = composition.filter((p, i) => i !== index);
    setComposition(nextComposition);
  };

  const onProductSelect = (
    index: number,
    productId: number | string,
    option: { value: string; label: string },
  ) => {
    if (productId === 'Add product') {
      removePosition(index);
      setIsProductCreatorActive(true);
      return;
    }

    if (composition.find(position => position.productId === productId)) {
      return removePosition(index);
    }
    const nextComposition = composition.slice();
    nextComposition[index].id = index;
    nextComposition[index].productId = +productId;
    nextComposition[index].externalProduct = option?.label || '';
    nextComposition[index].productPrice =
      productsSuggests.find((product) => product.id === +productId)?.price || 0;
    nextComposition[index].product = productsSuggests.find((product) => product.id === +productId);

    if (selectedProductsIds.current.includes(+productId)) {
      removePosition(index);
    } else {
      setComposition(nextComposition);
      selectedProductsIds.current = nextComposition.map(c => c.productId as number);
    }
  };

  const onQtyChange = (index: number, qty: any) => {
    const nextComposition = composition.slice();
    nextComposition[index].qty = qty;
    setComposition(nextComposition);
  };

  const onProductPriceChange = (index: number, productPrice: any) => {
    const nextComposition = composition.slice();
    nextComposition[index].productPrice = productPrice;
    setComposition(nextComposition);
  };

  const fetchProducts = async () => {
    try {
      const loadedProducts = await productInvoiceHttp.getProducts();
      setProductsSuggests(loadedProducts);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    void fetchProducts();
  }, []);

  const total = composition.reduce((acc, item) => acc + ((item.productPrice || 0) * item.qty), 0);

  useEffect(() => {
    setInvoiceTotal(total);
  }, [total]);

  useEffect(() => {
    selectedProductsIds.current = composition.map(c => c.productId as number);
  }, [composition]);

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (isProductCreatorActive) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isProductCreatorActive]);

  const filteredProductsSuggests = productsSuggests
    .filter(productsSuggest => !selectedProductsIds.current.includes(productsSuggest.id));

  return (
    <div className={cls._}>
      <div className={cls.list}>
        {composition.map((product, index) => (
          <OrderProduct
            key={product.id}
            product={product}
            index={index}
            productsSuggests={filteredProductsSuggests}
            removeProduct={removePosition}
            onProductSelect={onProductSelect}
            onQtyChange={onQtyChange}
            onProductPriceChange={onProductPriceChange}
          />
        ))}
        {isProductCreatorActive && (
          <OrderProduct
            product={null}
            index={composition.length}
            productsSuggests={productsSuggests}
            removeProduct={removePosition}
            onProductSelect={onProductSelect}
            onQtyChange={onQtyChange}
            onProductPriceChange={onProductPriceChange}
          />
        )}
      </div>
      <div className={cls.add}>
        <Button variant="contained" className={cls.addButton} onClick={() => addPosition({
          id: null,
          productId: null,
          qty: 1,
          productPrice: null,
          isExternal: false,
        })}>
          Add product
        </Button>
      </div>
      <div className={cls.total}>
        <div className={cls.totalLabel}>Total</div>
        <div className={cls.totalValue}>{moneyFormatter.format(total)}</div>
      </div>

      {isProductCreatorActive && (
        <ProductCreator
          setIsActive={setIsProductCreatorActive}
          addPosition={addPosition}
          fetchProducts={fetchProducts}
        />
      )}
    </div>
  );
};
