import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { moneyFormatter } from '@utils';

export const RiskFiscal = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const { taxCrop } = risk;

  return (
    <table className={cls.table}>
      <thead>
        <tr>
          <th colSpan={4}>Fresh Campo</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Periodo</td>
          <td>Emitido</td>
          <td>Cancelado</td>
          <td>Vigente</td>
        </tr>
        {taxCrop.values.map(tax => (
          <tr key={tax.year}>
            <td>{tax.year}</td>
            <td>{moneyFormatter.format(tax.emitted || 0)}</td>
            <td>{moneyFormatter.format(tax.cancelled || 0)}</td>
            <td>{moneyFormatter.format(tax.current || 0)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const useStyles = makeStyles({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& th': {
      backgroundColor: '#042E6B',
      color: '#FFF',
      fontSize: 12,
      fontWeight: 500,
      borderLeft: '1px solid white',
      '&:first-child': {
        borderTopLeftRadius: 5,
        borderLeft: '1px solid #042E6B',
      },
      '&:last-child': {
        borderTopRightRadius: 5,
      },
    },
    '& td': {
      borderTop: '1px solid #042E6B',
      borderLeft: '1px solid #042E6B',
      fontSize: 12,
      fontWeight: 400,
      color: '#042E6B',
      width: '12.5%',
      padding: '3px 8px',
      backgroundColor: '#F0F3FB',
      '&:last-child': {
        borderRight: '1px solid #042E6B',
      },
      '&[colspan]': {
        textAlign: 'center',
      },
    },
    '& tr:nth-child(1) td': {
      backgroundColor: 'transparent',
      color: '#042E6B',
    },
    '& tr:last-child td': {
      borderBottom: '1px solid #042E6B',
      '&:first-child': {
        borderBottomLeftRadius: 5,
      },
      '&:last-child': {
        borderBottomRightRadius: 5,
      },
    },
  },
});
