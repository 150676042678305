import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { Modal } from 'antd';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useNotify } from 'react-admin';
import { clsx } from 'clsx';

import { CreditRisk } from '@types';
import { creditHttp } from '@network';
import { axiosErrorToString, moneyFormatter } from '@utils';
import { BlueButton } from '@components';

export interface Props {
  creditId: number;
  isOpened: boolean;
  setOpened: (nextOpened: boolean) => void;
}

export const ExportCreditTerms = ({ creditId, isOpened, setOpened }: Props) => {
  const [risk, setRisk] = useState<CreditRisk>();
  const [isExporting, setExporting] = useState(false);
  const cls = useStyles();
  const notify = useNotify();

  const hideModal = () => setOpened(false);
  const pdfFilename = `credit_terms_${risk?.credit?.code}.pdf`;

  useEffect(() => {
    creditId && isOpened && (async () => {
      try {
        const nextRisk = await creditHttp.getRisk(creditId);
        setRisk(nextRisk);
      } catch (e) {
        notify(axiosErrorToString(e));
        setOpened(false);
      }
    })();
  }, [creditId, isOpened]);

  const downloadPdfDocument = async () => {
    setExporting(true);
    await new Promise((resolve) => setTimeout(resolve, 250));
    const div = document.getElementById('export-risk');

    try {
      div && html2canvas(div).then((canvas) => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }
        doc.save(pdfFilename);
      });
    } finally {
      setExporting(false);
    }
  };

  if (!risk) {
    return null;
  }

  return (
    <>
      <Modal
        open={isOpened}
        title={(
          <div className={cls.modalTitle}>
            <BlueButton
              onClick={downloadPdfDocument}
              className={cls.downloadButton}
            ><DownloadFileIcon /> Download PDF</BlueButton>
            <div>File name: {pdfFilename}</div>
          </div>
        )}
        footer={null}
        onCancel={hideModal}
        width={800}
        style={{ top: 20 }}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
        closeIcon={<Close style={{ marginTop: 14 }} />}
      >
        {isOpened && !risk && (
          <div className={cls.box}>
            <h3>Credit terms</h3>
          </div>
        )}
        {isOpened && risk && (
          <div id="export-risk" className={clsx(cls.box, isExporting)}>
            <div>
              <div className={cls.title}>Credit terms</div>

              <div className={cls.box}>
                <div className={cls.form}>
                  {risk.replyTerms
                    .filter((item) => item.isFixed)
                    .map((term, index) => (
                      <div className={cls.row} key={index}>
                        <div className={cls.rowLabel}>{term.titleMx}</div>
                        <div className={cls.rowValue}>{term.value}</div>
                      </div>
                    ))}
                  {risk.replyTerms
                    .filter((item) => !item.isFixed)
                    .map((term, index) => (
                      <div className={cls.row} key={index}>
                        <div className={cls.rowLabel}>{term.titleMx}</div>
                        <div className={cls.rowValue}>
                          {term.type === 'money' && term.value !== null ? moneyFormatter.format(term.value ? +term.value : 0) : null}
                          {term.type === 'percentage' && term.value !== null ? `${term.value}%` : null}
                          {term.type === 'single_choice' || term.type === 'long_answer' ? term.value : null}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>)}
      </Modal>
    </>);
};

const useStyles = makeStyles({
  modalTitle: {
    display: 'flex', '& > div': {
      padding: '10px 30px', fontSize: 14,
    },
  },
  box: {
    marginTop: -20,
    padding: '20px 20px',
    fontFamily: 'Outfit, sans-serif',
    color: '#000',
    background: 'url("/bg/export-risk-bg.png") repeat-y top left',
  },
  title: {
    margin: '25px 0 5px', textAlign: 'center', fontSize: 16, fontWeight: 600, color: '#000',
  },
  titleLeft: {
    textAlign: 'left',
  },
  downloadButton: {
    width: 'auto !important', display: 'flex', alignItems: 'center',
  },
  downloadIcon: {
    fontSize: 22, color: 'white', marginRight: 10,
  },
  form: {
    borderRadius: 10,
    border: '1px solid #042E6B',
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #042E6B',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  rowLabel: {
    width: '40%',
    height: 34,
    padding: '9px 24px',
    color: '#042E6B',
    fontSize: 12,
  },
  rowValue: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    lineHeight: 1.1,
    width: '60%',
    height: 34,
    padding: '9px 28px',
    borderLeft: '1px solid #042E6B',
    backgroundColor: '#F0F3FB',
    position: 'relative',
  },
});

const DownloadFileIcon = () => {
  const cls = useStyles();

  return (<svg className={cls.downloadIcon}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none">
    <g clipPath="url(#clip0_3168_6606)">
      <path
        d="M13.9198 3.25872H17.3957C17.3521 3.18539 17.2941 3.11914 17.2251 3.06289L13.6635 0.192057C13.5671 0.113724 13.4507 0.0566406 13.3237 0.0253906L13.3851 2.79872C13.3903 3.05289 13.6275 3.25706 13.9198 3.25872Z"
        fill="#042E6B" />
      <path
        d="M13.9198 3.9475C13.1972 3.94375 12.6093 3.44083 12.5925 2.81292L12.5302 0H1.78394C0.797334 0.0025 -0.000957471 0.699167 8.61942e-07 1.55708V18.4429C-0.000957471 19.3008 0.797334 19.9975 1.78394 20H15.7056C16.6923 19.9975 17.4905 19.3008 17.4896 18.4429V3.9475H13.9198Z"
        fill="#042E6B" />
      <path
        d="M11.9434 12.1964L9.84413 14.0164C9.60743 14.2214 9.29357 14.3443 8.96007 14.3627C8.93084 14.366 8.90161 14.3677 8.87238 14.3681C8.84699 14.3693 8.82159 14.3693 8.7962 14.3681C8.46222 14.3552 8.14549 14.2368 7.90447 14.0356L5.77026 12.3764C5.47078 12.1448 5.44395 11.746 5.71036 11.4856C5.97678 11.2252 6.43534 11.2018 6.73482 11.4335L8.15459 12.5314V7.29393C8.1407 6.9456 8.45407 6.6531 8.85513 6.64101C9.25572 6.62893 9.59209 6.90143 9.60599 7.25018C9.60647 7.26476 9.60647 7.27935 9.60599 7.29393V12.4443L10.9213 11.3006C11.2059 11.0552 11.6654 11.0564 11.9477 11.3039C12.2299 11.5514 12.228 11.951 11.9438 12.1964H11.9434Z"
        fill="#E1E5ED" />
    </g>
    <defs>
      <clipPath id="clip0_3168_6606">
        <rect width="17.4896" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>);
};
