import { makeStyles } from '@mui/styles';

export const ErrorMessage = ({ text }: {text: string}) => {
  const cls = useStyles();

  return (
    <div className={cls.error}>
      {text}
    </div>
  );
};

const useStyles = makeStyles({
  error: {
    width: '100%',
    color: 'red',
    fontSize: 13,
    lineHeight: 1,
    padding: '2px 4px 0',
  },
});
