import { CreateProps, Create, SimpleForm } from 'react-admin';

import {
  Row,
  Col,
  Container,
  TextInput,
  PasswordInput,
  BooleanInput,
  PhoneInput,
  ManyInput,
  Forbidden, ManyCheckboxInput,
} from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

export const AdminCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps}>
      <SimpleForm>
        <Container max>
          <Row title="Identity">
            <Col><TextInput source="username" required autoFocus /></Col>
            <Col><TextInput source="name" required /></Col>
            <Col><PhoneInput source="phone" /></Col>
            <Col><TextInput source="email" type="email" /></Col>
          </Row>
          <Row>
            <Col><BooleanInput source="isEnabled" defaultValue={true} /></Col>
            <Col><BooleanInput source="isSuperadmin" defaultValue={false} /></Col>
            <Col flex={2}>
              <ManyCheckboxInput source="roleIds" reference="role" label="Roles" />
            </Col>
          </Row>
          <Row title="Set Password">
            <Col><PasswordInput source="password" required /></Col>
            <Col><PasswordInput source="confirmPassword" required /></Col>
            <Col />
            <Col />
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
