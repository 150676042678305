import { ListProps, TextField, NumberField } from 'react-admin';

import {
  Grid,
  GridList,
  MoneyField,
  OptionsField,
  FileField,
  Forbidden,
  SelectArrayInput,
  TextInput,
  DateField,
} from '@components';
import { Delivery } from '@types';
import { useRbacList } from '@hooks';
import { DeliveryPositions } from '../DeliveryPositions';
import { DeliveryProviderField } from '../DeliveryProviderField';
import { DeliveryArrayTextField } from '../DeliveryArrayTextField';
import { DeliveryStatusField, deliveryStatusOptions } from '../DeliveryStatusField';
import cls from './DeliveryList.module.css';

export const DeliveryList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  const deliveryRowStyle = (record: Delivery) => {
    return {
      pointerEvents: record.isSummary ? 'none' : 'all',
    };
  };

  const deliveryRowClick = (record: any): string => {
    return !record?.isSummary ? 'edit' : 'show';
  };

  const isData = (record: Delivery) => !record.isSummary;

  return (
    <GridList {...props} filters={Filters}>
      <Grid
        rowClick={deliveryRowClick}
        rowStyle={deliveryRowStyle}
        defaultColumns={'id airtableOrder status distributor exchangeRate positionsCost shippingCost extraCost totalCost farmerTotalCost marginGross marginPercent createdAt positionsCodes positionsNames'.split(' ')}
        expand={<ExpandPanel />}
        expandSingle
        isRowSelectable={isData}
        isRowExpandable={isData}
        className={cls.grid}
      >
        <TextField source="id" label="ID" />
        <TextField source="airtableOrder" label="AirtableOrder" />
        <DeliveryStatusField source="status" />
        <DeliveryProviderField source="distributor" label="Provider" />
        <MoneyField source="exchangeRate" label="Exchange rate" />
        <MoneyField source="positionsCost" label="Cost provider" />
        <MoneyField source="shippingCost" label="Shipping cost" />
        <MoneyField source="extraCost" label="Extra cost" />
        <MoneyField source="totalCost" label="Total cost" />
        <MoneyField source="farmerTotalCost" label="Total sale" />
        <MoneyField source="marginGross" label="Margin gross" />
        <NumberField source="marginPercent" label="Margin percent" />
        <DateField source="createdAt" label="Created" />
        <DeliveryArrayTextField source="positionsCodes" key="posCodes" label="Customers codes" />
        <DeliveryArrayTextField source="positionsNames" key="posNames" label="Customers names" />
        <FileField source="invoiceFile" prefix="invoice - " label="Invoice" />
        <FileField source="receiptFile" prefix="receipt - " label="Payment receipt" />
      </Grid>
    </GridList>
  );
};

const ExpandPanel = ({ record }: {record?: Delivery}) => {
  return !record?.isSummary ? <DeliveryPositions delivery={record as Delivery} /> : null;
};

const Filters = [
  <TextInput key="q" source="q" label="Search" alwaysOn style={{ width: 270 }} />,
  <SelectArrayInput
    key="status"
    source="status"
    choices={deliveryStatusOptions}
    alwaysOn
    className="MS-field"
    style={{ width: 280 }}
  />,
  <OptionsField source="distributor" key="distributor" label="Provider" alwaysOn />,
];
