import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Modal } from 'antd';
import { SaveButton, useNotify, useRefresh, useUpdate } from 'react-admin';

import { useRequest } from '@hooks';
import { showAxiosError } from '@utils';
import { useController } from 'react-hook-form';

export const SaveOrderButton = (props: any) => {
  const { order, pristine } = props;
  const cls = useStyles();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const {
    field: { value: composition },
  } = useController({ name: 'composition' });
  const {
    field: { value: address },
  } = useController({ name: 'address' });
  const [update, { error }] = useUpdate('order', {
    id: order?.id,
    data: { ...order, composition, address },
  });

  const { loading, submit } = useRequest(async () => {
    await update();
    setIsModalVisible(false);
    refresh();
    notify('Updated');
  });

  useEffect(() => {
    error && showAxiosError(error as any);
  }, [error]);

  return (
    <>
      <SaveButton
        {...props}
        redirect={false}
        disabled={pristine}
        onClick={(e: any) => {
          e.preventDefault();
          setIsModalVisible(true);
        }}
      />

      <Modal
        title="Order save"
        open={isModalVisible}
        onOk={submit}
        onCancel={() => setIsModalVisible(false)}
        confirmLoading={loading}
        footer={(
          <>
            <Button
              className={cls.cancelBtn}
              color="warning"
              type="button"
              variant="outlined"
              onClick={() => setIsModalVisible(false)}
            >Cancel</Button>
            <SaveButton
              {...props}
              redirect={false}
              disabled={pristine}
              onClick={submit}
            />
          </>
        )}
      >
        <p>Do you really want to save all made changes?</p>
      </Modal>
    </>
  );
};

const useStyles = makeStyles({
  cancelBtn: {
    marginRight: 6,
  },
  icon: {
    marginRight: 6,
  },
});
