import { clsx } from 'clsx';
import { Col, Form, Row } from 'antd';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Edit, EditProps, useGetOne, useNotify, useRedirect } from 'react-admin';

import { leadHttp } from '@network';
import { Lead, LeadFormProps } from '@types';
import { LeadForm } from '@pages/lead/LeadForm';
import { useEditProps, useRbacEdit } from '@hooks';
import { axiosErrorToString, phoneFormatter } from '@utils';
import { Container, Forbidden, PageHeader } from '@components';
import cls from './LeadEdit.module.css';

export const LeadEdit = (props: EditProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const rbacEdit = useRbacEdit();
  const editProps = useEditProps();
  const [form] = Form.useForm<LeadFormProps>();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<LeadFormProps | null>(null);

  const { id } = useParams();
  const { data: record } = useGetOne('lead', { id });

  const Title = ({ record }: {record?: Lead}) =>
    record ? (<span>Lead: {record.name}</span>) : null;

  const addFormData = (data: Lead) => {
    setFormValues({
      name: data.name,
      phone: phoneFormatter.format(data.phone),
      email: data.email,
      stage: data.stage || '',
      stateId: data.state?.id || null,
      cropIds: data.crops?.map((c) => c.id) || [],
      activationId: data.activationId,
      responsibleAdminId: data.responsibleAdmin?.id || null,
    });
  };

  const submit = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const params = form.getFieldsValue();
      const newLead = await leadHttp.update(params, record.id);
      addFormData(newLead);
      notify('Lead has been updated successfully.');
    } catch (e) {
      // @ts-ignore
      if (e.errorFields?.length) {
        notify('Form is wrong', { type: 'error' });
      } else {
        notify(axiosErrorToString(e), { type: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      const result = await leadHttp.remove(record.id);

      if (result) {
        notify('Lead has been deleted');
        redirect('/lead');
      }
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  useEffect(() => {
    if (record) {
      addFormData(record);
    }
  }, [record]);

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}
      className={clsx(cls.page, 'details-page-wrapper')}>
      <>
        <PageHeader
          title="Individual lead"
          linkText="Back to all records"
          linkUrl="/lead"
        />
        <Container max className={cls.container}>
          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24} className={cls.wrapperCol}>
              <div className={cls.block}>
                <div className={cls.blockHeader}>Lead information</div>
                {!formValues ? null : (
                  <Form
                    form={form}
                    layout="horizontal"
                    onFinish={submit}
                    initialValues={formValues}
                  >
                    <LeadForm
                      form={form}
                      isEdit
                      onDelete={onDelete}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    </Edit>
  );
};
