import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Upload, UploadFile } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { FileWithoutPreview, InitialCard } from '@components';
import { FileImage, FilePdf } from '@components';
import { beforeUploadFlex, FileTypesOptions } from '@utils';
import { CreditReportFile } from '@types';
import { creditHttp, CreditReportTypes, s3http } from '@network';
import { CreditReportsType, NoIcon, TickWithLabel, YesIcon } from './credit-report';
import cls from './credit-report.module.css';

const MAX_REPORT_FILES = 10;
const { Dragger } = Upload;

interface CreditReportFileProps {
  creditId: number;
  title: string;
  files: CreditReportFile[];
  type: CreditReportTypes;
  setReportFiles: Dispatch<SetStateAction<CreditReportsType>>;
}

interface CreditReportFilesProps {
  creditId: number;
  files: Record<CreditReportTypes, CreditReportFile[]>;
  setReportFiles: Dispatch<SetStateAction<CreditReportsType>>;
}

interface CreditReportVerificationProps {
  files: CreditReportsType;
}

const UploadReportFile = ({ onUpload }: any) => {
  const options: FileTypesOptions = {
    types: [
      'application/pdf',
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/vnd.google-earth.kml+xml',
      '', // fix for kml-files on MacOS (unrecognized kml mimetype)
    ],
    typeError: 'You can only upload JPG/PNG/PDF/KML file!',
  };

  return (
    <Dragger
      maxCount={10}
      multiple
      listType="picture-card"
      showUploadList={false}
      beforeUpload={(file) => beforeUploadFlex(file, options)}
      customRequest={async ({ onSuccess }) => setTimeout(() => {
        onSuccess && onSuccess('ok');
      }, 0)}
      onChange={({ fileList }) => onUpload(fileList)}
      onPreview={() => {}}
      className={cls.creditReportUpload}
    >
      <div className={cls.uploadText}>+</div>
    </Dragger>
  );
};

const CreditReportDocument = ({
  creditId, title, files, type, setReportFiles,
}: CreditReportFileProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const usedFiles = useRef<Record<string, boolean>>({});

  const onUpload = async (uploadFiles: UploadFile[]) => {
    // check unique
    const files: UploadFile[] = [];
    uploadFiles.forEach(file => {
      const uid = file.uid;
      if (!usedFiles.current[uid]) {
        usedFiles.current[uid] = true;
        files.push(file);
      }
    });
    if (files.length === 0) {
      return;
    }

    // upload files
    try {
      const s3files = await Promise.all(
        files.map(file => s3http.uploadFile(file.originFileObj as File)),
      );
      for (let i = 0; i < s3files.length; i++) {
        const fileId = s3files[i].fileId;
        const newReport = await creditHttp.saveReportFile({ creditId, fileId, type });
        setReportFiles(reportFiles => ({
          ...reportFiles,
          [type]: [...reportFiles[type], newReport],
        }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onRemove = async (fileId: number) => {
    try {
      await creditHttp.removeReportFile({
        creditId,
        fileId,
      });
    } catch (e) {

    }
    setReportFiles(reportFiles => ({
      ...reportFiles,
      [type]: reportFiles[type].filter(i => i.id !== fileId),
    }));
  };

  const isValid = !!files?.length;

  return (
    <div className={cls.creditReport}>
      <div className={cls.creditReportHead}>
        <div className={cls.creditReportTitle}>{title}</div>
        <div className={cls.creditReportStatus}>
          <div className={cls.creditReportStatusLabel}>{isValid ? 'Docs Sent' : 'Docs Not Sent'}</div>
          {isValid ? <YesIcon /> : <NoIcon />}
        </div>
      </div>
      <div className={cls.creditReportFiles}>
        {loading && (
          <div className={cls.creditReportFile}>
            <LoadingOutlined />
          </div>
        )}

        {files?.map(file => (
          <div key={file.id} className={cls.creditReportFile}>
            <div className={cls.creditReportFileContent}>
              {file.file.isPdf ? (
                <FilePdf url={file.file.adminUrl} downloadFilename={file.file.originalName} />
              ) : (
                file.file.extension !== 'kml'
                  ? <FileImage url={file.file.adminUrl} downloadFilename={file.file.originalName} />
                  : <FileWithoutPreview
                    url={file.file.adminUrl}
                    downloadFilename={file.file.originalName}
                  />
              )}
              <div className={cls.creditReportFileClose} onClick={() => onRemove(file.id)}>
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none">
                  <path
                    d="M7 0C3.13461 0 0 3.13461 0 7C0 10.8654 3.13461 14 7 14C10.8654 14 14 10.8654 14 7C13.9938 3.13461 10.8623 0.00310973 7 0Z"
                    fill="#002A77" />
                  <path
                    d="M9.63374 4.36244C9.51246 4.24116 9.31655 4.24116 9.19527 4.36244L6.9998 6.55791L4.80433 4.36244C4.68305 4.24116 4.48714 4.24116 4.36586 4.36244C4.24458 4.48372 4.24458 4.67964 4.36586 4.80092L6.56133 6.99638L4.36586 9.19185C4.24458 9.31313 4.24458 9.50905 4.36586 9.63032C4.48714 9.7516 4.68305 9.7516 4.80433 9.63032L6.9998 7.43486L9.19527 9.63032C9.31655 9.7516 9.51246 9.7516 9.63374 9.63032C9.75502 9.50905 9.75502 9.31313 9.63374 9.19185L7.43827 6.99638L9.63374 4.80092C9.75502 4.67964 9.75502 4.48372 9.63374 4.36244Z"
                    fill="white" />
                </svg>
              </div>
            </div>

            <div className={cls.creditReportFileStatus}>Uploaded</div>
          </div>
        ))}

        {files?.length < MAX_REPORT_FILES && !loading && (
          <UploadReportFile onUpload={onUpload} />
        )}
      </div>
    </div>
  );
};

const CreditReportVerification = ({ files }: CreditReportVerificationProps) => {
  return (
    <div className={cls.creditReportVerification}>
      <div className={cls.creditReportVerificationTitle}>Reports Uploaded</div>
      <div className={cls.creditReportVerificationList}>
        <TickWithLabel label="SAT Report" isValid={!!files.EKATENA_REPORT.length} />
        <TickWithLabel label="Bureau Report" isValid={!!files.BUREAU_REPORT.length} />
        <TickWithLabel label="Field Report" isValid={!!files.SINECTA_REPORT.length} />
      </div>
    </div>
  );
};

export const CreditReportFiles = ({ creditId, files, setReportFiles }: CreditReportFilesProps) => {
  useEffect(() => {
    (async () => {
      try {
        const loadedFiles = await creditHttp.getReportFiles(+creditId);

        if (loadedFiles) {
          setReportFiles(loadedFiles);
        }

      } catch (e) {

      }
    })();
  }, []);

  return (
    <InitialCard
      contentClass={cls.creditReports}
      extraPadding
    >
      <CreditReportDocument
        creditId={creditId}
        title="Upload SAT report"
        files={files.EKATENA_REPORT}
        type={CreditReportTypes.EKATENA_REPORT}
        setReportFiles={setReportFiles}
      />
      <CreditReportDocument
        creditId={creditId}
        title="Upload Bureau report"
        files={files.BUREAU_REPORT}
        type={CreditReportTypes.BUREAU_REPORT}
        setReportFiles={setReportFiles}
      />
      <CreditReportDocument
        creditId={creditId}
        title="Upload Field report"
        files={files.SINECTA_REPORT}
        type={CreditReportTypes.SINECTA_REPORT}
        setReportFiles={setReportFiles}
      />

      <CreditReportVerification files={files} />
    </InitialCard>
  );
};
