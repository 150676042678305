import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { moneyFormatter } from '@utils';

export const RiskSupplierAmounts = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const { incomeSuppliers } = risk;
  const totalRows = (incomeSuppliers.values || []).length;

  return (
    <table className={cls.table}>
      <thead>
        <tr>
          <th colSpan={4}>{risk.supplier || 'Proveedor'}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Periodo</td>
          <td>Emitido</td>
          <td>DPO Promedio</td>
        </tr>
        {incomeSuppliers.values.map((suply, idx) => (
          <tr key={suply.period}>
            <td>{suply.period}</td>
            <td className={cls.noborder}>{moneyFormatter.format(suply.emitted || 0)}</td>
            {idx === 0 && (
              <td rowSpan={totalRows}>{moneyFormatter.format(incomeSuppliers.avgDpo || 0)}</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const useStyles = makeStyles({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& th': {
      backgroundColor: '#042E6B',
      color: '#FFF',
      fontSize: 12,
      fontWeight: 500,
      borderLeft: '1px solid white',
      '&:first-child': {
        borderTopLeftRadius: 5,
        borderLeft: '1px solid #042E6B',
      },
      '&:last-child': {
        borderTopRightRadius: 5,
      },
    },
    '& td': {
      borderTop: '1px solid #042E6B',
      borderLeft: '1px solid #042E6B',
      fontSize: 12,
      fontWeight: 400,
      color: '#042E6B',
      width: '12.5%',
      padding: '3px 8px',
      backgroundColor: '#F0F3FB',
      '&:last-child': {
        borderRight: '1px solid #042E6B',
      },
      '&[rowspan]': {
        textAlign: 'center',
      },
    },
    '& tr:nth-child(1) td': {
      backgroundColor: 'transparent',
      color: '#042E6B',
    },
    '& tr:last-child td, & td[rowspan]': {
      borderBottom: '1px solid #042E6B',
      '&:first-child': {
        borderBottomLeftRadius: 5,
      },
      '&:last-child': {
        borderBottomRightRadius: 5,
      },
    },
  },
  noborder: {
    borderRight: 'none !important',
    borderRadius: '0px !important',
  },
});
