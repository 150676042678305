import React, { useEffect, useState } from 'react';
import { Link } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, Input, InputNumber, Select } from 'antd';
import { useController } from 'react-hook-form';

import { productHttp } from '@network';
import { OrderComposition, Product } from '@types';

interface Props {
  source?: string;
  isPaid: boolean;
  editable: boolean;
}

export const OrderCompositionInput = ({ source = 'composition', isPaid, editable }: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name: source });
  const cls = useStyles();

  const [composition, setComposition] = useState<OrderComposition>(value || []);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    (async () => {
      const nextProducts = await productHttp.getListWithoutLimit();
      if (Array.isArray(nextProducts)) {
        setProducts(nextProducts);
      }
    })();
  }, []);

  useEffect(() => void onChange(composition), [composition]);

  const options = products.map(model => ({
    key: `${model.id}`,
    label: model.nameInfo,
  }));

  const addPosition = () => {
    const addedPosition = {
      id: null,
      deliveryId: null,
      productId: null,
      qty: 1,
      productPrice: null,
      isExternal: false,
      externalProduct: '',
      externalShop: '',
    };
    setComposition([...composition, addedPosition]);
  };
  const removePosition = (index: number) => {
    const nextComposition = composition.filter((p, i) => i !== index);
    setComposition(nextComposition);
  };
  const onProductSelect = (index: number, productId: number) => {
    if (composition.find(position => position.productId === productId)) {
      return removePosition(index);
    }
    const nextComposition = composition.slice();
    nextComposition[index].productId = productId;
    setComposition(nextComposition);
  };
  const onQtyChange = (index: number, qty: any) => {
    const nextComposition = composition.slice();
    nextComposition[index].qty = qty;
    setComposition(nextComposition);
  };
  const onProductPriceChange = (index: number, productPrice: any) => {
    const nextComposition = composition.slice();
    nextComposition[index].productPrice = productPrice;
    setComposition(nextComposition);
  };
  const onExternalSwap = (index: number, isChecked: boolean) => {
    const nextComposition = composition.slice();
    nextComposition[index].isExternal = isChecked;
    setComposition(nextComposition);
  };
  const onExternalName = (index: number, externalName: string) => {
    const nextComposition = composition.slice();
    nextComposition[index].externalProduct = externalName;
    setComposition(nextComposition);
  };
  const onExternalShop = (index: number, externalShop: string) => {
    const nextComposition = composition.slice();
    nextComposition[index].externalShop = externalShop;
    setComposition(nextComposition);
  };

  return (
    <div>
      {(composition || []).map((position, index) => {
        const product = products.find(p => p.id === position.productId);

        return (
          <div className={cls.position} key={index}>
            {editable ? (
              <div className={cls.colMiddle}>
                <Button
                  disabled={isPaid}
                  onClick={addPosition} variant="outlined" size="small"
                ><AddIcon /> Add</Button>
              </div>
            ) : null}
            <div className={cls.col}>
              <InputLabel shrink>Is External</InputLabel>
              <Checkbox
                defaultChecked={position.isExternal}
                onChange={e => onExternalSwap(index, e.target.checked)}
                disabled={isPaid}
              />
            </div>
            {position.isExternal ? (
              <div className={cls.col}>
                <InputLabel shrink>External Product</InputLabel>
                <Input
                  className={cls.externalInput}
                  value={position.externalProduct}
                  onChange={e => onExternalName(index, e.target.value)}
                  disabled={isPaid}
                />
              </div>
            ) : null}
            {position.isExternal ? (
              <div className={cls.col}>
                <InputLabel shrink>External Shop</InputLabel>
                <Input
                  className={cls.externalInput}
                  value={position.externalShop}
                  onChange={e => onExternalShop(index, e.target.value)}
                  disabled={isPaid}
                />
              </div>
            ) : null}
            {!position.isExternal ? (
              <div className={cls.col}>
                <InputLabel shrink>Product</InputLabel>
                <Select
                  className={cls.selectProduct}
                  disabled={isPaid}
                  value={position?.productId?.toString()}
                  showSearch
                  placeholder="Select a product"
                  optionFilterProp="children"
                  onChange={productId => onProductSelect(index, +productId)}
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={options.map((option) => ({
                    value: option.key,
                    label: option.label,
                  }))}
                />
              </div>
            ) : null}
            <div className={cls.col}>
              <InputLabel shrink>Quantity (1 or 1.225)</InputLabel>
              <InputNumber
                disabled={isPaid}
                value={position.qty}
                onChange={qty => onQtyChange(index, qty)}
                defaultValue={1}
              />
            </div>
            <div className={cls.col}>
              <InputLabel shrink>Requested Price</InputLabel>
              <InputNumber
                disabled={isPaid}
                value={position.productPrice || 0}
                onChange={price => onProductPriceChange(index, price)}
              />
            </div>
            {editable ? (
              <div className={cls.col}>
                <InputLabel shrink>Remove</InputLabel>
                <Button
                  disabled={isPaid}
                  onClick={() => removePosition(index)}
                >
                  <DeleteIcon />
                </Button>
              </div>
            ) : null}

            {product && (
              <Link
                to={{ pathname: `/product/${position.productId}` }}
                key={position.productId}
              >
                <div className={cls.info}>
                  <div className={cls.col}>
                    {product.image && (
                      <img src={product.image} alt={product.name} className={cls.pic} />
                    )}
                  </div>
                </div>
              </Link>
            )}

            {position.deliveryId ? (
              <div className={cls.col}>
                <InputLabel shrink>Shipping</InputLabel>
                <Link to={{ pathname: `/delivery/${position.deliveryId}` }}>
                  Shipping #{position.deliveryId}
                </Link>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles({
  externalInput: {
    width: '230px !important',
  },
  position: {
    display: 'flex',
    width: '100%',
    marginBottom: 10,
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  info: {
    display: 'flex',
    width: '100%',
  },
  col: {
    marginRight: 15,
  },
  colMiddle: {
    marginTop: 22,
    marginRight: 15,
  },
  pic: {
    maxWidth: 150,
    maxHeight: 100,
    marginTop: -10,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noimageIcon: {
    fontSize: 32,
    color: '#AAA',
  },
  selectProduct: {
    minWidth: 400,
  },
});
