import React from 'react';
import { ListProps, TextField, ReferenceField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  MoneyField,
  SelectInput,
  DateRangeInput,
  NullableBooleanInput,
  BooleanField,
  ReferenceInput,
  DateField,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { rbac } from '@utils/rbac';
import { CreditStatus, creditStatusMap } from '@types';
import { CreditStatusField } from '@pages/credit/CreditStatusField';
import { RiskStateField } from './RiskStateField';
import { RiskScoreField } from './RiskScoreField';

export const CreditRiskList = (props: ListProps) => {
  const rbacList = useRbacList();
  const exporter = useExporter({
    filename: 'Risk Manager list',
    props: {
      'credit.code': {
        label: 'Credit code',
      },
      'credit.status': {
        label: 'Status',
        prop: 'credit.statusEn',
      },
      'credit.accreditedName': {
        label: 'Accredited',
      },
      'state': {
        label: 'Underwriting',
        transform: 'uppercase',
      },
      'credit.user.stateId': {
        label: 'State',
        prop: 'credit.user.state.name',
      },
      'credit.requestAmount': {
        label: 'Requested Amount',
        transform: 'money',
      },
      'credit.creditCropNames': {
        label: 'Financed Crops',
      },
      'credit.creditCropHa': {
        label: 'Financed Ha',
      },
      'buyerNames': {
        label: 'Buyers',
      },
      'retailerNames': {
        label: 'Retailers',
      },
      'associateNames': {
        label: 'Associates',
      },
      'riskScore': {
        label: 'Score',
        transform: 'percent',
      },
      'isLegacy': {
        label: 'Is Legacy',
        transform: 'boolean',
      },
      'isFixedReplies': {
        label: 'Is Fixed',
        transform: 'boolean',
      },
      'responsibleAdminId': {
        label: 'Sent by',
        prop: 'responsibleAdmin.name',
      },
      'createdAt': {
        label: 'Created at',
        transform: 'datetime',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton={!rbac.isSuperadmin()}
      exporter={exporter}
    >
      <Grid
        rowClick="edit"
        defaultColumns={['credit.code', 'credit.accreditedName', 'state', 'credit.user.stateId',
          'credit.requestAmount', 'credit.creditCropNames', 'riskScore',
        ]}
      >
        <TextField source="credit.code" label="Credit code" />
        <CreditStatusField source="credit.status" label="Status" />
        <TextField source="credit.accreditedName" label="Accredited" sortable={false} />
        <RiskStateField source="state" label="Underwriting" />
        <ReferenceField reference="state" source="credit.user.stateId" label="State">
          <TextField source="name" />
        </ReferenceField>
        <MoneyField source="credit.requestAmount" label="Requested amount"
          style={{ display: 'inline-block', width: 100 }} />
        <TextField source="credit.creditCropNames" label="Financed Crops" sortable={false} />
        <TextField source="credit.creditCropHa" label="Financed Ha" sortable={false} />
        <TextField source="buyerNames" label="Buyers" sortable={false} />
        <TextField source="retailerNames" label="Retailers" sortable={false} />
        <TextField source="associateNames" label="Associates" sortable={false} />
        <RiskScoreField source="riskScore" label="Score" width={65} />
        <BooleanField source="isLegacy" label="Is Legacy" />
        <BooleanField source="isFixedReplies" label="Is Fixed" />
        <ReferenceField reference="admin" source="responsibleAdminId" label="Sent by">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Created at" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 220 }} />,
  <SelectInput
    label="Underwriting"
    key="state"
    source="state"
    choices={[
      { id: 'pending', name: 'Pending' },
      { id: 'complete', name: 'Complete' },
    ]}
    alwaysOn
  />,
  <SelectInput
    label="Credit status"
    key="status"
    source="status"
    choices={[
      { id: CreditStatus.MODERATION, name: creditStatusMap[CreditStatus.MODERATION] },
      { id: CreditStatus.COMPLETED_FILE, name: creditStatusMap[CreditStatus.COMPLETED_FILE] },
      { id: CreditStatus.BANK_REVISION, name: creditStatusMap[CreditStatus.BANK_REVISION] },
      { id: CreditStatus.APPROVED, name: creditStatusMap[CreditStatus.APPROVED] },
      { id: CreditStatus.DENIED, name: creditStatusMap[CreditStatus.DENIED] },
      { id: CreditStatus.SIGNED, name: creditStatusMap[CreditStatus.SIGNED] },
      { id: CreditStatus.REPAID, name: creditStatusMap[CreditStatus.REPAID] },
      { id: CreditStatus.DRAFT, name: creditStatusMap[CreditStatus.DRAFT] },
      { id: CreditStatus.RESTRUCTURE, name: creditStatusMap[CreditStatus.RESTRUCTURE] },
      { id: CreditStatus.DELAY, name: creditStatusMap[CreditStatus.DELAY] },
    ]}
  />,
  <DateRangeInput key="createdAt" source="createdAt" label="Created At"
    defaultValue={false} alwaysOn />,
  <NullableBooleanInput key="isLegacy" source="isLegacy" label="Is Legacy" />,
  <NullableBooleanInput key="isFixedReplies" source="isFixedReplies" label="Is Fixed" />,
  <ReferenceInput
    key="responsibleAdminId"
    source="responsibleAdminId"
    reference="admin"
    label="Sent by"
    perPage={10000}
    sort={{ field: 'name', order: 'ASC' }}
    emptyText="-"
  >
    <SelectInput optionText="name" label="Sent by" style={{ width: 100 }} />
  </ReferenceInput>,
];
