import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useController } from 'react-hook-form';

import { adminHttp } from '@network';

interface SelectOption {
  key: string,
  label: string,
}

interface Props {
  source: string;
  label?: string;
  hideLabel?: boolean;
  reference?: string;
  optionKey?: string;
  optionLabel?: string;
  required?: boolean;
}

export const OneInput = ({
  source, label, hideLabel, reference, optionKey, optionLabel, required,
}: Props) => {
  const {
    fieldState: { isTouched, error },
    field: { value, onChange },
  } = useController({ name: source });

  const initialOptions: SelectOption[] = value ? [{ key: value, label: '' }] : [];
  const defaultOptions: SelectOption[] = required ? [] : [{ key: '', label: '— empty —' }];

  // Options
  const [options, setOptions] = useState<SelectOption[]>(initialOptions);
  useEffect(() => {
    (async () => {
      const response = await adminHttp.get<any>(reference || source, {
        params: { _start: 0, _end: 9999999 },
      });
      const nextOptions: SelectOption[] = defaultOptions;
      if (response.data && Array.isArray(response.data)) {
        (response.data ?? []).forEach((model: any) => {
          nextOptions.push({
            key: model[optionKey ?? 'id'],
            label: model[optionLabel ?? 'nameMx'] || model['name'] || model['title'],
          });
        });
      }
      setOptions(nextOptions);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputLabel = label || source.slice(0, 1).toUpperCase().concat(source.slice(1));
  console.log({ required });
  return (
    <FormControl fullWidth required={required}>
      <TextField
        label={hideLabel ? undefined : inputLabel}
        onChange={onChange}
        value={value?.toString()}
        error={!!(isTouched && error)}
        select
        fullWidth
        variant="standard"
        size="small"
        required={required}
      >
        {options && options.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <FormHelperText> </FormHelperText>
    </FormControl>
  );
};
