import { Http } from './http';
import {
  CreditTransaction,
  Order,
  OrderTransaction,
  OrderTransactionPartner,
  OrderTransactionType,
} from '@types';

class OrdersHttp extends Http {
  prefix = 'order';

  async getCreditsWithHistory(userId: number): Promise<Order[]> {
    const res = await this.get<Order[]>(`/orders-with-history/${userId}`);

    return res.data;
  }

  async exportPositionsByUser(userId: number): Promise<ExportPositionsResponse> {
    const res = await this.get<ExportPositionsResponse>(`/export-positions/${userId}`);

    return res.data;
  }

  async getOrdersByUser(userId: number): Promise<Order[]> {
    const res = await this.get<Order[]>(`?userId=${userId}`);

    return res.data;
  }

  async addTransaction(orderId: number, payload: AddTransactionPayload) {
    const res = await this.post<OrderTransaction>(`/${orderId}/transactions`, payload);

    return res.data;
  }

  async payOperation(params: PayOperationParams): Promise<OrderTransaction> {
    const { orderId, partner, amount } = params;
    const url = `/${orderId}/pay-operation`;
    const res = await this.post<CreditTransaction>(url, { partner, amount });

    return res.data;
  }

  async cancelOrder(orderId: number): Promise<Order> {
    const url = `/${orderId}/cancel`;
    const res = await this.delete<Order>(url);

    return res.data;
  }

  async rollbackOrder(orderId: number): Promise<Order> {
    const url = `/${orderId}/rollback`;
    const res = await this.delete<Order>(url);

    return res.data;
  }

  async removeOrders(orderIds: number[]): Promise<void> {
    const url = '/remove';
    await this.post<RemoveOrdersByIdsParams>(url, { orderIds });

    return;
  }

  async createOrder(params: CreateOrderParams): Promise<void> {
    const url = '';
    await this.post(url, params);

    return;
  }

  async updateOrder(params: UpdateOrderParams): Promise<void> {
    const url = `/${params.orderId}`;
    await this.put(url, params);

    return;
  }
}

export const ordersHttp = new OrdersHttp();

interface PayOperationParams {
  orderId: number;
  partner: OrderTransactionPartner;
  amount: string;
}

interface AddTransactionPayload {
  amount: number | string;
  type: OrderTransactionType;
}

interface RemoveOrdersByIdsParams {
  orderIds: number[]
}

export interface ExportPositionsResponse {
  positions: {
    name: string;
    qty: number;
    cost: number;
    price: number;
  }[],
  totalCost: number;
}

export interface CreateOrderParams {
  composition: any[];
  userId: number;
  creditId: number;
}

export interface UpdateOrderParams {
  orderId: number;
  composition: any[];
  userId: number;
  creditId?: number;
  requestHours?: number;
}
