import { makeStyles } from '@mui/styles';
import { Input } from 'antd';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { Order } from '@types';
import { dateFormatter, moneyFormatter } from '@utils';
import { useState } from 'react';

interface Props {
  orders: Order[];
}

export const CreditOrdersWidget = ({ orders }: Props) => {
  const cls = useStyles();
  const [search, setSearch] = useState<string>('');
  const summary = orders.reduce((acc, cur) => acc + +(cur.totalCost || 0), 0);

  // columns
  const columns: string[] = ['Order number', 'Order total', 'Date'];

  return (
    <div className="RaDatagrid-tableWrapper">
      <div className={cls.tableHeader}>
        <div>
          <Input
            placeholder="Search"
            className={cls.searchInput}
            value={search}
            onChange={e => setSearch(e.target.value)}
            suffix={(
              <svg xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none">
                <path d="M14.3632 15C14.1941 15 14.0348 14.9338 13.9147 14.8137L10.2308 11.1397L9.99064 11.3162C8.90485 12.1176 7.61809 12.5417 6.2676 12.5417C4.91711 12.5417 3.63034 12.1176 2.54456 11.3162C-0.237305 9.26471 -0.830443 5.33088 1.22348 2.54902C2.39995 0.953431 4.2872 0 6.2725 0C7.61809 0 8.9024 0.42402 9.99064 1.22549C12.7725 3.27696 13.3681 7.21078 11.3142 9.99265L11.1377 10.2328L14.8117 13.9167C15.0593 14.1642 15.0593 14.5662 14.8117 14.8137C14.6916 14.9338 14.5323 15 14.3632 15ZM6.27495 1.27206C3.51515 1.27206 1.27005 3.51716 1.27005 6.27696C1.27005 9.03677 3.51515 11.2819 6.27495 11.2819C9.03475 11.2819 11.2799 9.03677 11.2799 6.27696C11.2799 3.51716 9.03475 1.27206 6.27495 1.27206Z"
                  fill="#002A77" />
              </svg>
            )}
          />
        </div>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 750 }}>
          <TableHead>
            <TableRow>
              {columns.map(columnName => (
                <TableCell key={columnName} className={cls.thead}>
                  {columnName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order: Order) =>
              order.code.toLowerCase().includes(search.toLowerCase()) && (
                <TableRow key={order.id} className={cls.tableRow}>
                  <TableCell className={cls.value}>
                    {order.code}
                  </TableCell>
                  <TableCell className={cls.value}>
                    {moneyFormatter.format(order.totalCost)}
                  </TableCell>
                  <TableCell className={cls.value}>
                    {dateFormatter.toDateSlash(order.createdAt)}
                  </TableCell>
                </TableRow>
              ))}
            <TableRow className={cls.totalRow}>
              <TableCell className={cls.totalValue}>Total</TableCell>
              <TableCell className={cls.totalValue}>
                {moneyFormatter.format(summary)}
              </TableCell>
              <TableCell className={cls.value}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const useStyles = makeStyles({
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  tableRow: {
    height: 37,
  },
  thead: {
    fontWeight: 500,
  },
  totalRow: {
    height: '45px !important',
  },
  totalValue: {
    paddingLeft: '20px !important',
    color: '#042E6B !important',
    fontSize: '15px !important',
    fontWeight: 500,
  },
  value: {
    paddingLeft: '20px !important',
    color: '#000 !important',
    fontSize: '15px !important',
  },
  searchInput: {
    width: 220,
    height: 37,
    borderRadius: 8,
    border: '1px solid #042E6B',
  },
});
