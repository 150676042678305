import { EditProps, Edit, BooleanInput, SimpleForm } from 'react-admin';
import {
  Row,
  Col,
  Container,
  TextInput,
  PasswordInput,
  PhoneInput,
  ManyInput,
  Forbidden, ManyCheckboxInput,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Admin: {record.name}</span>) : null;

export const AdminEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container max>
          <Row title="Identity">
            <Col><TextInput source="username" required /></Col>
            <Col><TextInput source="name" required /></Col>
            <Col><PhoneInput source="phone" /></Col>
            <Col><TextInput source="email" type="email" /></Col>
          </Row>
          <Row>
            <Col><BooleanInput source="isEnabled" /></Col>
            <Col><BooleanInput source="isSuperadmin" /></Col>
            <Col flex={2}>
              <ManyCheckboxInput source="roleIds" reference="role" label="Roles" />
            </Col>
          </Row>
          <Row title="Change Password">
            <Col><PasswordInput source="password" /></Col>
            <Col><PasswordInput source="confirmPassword" /></Col>
            <Col />
            <Col />
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};

export const adminRolesOptions = [
  { id: 'manager', name: 'Manager' },
  { id: 'support', name: 'Support' },
];
