import React from 'react';
import { DateField as RaDateField, NumberField as RaNumberField } from 'react-admin';

export const DateField = ((props: any) => {
  const options = props.options || {};
  options.timeZone = 'UTC';

  return (
    <RaDateField {...props} options={options} />
  );
}) as typeof RaDateField;

export const NumberField = ((props: any) => {
  const options = props.options || {};

  return (
    <RaNumberField {...props} options={options} />
  );
}) as typeof RaNumberField;
