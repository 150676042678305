import { EditProps, Edit, SimpleForm, useRecordContext } from 'react-admin';
import {
  Row,
  Col,
  Container,
  TextInput,
  BooleanInput,
  Forbidden,
  ManyCheckboxInput,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = () => {
  const record = useRecordContext();

  return <span>Permission: {record?.name}</span>;
};

export const RoleEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container max>
          <Row>
            <Col><TextInput source="name" required /></Col>
            <Col><BooleanInput source="isEnabled" /></Col>
            <Col><TextInput source="id" disabled /></Col>
            <Col />
          </Row>
          <Row>
            <Col flex={4}>
              <ManyCheckboxInput
                source="permissionIds"
                reference="permission?method[]=ALL&method[]=READONLY&_start=0&_end=9999999"
                label="Permissions"
                columns={4}
              />
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
