import { TextField, EmailField, ListProps } from 'react-admin';

import {
  PhoneField,
  TextInput,
  Grid,
  GridList,
  BooleanField,
  ManyField,
  Forbidden,
  DateField,
} from '@components';
import { useRbacList } from '@hooks';

export const AdminList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'username name email phone roles isEnabled isSuperadmin'.split(' ')}
      >
        <TextField source="username" />
        <TextField source="name" />
        <PhoneField source="phone" />
        <EmailField source="email" />
        <ManyField source="roles" reference="role" label="Roles" />
        <BooleanField source="isSuperadmin" label="Superadmin" />
        <BooleanField source="isEnabled" label="Enabled" />
        <DateField source="createdAt" label="Created" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];

