import { FC, useRef, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';

import { ordersHttp } from '@network';
import { OrderTransactionPartner } from '@types';
import { useRequest, useUpdated } from '@hooks';
import cls from './order-pay.module.css';

interface Props {
  orderId: number;
  partner: OrderTransactionPartner;
  title?: string;
  modalTitle?: string;
  isIncome?: boolean;
}

export const OrderPay: FC<Props> = ({ orderId, title, modalTitle, partner, isIncome }) => {
  const amountRef = useRef<any>();
  const [amount, setAmount] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const updated = useUpdated();

  const showModal = () => {
    setIsModalVisible(true);
    setTimeout(() => void amountRef.current?.focus(), 500);
  };
  const hideModal = () => setIsModalVisible(false);

  const { loading, submit } = useRequest(async () => {
    if (!amount) {
      return;
    }
    await ordersHttp.payOperation({ orderId, partner, amount });
    hideModal();
    setAmount('');
    setImmediate(updated);
  });

  return (
    <>
      <div className={cls.box}>
        <Button
          className={isIncome ? cls.btnIncome : cls.btnOutcome}
          onClick={showModal}
          icon={isIncome ? <PlusCircleFilled /> : <MinusCircleFilled />}
          type="primary"
        >
          {title}
        </Button>
      </div>
      <Modal
        open={isModalVisible}
        title={title || modalTitle}
        okText={isIncome ? 'Income to order' : 'Withdrawal from order'}
        onOk={submit}
        confirmLoading={loading}
        onCancel={hideModal}
        width={350}
      >
        <Input
          ref={amountRef}
          addonBefore={isIncome
            ? <PlusCircleFilled className={cls.plusIcon} />
            : <MinusCircleFilled className={cls.minusIcon} />}
          type="number"
          value={amount}
          onChange={e => setAmount(e.target.value)}
          placeholder="Amount ex: 10400.50"
          autoFocus
        />
      </Modal>
    </>
  );
};
