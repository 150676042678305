import { AdminComment } from './AdminComment';
import { DealerContract } from './DealerContract';
import { Crop } from './Crop';
import { Dealer } from './Dealer';
import { Credit } from './Credit';
import { Order } from './Order';
import { Farm } from './Farm';
import { MoffinProfile } from './MoffinProfile';
import { State } from './State';

export interface User {
  id: number;
  code: string;
  status: string;
  statusEn: string;
  source: string | null;
  sourceEn: string | null;
  firstName: string;
  lastName: string;
  middleName: string | null;
  fullName: string;
  email: string;
  phone: string | null;
  birthday: string;
  isEmailActivated: boolean;
  address: string | null;
  addressExtended: string | null;
  fullAddress: string | null;
  rfc: string | null;
  curp: string | null;
  ciec: string | null;
  marriage: string | null;
  avatar: string | null;
  cropIds: number[];
  crops: Crop[];
  dealerIds: number[];
  retailerIds: number[];
  associateIds: number[];
  dealers: Dealer[];
  comments?: AdminComment[];
  contracts?: DealerContract[];
  credits?: Credit[];
  orders?: Order[];
  farms?: Farm[];
  location?: Address[];
  history?: UserHistory[];
  moffinProfiles?: MoffinProfile[];
  isCompany: boolean;
  companyName?: string;
  hubspotRequest?: Record<string, any>;
  hubspotResponse?: Record<string, any>;
  hubspotError?: Record<string, any>;
  hubspotAt?: string;
  hubspotId?: string;
  hubspotLink?: string;
  state?: State | null;
  responsibleAdmin?: {name: string} | undefined;
  contactOwner?: {name: string} | undefined;
  contactNextCycle?: string;
  createdAt: string;
  updatedAt: string;
  isRenewal: boolean;
  isUnqualified: boolean;
  isActive: boolean;
  isInactive: boolean;
  hasLogged?: boolean;
  responsibleAdminId: number;
  hoursRegisterApplied: number | null;
}

export const marriageOptions = ['married', 'divorced', 'widow', 'single'].map(opt => ({
  id: opt,
  name: opt,
}));
export const genderOptions = ['Hombre', 'Mujer', 'Prefiero no decir'].map(opt => ({
  id: opt,
  name: opt,
}));
export const loginTryOptions = ['Logged in', 'Not Logged in'].map(opt => ({
  id: opt.toLowerCase(),
  name: opt,
}));
export const moffinOptions = ['Yes', 'No', 'Completed'].map(opt => ({
  id: opt.toLowerCase(),
  name: opt,
}));

export interface Address {
  extended: string;
  placeId: string;
  latitude: string;
  longitude: string;
  country: string;
  postalCode: string;
  state: string;
  city: string;
  neighbourhood: string;
  street: string;
  house: string;
  apartment: string;
  id?: number;
  userId?: number;
  original?: string;
  components?: any;
  formatted?: string;
}

export interface UserHistory {
  id: number;
  title: string;
  statusBefore: string | null;
  statusAfter: string | null;
  adminName?: string | null;
  createdAt: string;
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  // deprecated
  DEACTIVATED = 'deactivated',
  REGISTERED = 'registered',
  INFO_FILLED = 'info_filled',
  QUESTIONNAIRE_FILLED = 'questionnaire_filled',
  APPLIED = 'applied',
  RENEWED = 'renewed',
  REJECTED = 'rejected',
  NON_VALID = 'non_valid',
  AUTO_CALCULATE = 'auto_calculate',
  CONTACT_NEXT_CYCLE = 'contact_next_cycle',
}

