import {
  Edit,
  DeleteButton,
  EditProps,
  FormTab,
  TabbedForm,
  Toolbar,
  Link,
} from 'react-admin';
import { makeStyles } from '@mui/styles';

import {
  Col,
  Container,
  DateTimeInput,
  MoneyInput,
  ConfirmOrder,
  ReferenceInput,
  Row,
  SelectInput,
  TextInput,
  HistoryField,
  NumberInput,
  DateTimeField,
  Forbidden,
} from '@components';
import { Credit, Order } from '@types';
import { useEditProps, useRbacEdit, useRbacView, useRecord } from '@hooks';

import { orderStatusOptions } from './OrderStatusField';
import { OrderCompositionInput } from './OrderCompositionInput';
import { OrderDeliveryField } from './OrderDeliveryField';
import { OrderLinkField } from './OrderLinkField';
import { CancelOrderButton } from './CancelOrderButton';
import { RequestPaymentButton } from './RequestPaymentButton';
import { Divider } from '@mui/material';
import { SaveOrderButton } from '@pages/order/SaveOrderButton';
import { OrderTransactionsField } from './OrderTransactionsField';
import { RollbackOrderButton } from './RollbackOrderButton';
import { useEffect, useState } from 'react';
import { creditHttp, ordersHttp } from '@network';

const Title = ({ record }: {record?: any}) => {
  return record ? (<span>Order #{record.id}</span>) : null;
};

export const OrderEditOld = (props: EditProps) => {
  const cls = useStyles();
  const order = useRecord<Order>(props);
  const canBeCanceled = order && order.status === 'waiting_for_payment';
  const canBeRequested = order && order.status === 'waiting_for_payment';
  const canBeDeleted = order && order.status !== 'paid';
  const canBeRollback = order && order.status === 'paid';
  const isPaid = order && order.isPaid;
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();
  const [credits, setCredits] = useState<Credit[]>([]);

  useEffect(() => {
    order?.userId && (async () => {
      const nextCredits = await creditHttp.getCreditsByUser(order.userId);
      setCredits(nextCredits);
    })();
  }, [order?.userId]);

  if (!rbacView) {
    return <Forbidden />;
  }

  const EditToolbar = (props: any) => {
    return rbacEdit ? (
      <Toolbar {...props} className={cls.toolbar}>
        <SaveOrderButton {...props} order={order} />
        <div>
          {canBeRollback && <RollbackOrderButton order={order} />}
          {canBeRequested && <RequestPaymentButton order={order} />}
          {canBeCanceled && <CancelOrderButton order={order} />}
          <DeleteButton {...props} disabled={!canBeDeleted} />
        </div>
      </Toolbar>
    ) : null;
  };

  const orderDeliveryAddressDisabled = order?.status !== 'paid' || !rbacEdit;

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <TabbedForm toolbar={rbacEdit ? <EditToolbar /> : false}>
        <FormTab label="Order">
          <Container max>
            <Row>
              <Col><SelectInput source="status" choices={orderStatusOptions} disabled /></Col>
              <Col><DateTimeInput disabled source="createdAt" /></Col>
              <Col><TextInput disabled source="code" /></Col>
              <Col label="Delivery status"><OrderDeliveryField source="deliveryStatus" /></Col>
              <OrderLinkField
                source="credit"
                label="Credit code"
                linkPrefix="credit/"
                linkText="code"
                linkTarget="id"
              />
            </Row>
            <Row>
              <Col><MoneyInput disabled source="totalCost" label="Total amount" /></Col>
              <Col><MoneyInput disabled source="balance" label="Paid amount" /></Col>
              <Col flex={2}>
                {order && rbacEdit ? (
                  <Row>
                    <ConfirmOrder order={order} credits={credits} editable={rbacEdit} />
                  </Row>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  source="address"
                  label="Delivery address"
                  disabled={orderDeliveryAddressDisabled}
                />
              </Col>
            </Row>
            {rbacEdit ? (
              <Row title="Request Quotation confirmation">
                <Col><RequestPaymentButton order={order} /></Col>
                <Col><NumberInput source="paymentLivesHours" disabled /></Col>
                <Col><DateTimeField source="paymentStartsAt" disabled /></Col>
                <Col><DateTimeField source="paymentExpiresAt" disabled /></Col>
                <Col><DateTimeField source="paymentPaidAt" disabled /></Col>
              </Row>
            ) : null}
            <Row>
              <Col title="Positions">
                <OrderCompositionInput source="composition" isPaid={isPaid} editable={rbacEdit} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col title="Transactions"><OrderTransactionsField source="transactions" /></Col>
              <Col title="History"><HistoryField source="history" /></Col>
            </Row>
            <Row title="User">
              <Col>
                <TextInput
                  source="user.fullName"
                  label="Full name"
                  disabled
                  helperText={
                    order && order.userId
                      ? <Link to={`/user/${order.userId}`}><b>open user</b></Link>
                      : undefined
                  }
                />
              </Col>
              <Col><TextInput source="user.email" label="E-mail" disabled /></Col>
              <Col><TextInput source="user.phone" label="Phone" disabled /></Col>
              <Col>
                <ReferenceInput source="user.stateId" reference="state" label="State">
                  <SelectInput optionText="name" disabled />
                </ReferenceInput>
              </Col>
            </Row>
          </Container>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  toolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
});
