import { makeStyles } from '@mui/styles';

import { InitialCard } from './initial-card';
import { useState } from 'react';
import { Scroll } from '@components/Scroll';
import { dateFormatter } from '@utils';
import { OrangeOutlineButton } from '@components/OrangeOutlineButton';

export interface HistoryItem {
  id: number;
  title: string;
  createdAt: string;
  statusBefore?: string | null;
  statusAfter?: string | null;
  adminName?: string | null;
  adminId?: number | null;
}

interface Props {
  title: string;
  history: HistoryItem[];
  mt?: number;
  max?: number;
}

export const InitialHistory = ({ title, history, mt, max = 4 }: Props) => {
  const cls = useStyles();
  const [showAll, setShowAll] = useState(false);
  const allItems = history;
  const showItems = allItems.slice(0, showAll ? undefined : max);

  const showMoreBtn = !showAll && allItems.length > showItems.length;

  return (
    <InitialCard left={title} mt={mt} autoHeight extraPadding>
      <div className={cls.box}>
        <Scroll height={showAll ? 300 : showItems.length * 75}>
          {showItems.map(item => (
            <div className={cls.item} key={item.id}>
              <div className={cls.header}>
                <div className={cls.title}>{item.title}</div>
                <div>{dateFormatter.toDateTime(item.createdAt)}</div>
              </div>
              <div className={cls.footer}>
                {item.statusBefore ? <div>{item.statusBefore}</div> : null}
                {item.statusBefore && item.statusAfter ? (
                  <svg xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                    fill="none">
                    <path d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464467C11.9763 0.269205 11.6597 0.269205 11.4645 0.464467C11.2692 0.659729 11.2692 0.976312 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM-4.37114e-08 4.5L15 4.5L15 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="#042E6B" />
                  </svg>
                ) : null}
                {item.statusAfter ? <div>{item.statusAfter}</div> : null}
              </div>
            </div>
          ))}

          {showMoreBtn ? (
            <OrangeOutlineButton onClick={() => setShowAll(true)}>
              Show Previous ({allItems.length - showItems.length})
            </OrangeOutlineButton>
          ) : null}
        </Scroll>
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
  },
  item: {
    marginBottom: 18,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#002A77',
    fontSize: 12,
    fontWeight: 400,
    borderBottom: '1px solid rgba(4, 46, 107, 0.50)',
    paddingBottom: 6,
  },
  title: {},
  footer: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    fontSize: 14,
    lineHeight: 1.1,
    fontWeight: 400,
    minHeight: 21,
    wordBreak: 'break-all',

    '& svg': {
      display: 'block',
      margin: '0 10px',
    },
  },
  showMoreBtn: {
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 8,
    textTransform: 'none',
    color: '#FD8A32',
    fontWeight: 400,
  },
});
