import { FinanceDocument } from './FinanceDocument';

export interface FinanceDeposit {
  id: number;
  concept: string;
  conceptMonth: string;
  conceptNumber: string;
  amount: number;
  accountId: number | null;
  account: FinanceDepositAccountOption | null;
  creditId: number | null;
  credit: FinanceDepositCreditOption | null;
  code: string | null;
  creditCode: string | null;
  paymentDate: Date;
  purpose: string;
  details: string | null;
  accredited: string | null;
  received: boolean;
  createdAt: string;
  updatedAt: string;
  receipts: FinanceDocument[];
  invoices: FinanceDocument[];
  airtableId: string | null;
}

export interface FinanceDepositAccountOption {
  id: number;
  code: string;
  account: string;
  balance?: number;
}

export interface FinanceDepositCreditOption {
  id: number;
  code: string;
  accredited: string;
  balance?: number;
}

export interface FinanceDepositOptions {
  concept: string[];
  conceptMonth: string;
  conceptNumber: string;
  purpose: string[];
  accounts: FinanceDepositAccountOption[];
  credits: FinanceDepositCreditOption[];
}

export interface CreateFinanceDepositParams {
  concept: string;
  amount: number;
  accountId: number | null;
  creditId: number | null;
  paymentDate: string;
  purpose: string;
  details: string | null;
  received: boolean;
}

export const purposeOptions = ['Credits', 'Operations'].map(opt => ({
  id: opt,
  name: opt,
}));
