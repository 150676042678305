import { makeStyles } from '@mui/styles';
import { Col, Row } from 'antd';
import { clsx } from 'clsx';
import { Button } from '@mui/material';
import { CreditMoneyType } from '@types';
import { InitialCard } from '@components';
import { moneyFormatter } from '@utils';

export interface Amount {
  title: string;
  amount: number;
  group: CreditMoneyType;
}

interface AmountWidgetProps {
  amounts: Amount[];
  group: string | undefined;
  changeGroup: (group: CreditMoneyType) => void;
}

export const AmountsWidget = ({ amounts, changeGroup, group }: AmountWidgetProps) => {
  const cls = useStyles();

  return (
    <InitialCard autoHeight extraPadding>
      <div className={cls.box}>
        <Row gutter={[15, 15]}>
          {amounts.map(amount => (
            <Col className={cls.col} key={amount.title} xs={12} sm={8} md={4}>
              <Button
                className={clsx(cls.groupBtn, amount.group === group && cls.groupSelected)}
                onClick={() => changeGroup(amount.group)}
              >
                <div className={cls.title}>{amount.title}</div>
                <div className={cls.money}>{moneyFormatter.format(amount.amount)}</div>
              </Button>
            </Col>
          ))}
        </Row>
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    fontFamily: 'Outfit, sans-serif',
  },
  col: {},
  groupBtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    textTransform: 'none',
    color: '#272727',
  },
  groupSelected: {
    backgroundColor: '#042E6B !important',
    color: 'white !important',
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
  },
  money: {
    fontSize: 15,
    fontWeight: 400,
  },
});
