import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

import { CreditRisk } from '@types';

export const RiskCreditBureauScore = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const { tableCreditBureauScore } = risk;

  if (!tableCreditBureauScore?.headers || !tableCreditBureauScore.headers.length) {
    return null;
  }

  return (
    <div className={cls.wrap}>
      <div className={clsx(cls.row, cls.categories)}>
        <div className={clsx(cls.category, cls.col, cls.col_full)}>Buro de Crédito Score</div>
      </div>
      <div className={cls.row}>
        {tableCreditBureauScore.headers.map(header => (
          <div key={header.key} className={clsx(cls.header, cls.col, cls.col_1)}>
            {header.label}
          </div>
        ))}
      </div>
      <div>
        {tableCreditBureauScore.rows.map((row, idx) => (
          <div key={idx} className={cls.row}>
            {tableCreditBureauScore.headers.map((header) => (
              <div key={header.key} className={clsx(cls.content, cls.bg, cls.col, cls.col_1)}>
                {row[header.key]}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  wrap: {
    border: '1px solid #042E6B',
    borderRadius: 5,
    overflow: 'hidden',
    marginTop: 30,
  },
  row: {
    display: 'flex',
  },
  col: {
    backgroundColor: '#FFF',
  },
  col_1: {
    flex: '0 0 50%',
    maxWidth: '50%',
  },
  col_full: {
    flex: '0 0 100%',
    maxWidth: '100%',
  },
  categories: {
    backgroundColor: '#042E6B',
  },
  category: {
    minHeight: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: '#042E6B',
    borderLeft: '1px solid #FFF',
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  header: {
    minHeight: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 500,
    color: '#042E6B',
    borderLeft: '1px solid #042E6B',
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  bg: {
    backgroundColor: '#F0F3FB',
  },
  content: {
    borderTop: '1px solid #042E6B',
    borderLeft: '1px solid #042E6B',
    '&:first-child': {
      borderLeft: 'none',
    },
    minHeight: 34,
    fontSize: 12,
    fontWeight: 400,
    color: '#042E6B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
