import { Bank } from './Bank';
import { Guarantor } from './Guarantor';
import { CreditHistory } from './CreditHistory';
import { CreditTransaction } from './CreditTransaction';
import { Document } from './Document';
import { User } from './User';
import { CreditCrop, Crop } from './Crop';
import { CreditInsurance } from './CreditInsurance';
import { AirtableCredit } from './AirtableCredit';
import { CreditFarm } from './CreditFarm';
import { CreditCall } from './CreditCall';
import { CreditInvoice } from './CreditInvoice';
import { CreditMoney } from './CreditMoney';
import { Order } from './Order';
import { Chat } from './Chat';
import { CreditRisk } from './CreditRisk';
import { DocumentFile } from '@types';

export interface Credit {
  id: number;
  userId: number;
  user: User;
  status: CreditStatus;
  statusEn: string;
  code: string;
  docsLink: string;
  responsibleAdmin?: any;
  bank: Bank | null;
  balance: number;
  createdAt: string;
  creditCrops: CreditCrop[];
  orders?: Order[];
  guarantors: Guarantor[];
  history: CreditHistory[];
  transactions: CreditTransaction[];
  documents: Document[];
  delayDays: number | null;
  insurances?: CreditInsurance[];
  requestAmount: number;
  financedCrop: Crop | null;
  financedCropId: number | null;
  financedHa: number | null;
  insuranceCompany: string | null;
  signatureAt: string | null;
  paymentAt: string | null;
  airtableCredits?: AirtableCredit[];
  airtableFields: Record<string, any>;
  airtableSyncAt: string | null;
  airtableUpdatedAt: string | null;
  modificationAt: string | null;
  progressAt: string | null;
  farms?: CreditFarm[];
  calls?: CreditCall[];
  invoices?: CreditInvoice[];
  money?: CreditMoney[];
  isManualBalance: boolean;
  ordersAmount: number;
  bankApproval: number;
  manual: number;
  farmerDeposit: number;
  membership: number;
  creditFee: number;
  interest: number;
  insurance: number;
  refunds: number;
  retention: number;
  repayment: number;
  membershipPercent: number;
  membershipCompanyPercent: number;
  isMoney: boolean;
  riskScore: number | null;
  progressPercent?: number;
  chat?: Chat;
  risk?: CreditRisk;
  isGuarantorsRequired?: boolean;
  isAdminDraft?: boolean;
  accredited: string | null | CreditAccredited;
  accreditedName: string | null;
  hoursCreatedAnalysis: number | null;
  hoursAnalysisResult: number | null;
}

export interface CreditReportFile extends File {
  id: number;
  file: DocumentFile;
}

export enum CreditStatus {
  MODERATION = 'moderation',
  COMPLETED_FILE = 'completed_file',
  BANK_REVISION = 'bank_revision',
  APPROVED = 'approved',
  DENIED = 'denied',
  SIGNED = 'signed',
  REPAID = 'repaid',
  DRAFT = 'draft',
  RESTRUCTURE = 'restructure',
  DELAY = 'delay',
}

export const creditStatusMap = {
  [CreditStatus.MODERATION]: 'Applying',
  [CreditStatus.COMPLETED_FILE]: 'Completed File',
  [CreditStatus.BANK_REVISION]: 'Analysis',
  [CreditStatus.APPROVED]: 'Approved',
  [CreditStatus.DENIED]: 'Denied',
  [CreditStatus.SIGNED]: 'Signed',
  [CreditStatus.REPAID]: 'Repaid',
  [CreditStatus.DRAFT]: 'Draft',
  [CreditStatus.RESTRUCTURE]: 'Restructure',
  [CreditStatus.DELAY]: 'Delay',
};

export const creditFixedStatuses: CreditStatus[] = [
  CreditStatus.SIGNED,
  CreditStatus.APPROVED,
  CreditStatus.DELAY,
  CreditStatus.RESTRUCTURE,
];

export const creditFixedTitle = creditFixedStatuses.map(s => creditStatusMap[s]).join(', ');

export enum CreditAccredited {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}
