import { useState } from 'react';
import { ReplayOutlined } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Modal } from 'antd';

import { useRequest, useUpdated } from '@hooks';
import { ordersHttp } from '@network';
import { Order } from '@types';

export const RollbackOrderButton = ({ order }: {order: Order}) => {
  const updated = useUpdated();
  const cls = useStyles();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { loading, submit } = useRequest(async () => {
    await ordersHttp.rollbackOrder(order.id);
    setIsModalVisible(false);
    setImmediate(updated);
  });

  return (
    <>
      <Button
        className={cls.button}
        variant="text"
        type="button"
        onClick={() => setIsModalVisible(true)}
        color="inherit"
        aria-label="Cancel"
      >
        <ReplayOutlined className={cls.icon} />
        Rollback
      </Button>

      <Modal
        title="Rollback order payment"
        open={isModalVisible}
        onOk={submit}
        onCancel={() => setIsModalVisible(false)}
        okText="Yes"
        cancelText="No"
        confirmLoading={loading}
      >
        <p>Do you really want to rollback the payment of this order?</p>
      </Modal>
    </>
  );
};

const useStyles = makeStyles({
  button: {
    color: '#ffa500',
    marginRight: 15,
  },
  icon: {
    fontSize: 22,
    color: '#ffa500',
    marginRight: 6,
  },
});
