import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { SaveFilled } from '@ant-design/icons';
import {
  Create,
  CreateProps,
  SaveButton,
  SimpleForm,
  TopToolbar,
} from 'react-admin';

import {
  Container,
  TextInput,
  Forbidden,
  SelectInput,
  PageHeader,
  FormRow,
  SwitchInput, InputMultiple,
} from '@components';
import { AnswersInput } from '../AnswersInput';
import { useCreateProps, useRbacCreate } from '@hooks';
import { requirementsOptions } from '../requirements-options';
import cls from './QuestionCreate.module.css';
import { dealerTiers } from '@types';

export const QuestionCreate = (props: CreateProps) => {
  const maxNameLength = 500;
  const rbacCreate = useRbacCreate();
  const createProps = useCreateProps();
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [isSendToCredit, setIsSendToCredit] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [isOptional, setIsOptional] = useState(false);
  const [isTable, setIsTable] = useState(false);
  const [withCustomAnswer, setWithCustomAnswer] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [isMoney, setIsMoney] = useState(false);
  const mainColGrid = {
    xs: { title: 24, children: 24 },
    sm: { title: 12, children: 12 },
    md: { title: 10, children: 14 },
    lg: { title: 8, children: 16 },
    xl: { title: 10, children: 14 },
    xxl: { title: 8, children: 16 },
  };

  useEffect(() => {
    setDisabled(name.length > 500 || name.length === 0);
  }, [name]);

  if (!rbacCreate) {
    return <Forbidden />;
  }

  const Actions = ({ disabled }: {disabled: boolean}) => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon} />}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
          disabled={disabled}
        />
      </TopToolbar>
    );
  };

  const defaultValues = {
    nameMx: '',
    requirements: 'all',
    maxInputLength: 1024,
    isEnabled: true,
    isSendToCredit: false,
    isOptional: false,
    isTable: false,
    withCustomAnswer: false,
    isMultiple: false,
    isNumber: false,
    isMoney: false,
    isVisible: true,
    excludedTiers: [],
  };

  return (
    <Create
      {...props}
      {...createProps}
      className={clsx(cls.page, 'details-page-wrapper')}
    >
      <PageHeader
        title="Create a question"
        linkText="Back to all questions"
        linkUrl="/question"
      />

      <SimpleForm
        toolbar={<Actions disabled={disabled} />}
        className={cls.form}
        defaultValues={defaultValues}
      >
        <Container max>
          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColFirst)}>
              <div className={clsx(cls.block, cls.blockMain)}>
                <div className={cls.blockHeader}>Question</div>
                <FormRow
                  title="Name mx*:"
                  fullWidth
                  rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                  colTitleClassName={cls.formLabelCol}
                  colChildrenClassName={clsx(
                    cls.formValueCol,
                    cls.formMainValue,
                    cls.nameInput,
                    name.length > 500 ? cls.error : '',
                  )}
                >
                  <span className={cls.counter}>{name.length}/{maxNameLength}</span>
                  <TextInput
                    name="nameMx"
                    source="nameMx"
                    required
                    autoFocus
                    multiline
                    label={false}
                    onInput={(e: any) => setName(e.target.value)}
                    className={cls.nameWrapper}
                    placeholder="Write your question"
                  />
                </FormRow>
                <FormRow
                  title="Applies to type of User"
                  width={mainColGrid}
                  rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                  colTitleClassName={cls.formLabelCol}
                  colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                >
                  <SelectInput
                    source="requirements"
                    label={false}
                    choices={requirementsOptions}
                    required
                    defaultValue="all"
                  />
                </FormRow>
                <FormRow
                  title="Max input length"
                  width={mainColGrid}
                  rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                  colTitleClassName={cls.formLabelCol}
                  colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                >
                  <TextInput
                    label={false}
                    disabled={!withCustomAnswer}
                    name="maxInputLength"
                    source="maxInputLength"
                  />
                </FormRow>
                <FormRow
                  title="Excluded for Tier"
                  width={mainColGrid}
                  rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                  colTitleClassName={cls.formLabelCol}
                  colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                >
                  <InputMultiple
                    multiple
                    options={dealerTiers as string[]}
                    source="excludedTiers"
                    name="excludedTiers"
                  />
                </FormRow>
              </div>
            </Col>
            <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColLast)}>
              <div className={clsx(cls.block, cls.blockMain)}>
                <div className={cls.blockHeader}>Field type</div>
                <div className={cls.switchers}>
                  <div className={cls.switcher}>
                    <SwitchInput
                      name="isEnabled"
                      source="isEnabled"
                      label="Is enabled"
                      value={isEnabled}
                      onChange={setIsEnabled}
                      className={cls.switcherField} />
                    <SwitchInput
                      name="isOptional"
                      source="isOptional"
                      label="Is optional"
                      value={isOptional}
                      onChange={setIsOptional}
                      className={cls.switcherField} />
                    <SwitchInput
                      name="isTable"
                      source="isTable"
                      label="Is table"
                      value={isTable}
                      onChange={setIsTable}
                      disabled={withCustomAnswer || isMultiple || isNumber}
                      className={cls.switcherField} />
                    <SwitchInput
                      name="isMultiple"
                      source="isMultiple"
                      label="Is multiple"
                      value={isMultiple}
                      onChange={setIsMultiple}
                      disabled={isTable || withCustomAnswer}
                      className={cls.switcherField} />
                    <SwitchInput
                      name="isNumber"
                      source="isNumber"
                      label="Is number"
                      value={isNumber}
                      onChange={setIsNumber}
                      disabled={!withCustomAnswer || isTable}
                      className={cls.switcherField} />
                    <SwitchInput
                      name="isMoney"
                      source="isMoney"
                      label="Is money"
                      value={isMoney}
                      onChange={setIsMoney}
                      disabled={!withCustomAnswer || isTable}
                      className={cls.switcherField} />
                    <SwitchInput
                      name="withCustomAnswer"
                      source="withCustomAnswer"
                      label="With custom answer"
                      value={withCustomAnswer}
                      onChange={setWithCustomAnswer}
                      disabled={isTable}
                      className={cls.switcherField} />
                    <SwitchInput
                      name="isSendToCredit"
                      source="isSendToCredit"
                      label="Send to Analysis"
                      value={isSendToCredit}
                      onChange={setIsSendToCredit}
                      className={cls.switcherField} />
                    <SwitchInput
                      name="isVisible"
                      source="isVisible"
                      label="Is visible"
                      className={cls.switcherField} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24} className={clsx(cls.wrapperCol, cls.wrapperFull)}>
              <div className={clsx(cls.block, cls.blockMain)}>
                <div className={cls.blockHeader}>Answers</div>
                {withCustomAnswer && (
                  <div>User will type in his custom answer</div>
                )}
                {!withCustomAnswer && (
                  <AnswersInput source="answers" />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
