import { Balance } from './components/Balance';
import { VerticalDoubleGraph } from './components/VerticalDoubleGraph';
import { InitialPage } from '@components';
import { rbac } from '@utils';

export const FinanceDashboardPage = () => {
  const hasAccess = rbac.hasFinanceBalancePerm();

  if (!hasAccess) {
    return null;
  }

  return (
    <InitialPage title="Balance">
      <Balance />
      <VerticalDoubleGraph />
      {/*<HorizontalDoubleGraph />*/}
    </InitialPage>
  );
};
