import React, { useEffect, useState } from 'react';
import { Row, Col, notification } from 'antd';
import {
  DeleteButton,
  Edit,
  EditProps,
  SaveButton,
  SimpleForm,
} from 'react-admin';
import { Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { checkSuperadmin } from '@providers';
import { Forbidden, InitialPage, Loader } from '@components';
import { AnswerCol, AnswerRow, Credit, Doctype, SurveyQuestion } from '@types';
import { useEditProps, useQuery, useRbacEdit, useRbacView, useRecord } from '@hooks';
import {
  creditHttp,
  CreditSurveyTableColProps,
  CreditSurveyTableRowProps,
  surveyHttp,
} from '@network';

import { axiosErrorToString, isProdServer } from '@utils';
import { CreditCallsPage } from './CreditCallsPage';
import { CreditFarmsPage } from './CreditFarmsPage';
import { CreditInitial } from '@pages/credit/CreditInitial';
import { CreditDocuments } from '@pages/credit/CreditWidget';
import { CreditReportPage } from '@pages/credit/CreditReport';
import { CreditB2B } from '@pages/credit/CreditEdit/CreditB2B';
import { CreditUser } from '@pages/credit/CreditEdit/CreditUser';
import { CreditInfo } from '@pages/credit/CreditEdit/CreditInfo';
import { CreditHistory } from '@pages/credit/CreditEdit/CreditHistory';
import { CreditAccredited } from '@pages/credit/CreditEdit/CreditAccredited';
import { CreditGuarantor, CreditGuarantorCreate } from '@pages/credit/CreditEdit/CreditGuarantor';
import { CreditSurveyDetail } from '@pages/credit/CreditEdit/CreditSurveyDetail';
import { CreditRiskRedirect } from '@pages/credit/CreditEdit/CreditRiskRedirect';
import { CreditDocumentsLink } from '@pages/credit/CreditEdit/CreditDocumentsLink';
import { CreditContractsDetails } from '@pages/credit/CreditInitial/CreditContractsDetails/credit-contracts-details';
import { CreditInvoicesDetail, CreditInvoicesPage } from './CreditInvoices';
import { CreditSurveyProgress } from '@pages/credit/CreditInitial/CreditSurveyProgress';
import { CreditFarms } from '@pages/credit/CreditInitial/CreditFarms';
import { CropProblems } from '@pages/credit/CreditEdit/CreditB2B/CropProblems';
import { CreditIncomeVerification } from '@pages/credit/CreditEdit/CreditIncomeVerification';

const Title = ({ record }: {record?: any}) => {
  return record ? (<span>Credit #{record.id}</span>) : null;
};

export const CreditEdit = (props: EditProps) => {
  const cls = useStyles();
  const query = useQuery();
  const initialCredit = useRecord<Credit>(props);
  const editProps = useEditProps();

  const [credit, setCredit] = useState<Credit>();
  const [requiredDoctypeIds, setRequiredDoctypeIds] = useState<number[]>([]);
  const [guarantorDoctypes, setGuarantorDoctypes] = useState<Doctype[]>([]);
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const [savedQuestions, setSavedQuestions] = useState<SurveyQuestion[]>([]);
  const [isFixedSurvey, setIsFixedSurvey] = useState(false);
  const [answeredDate, setAnsweredDate] = useState<Date | null>(null);

  const isSuperadmin = checkSuperadmin();
  const rbacView = useRbacView();
  const editable = useRbacEdit();

  const getSurvey = async () => {
    if (credit) {
      const {
        surveyQuestions, answeredDate: loadedAnsweredDate, isFixed,
      } = await surveyHttp.getCreditSurvey(credit.id);

      setIsFixedSurvey(isFixed);
      setQuestions(surveyQuestions.map((q, index) => {
        q.position = index + 1;
        return q;
      }));
      setSavedQuestions(surveyQuestions.map((q, index) => {
        q.position = index + 1;
        return q;
      }));
      setAnsweredDate(loadedAnsweredDate);
    }
  };

  const onSaveQuestions = async (questions: SurveyQuestion[]) => {
    if (isFixedSurvey) {
      return;
    }
    if (credit?.id && questions.length) {
      await Promise.all(
        questions.map(q => {
          const params = q.isTable ? {
            rows: q.rows.map((r: AnswerRow): CreditSurveyTableRowProps => ({
              cols: r.cols.map((c: AnswerCol): CreditSurveyTableColProps => ({
                answerId: c.answerId,
                customAnswer: c.customAnswer,
              })),
            })),
          } : { answerIds: q.answerIds, customAnswer: q.customAnswer || undefined };
          return surveyHttp.updateCreditSurvey(credit.id, q.id, params);
        }),
      ).then(() => {
        notification.success({ message: 'Survey is saved', duration: 8 });
        getSurvey();
      }).catch((e) => {
        console.error(e);
        const errorMessage = axiosErrorToString(e);
        notification.error({ message: errorMessage, duration: 8 });
      });
    }
  };

  useEffect(() => {
    initialCredit && (async () => {
      const res = await creditHttp.getCreditDoctypes(initialCredit.id);
      const requiredDoctypeIdsList: number[] = [];
      (res.doctypes || []).forEach(d => {
        if (d.isSendToCredit) {
          requiredDoctypeIdsList.push(d.id);
        }
      });
      setRequiredDoctypeIds(requiredDoctypeIdsList);
      setGuarantorDoctypes(res.guarantorDoctypes);
    })();
    initialCredit && (async () => {
      const nextCredit = await creditHttp.getCredit(initialCredit.id);
      setCredit(nextCredit);
    })();
  }, [initialCredit]);

  useEffect(() => {
    getSurvey();
  }, [credit?.id]);

  const showInitial = query.get('initial') === '';
  const showSurvey = query.get('survey') === '';
  const showFarms = query.get('farms') === '';
  const showCalls = query.get('calls') === '';
  const showReport = query.get('report') === '';
  const showRisk = query.get('risk') === '';
  const showContracts = query.get('contracts') === '';

  const createGuarantor = query.get('guarantor-create') === '';
  const guarantorId = query.get('guarantor');
  const showGuarantor = !!guarantorId;
  const showInvoices = query.get('invoices') === '';
  const invoiceDetail = query.get('invoices');
  const showInvoiceDetail = !!invoiceDetail;

  const transform = (data: any) => {
    const { status, bankId, docsLink } = data;

    return { status, bankId, docsLink } as any;
  };

  if (!rbacView) {
    return <Forbidden />;
  }

  if (!credit) {
    return <Loader />;
  }

  if (showInitial) {
    return credit ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <CreditInitial credit={credit} />
      </Edit>
    ) : <Loader />;
  }

  if (showSurvey) {
    return credit ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <CreditSurveyDetail
          credit={credit}
          isFixedSurvey={isFixedSurvey}
          questions={questions}
          savedQuestions={savedQuestions}
          setQuestions={setQuestions}
          answeredDate={answeredDate}
          onUpdate={onSaveQuestions}
        />
      </Edit>
    ) : <Loader />;
  }

  if (showFarms) {
    return credit ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <CreditFarmsPage credit={credit} setCredit={setCredit} />
      </Edit>
    ) : <Loader />;
  }

  if (showCalls) {
    return credit ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <CreditCallsPage credit={credit} />
      </Edit>
    ) : <Loader />;
  }

  if (showReport) {
    return credit ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <CreditReportPage
          credit={credit}
          setCredit={setCredit}
          requiredDoctypeIds={requiredDoctypeIds}
          questions={questions.filter(q => q.isSendToCredit) || []}
          guarantorDoctypes={guarantorDoctypes}
        />
      </Edit>
    ) : <Loader />;
  }

  if (showInvoices) {
    return credit ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <CreditInvoicesPage credit={credit} />
      </Edit>
    ) : <Loader />;
  }

  if (showInvoiceDetail) {
    return credit ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <CreditInvoicesDetail credit={credit} invoiceId={+invoiceDetail} />
      </Edit>
    ) : <Loader />;
  }

  if (showRisk) {
    return credit ? (
      <CreditRiskRedirect credit={credit} />
    ) : <Loader />;
  }

  if (createGuarantor) {
    return credit ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <CreditGuarantorCreate
          credit={credit}
        />
      </Edit>
    ) : <Loader />;
  }

  if (showGuarantor) {
    const guarantor = credit.guarantors.find(g => g.id === +guarantorId);
    if (!guarantor) return null;

    return credit ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <CreditGuarantor
          credit={credit}
          doctypes={guarantorDoctypes}
          guarantor={guarantor}
          isSuperadmin={isSuperadmin}
          editable={editable}
        />
      </Edit>
    ) : <Loader />;
  }

  if (showContracts) {
    return credit ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <CreditContractsDetails credit={credit} setCredit={setCredit} isSuperadmin={isSuperadmin} />
      </Edit>
    ) : <Loader />;
  }

  const EditActions = () => {
    return editable ? (
      <Toolbar>
        <SaveButton label="Save" type="button" />
        <DeleteButton />
      </Toolbar>
    ) : null;
  };

  const backTo = `/credit/${credit.id}?initial`;

  return (
    <InitialPage title="Individual credit" backTo={backTo} backText="Back to credit">
      <Edit {...props} title={<Title />} transform={transform} {...editProps}>
        <SimpleForm toolbar={<EditActions />}>
          <Row gutter={[20, 20]} className={cls.columns}>
            <Col sm={24} md={11}>
              <CreditUser credit={credit} />
              <CreditInfo credit={credit} />
              <CreditB2B credit={credit} />
            </Col>
            <Col sm={24} md={13}>
              <CreditAccredited credit={credit} />
              <CreditSurveyProgress titlePosition="left" credit={credit} />
              <CreditFarms credit={credit} />
              {!isProdServer() && <CreditIncomeVerification credit={credit} />}
              <CreditHistory credit={credit} />
              <CreditDocumentsLink credit={credit} />
              <CropProblems />
            </Col>
          </Row>
        </SimpleForm>
      </Edit>

      <div className={cls.block}>
        <CreditDocuments
          credit={credit}
          isSuperadmin={isSuperadmin}
          editable={editable}
          isCompany={credit?.user?.isCompany || false}
        />
      </div>
    </InitialPage>
  );
};

const useStyles = makeStyles({
  columns: {
    width: '100%',
  },
  block: {
    marginTop: 18,
  },
});
