import React from 'react';
import { ListProps, TextField, ReferenceField } from 'react-admin';

import {
  TextInput, Grid, GridList, Forbidden, SelectArrayInput, SelectInput, DateField,
} from '@components';
import { useRbacList } from '@hooks';
import { CallStatusField } from './CallStatusField';
import { CallRiskField } from './CallRiskField';
import { CreditStatusField } from '@pages/credit/CreditStatusField';
import { rbac } from '@utils/rbac';

export const CreditCallList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton={!rbac.isSuperadmin()}
    >
      <Grid
        rowClick="edit"
        defaultColumns={['id', 'credit.id', 'credit.userId', 'credit.user.phone',
          'title', 'responsibleAdmin.name', 'status', 'risk',
        ]}
      >
        <ReferenceField source="credit.id" reference="credit" label="Credit code">
          <TextField source="code" />
        </ReferenceField>
        <CreditStatusField source="credit.status" label="Credit status" />
        <ReferenceField source="credit.userId" reference="user" label="User Full name">
          <TextField source="fullName" />
        </ReferenceField>
        <TextField source="credit.user.phone" label="Phone number" />
        <TextField source="title" label="Task title/type" />
        <TextField source="responsibleAdmin.name" label="Task manager" />
        <CallStatusField source="status" label="Call status" />
        <CallRiskField source="risk" label="Call risk" />
        <DateField source="contactBy" label="Contact by" />
        <DateField source="contactAt" label="Contact at" />
        <DateField source="credit.paymentAt" label="Payment date" />
        <DateField source="createdAt" label="Created at" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 220 }} />,
  <SelectArrayInput
    style={{ width: 250 }}
    alwaysOn
    key="type"
    source="type"
    choices={[
      { id: 'welcome', name: 'Welcome call', color: '#4CAF50' },
      { id: 'follow_up', name: 'Follow Up', color: '#2196F3' },
      { id: 'collection', name: 'Collections', color: '#673AB7' },
      { id: 'final', name: 'Collections Final', color: '#e36e26' },
      { id: 'manual', name: 'Manual', color: '#888' },
    ]}
    className="MS-field"
  />,
  <SelectInput
    label="Call Date"
    key="callDate"
    source="callDate"
    choices={[
      { id: 'overdue', name: 'Overdue Calls' },
      { id: 'future', name: 'Future Calls' },
    ]}
    alwaysOn
  />,
];
