import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { numberFormatter } from '@utils';
import { Credit, FinanceDeposit, FinanceWithdrawal } from '@types';
import { financeAccountHttp } from '@network';
import { useRequest } from '@hooks';
import { InitialCard } from '@components';

interface FinancialTransactionsButtonProps {
  fileIds: number[];
  type: 'invoice' | 'receipt';
  creditCode: string;
}
const FinancialTransactionsButton = ({
  fileIds, type, creditCode,
}: FinancialTransactionsButtonProps) => {
  const cls = useStyles();

  const { submit: downloadDocuments, loading: isDownloadLoading } = useRequest(async () => {
    await financeAccountHttp.downloadDocuments({
      fileIds,
      type,
      creditCode,
    });
  });

  const isDisabled = fileIds.length === 0;
  const label = type === 'receipt' ? 'View Receipts' : 'View Invoices';

  return (
    <Button
      className={cls.action}
      onClick={downloadDocuments}
      disabled={isDisabled || isDownloadLoading}
    >
      {isDownloadLoading ? 'Loading' : label}
    </Button>
  );
};

export const FinancialTransactions = ({ credit }: {credit: Credit}) => {
  const cls = useStyles();

  const [transactions, setTransactions] = useState<Array<FinanceDeposit | FinanceWithdrawal>>([]);

  useEffect(() => {
    if (credit?.id) {
      (async () => {
        const nextTransactions = await financeAccountHttp.findByCreditId(credit.id);

        if (nextTransactions && Array.isArray(nextTransactions)) {
          setTransactions(nextTransactions);
        }
      })();
    }
  }, [credit]);

  return (
    <InitialCard left="Transactions" autoHeight extraPadding>
      <div className={cls.list}>
        {transactions.length === 0 && (
          <div className={cls.empty}>No items</div>
        )}

        {transactions.map(transaction => {
          let type: 'deposit' | 'withdrawal';
          if (transaction.hasOwnProperty('received')) {
            type = 'deposit';
          } else {
            type = 'withdrawal';
          }

          const amountPrefix = type === 'deposit' ? '+ $' : '- $';
          const amount = numberFormatter.format(transaction.amount);
          return (
            <div key={transaction.id} className={cls.item}>
              <div className={cls.content}>
                <div className={cls.top}>
                  <div className={cls.name}>{transaction.code}</div>
                  <div className={cls.date}>{transaction.createdAt}</div>
                </div>
                <div className={cls.bottom}>
                  <div className={cls.amount}>
                    {amountPrefix + amount}
                  </div>
                  <div className={cls.source}>by @AdminUser</div>
                </div>
              </div>
              <div className={cls.actions}>
                <FinancialTransactionsButton
                  fileIds={transaction?.receipts?.map(r => r.fileId) || []}
                  type="receipt"
                  creditCode={credit.code}
                />
                <FinancialTransactionsButton
                  fileIds={transaction?.invoices?.map(r => r.fileId) || []}
                  type="invoice"
                  creditCode={credit.code} />
              </div>
            </div>
          );
        })}

      </div>
    </InitialCard>

  );
};

const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginTop: 20,
  },
  item: {
    display: 'flex',
    width: '100%',
    gap: 24,
  },
  content: {
    width: '100%',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgba(4, 46, 107, 0.50)',
    paddingBottom: 5,
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 5,
  },
  name: {
    color: '#000',
    fontSize: 14,
  },
  date: {
    color: '#002A77',
    fontSize: 14,
  },
  amount: {
    minWidth: 120,
    borderRadius: 10,
    background: 'rgba(217, 217, 217, 0.50)',
    color: '#002A77',
    fontSize: 12,
    padding: '4px 12px',
    textAlign: 'center',
  },
  source: {
    color: '#002A77',
    fontSize: 12,
  },
  actions: {
    display: 'flex',
    gap: 20,
  },
  action: {
    width: 170,
    color: '#002A77',
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 10,
    border: '1px solid #042E6B',
    background: '#fff',
    lineHeight: 1,
    height: 'fit-content',
    padding: 12,
    textTransform: 'none',
  },
  empty: {
    fontSize: 13,
    fontWeight: 400,
    marginTop: 12,
  },
});
