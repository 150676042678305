import React from 'react';
import { TextField, ListProps, ReferenceField } from 'react-admin';
import Tooltip from '@mui/material/Tooltip';
import { QuestionCircleOutlined } from '@ant-design/icons';

import {
  TextInput,
  Grid,
  GridList,
  BooleanField,
  SelectInput,
  NullableBooleanInput,
  JsonField,
  Forbidden,
  DateRangeInput,
  DateField,
} from '@components';
import { checkSuperadmin } from '@providers';
import { useRbacList } from '@hooks';
import cls from './LoginTry.module.css';

export const LoginTryList = (props: ListProps) => {
  const isSuperadmin = checkSuperadmin();
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'login isSucceed partnerClass userId ip isAdmin createdAt'.split(' ')}
      >
        <TextField source="login" />
        <BooleanField source="isSucceed" label="Is succeed" />
        <TextField source="partnerClass" label="Source" />
        {isSuperadmin
          ? <ReferenceField reference="admin" source="adminId">
            <TextField source="name" />
          </ReferenceField>
          : <></>
        }
        <TextField source="userFullName" label="User name" />
        <TextField source="gender" label="User gender" />
        <TextField source="ip" label="IP Address" />
        <JsonField source="headers" />
        <BooleanField source="isAdmin" label="Logged by admin" />
        <DateField source="createdAt" label="Login try at" showTime />
      </Grid>
    </GridList>
  );
};

const partnerOptions = [
  { id: 'Admin', name: 'Admin' },
  { id: 'User', name: 'User' },
];

const genderOptions = [
  { id: 'Hombre', name: 'Hombre' },
  { id: 'Mujer', name: 'Mujer' },
  { id: 'Prefiero no decir', name: 'Prefiero no decir' },
];

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <SelectInput source="partnerClass"
    key="partnerClass"
    choices={partnerOptions}
    label="Source"
    alwaysOn />,
  <NullableBooleanInput source="isSucceed"
    key="isSucceed"
    label="Is succeed"
    style={{ width: 120 }}
    alwaysOn />,
  <NullableBooleanInput
    source="isAdmin"
    key="isAdmin"
    label="Logged by admin"
    style={{ width: 240 }}
    alwaysOn
    defaultValue={false}
  />,
  <SelectInput
    source="gender"
    key="gender"
    choices={genderOptions}
    label={
      <div>
        User gender
        <Tooltip title="This filter is hide the Admin users">
          <span className={cls.helpIcon}>
            <QuestionCircleOutlined />
          </span>
        </Tooltip>
      </div>
    }
  />,
  <DateRangeInput
    key="createdAt"
    source="createdAt"
    label="Period"
    defaultValue={false}
  />,
];
