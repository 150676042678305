import { PropsWithChildren, useRef } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

interface Props {
  height?: number;
  paddingRight?: number;
  paddingLeft?: number;
  scrollBottom?: boolean;
}

export const Scroll = ({
  children, height, paddingRight, paddingLeft, scrollBottom,
}: PropsWithChildren<Props>) => {
  const scrollRef = useRef<Scrollbar>();

  return (
    <Scrollbar style={{ height }}
      ref={(ref: any) => scrollRef.current = ref}
      disableTracksWidthCompensation
      onUpdate={(scrollValues, prevScrollState) => {
        if (scrollBottom && scrollValues.scrollHeight > prevScrollState.scrollHeight) {
          scrollRef.current?.scrollToBottom();
        }
      }}>
      <div style={{ paddingRight, paddingLeft }}>
        {children}
      </div>
    </Scrollbar>
  );
};
