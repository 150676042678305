import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Input, Alert, Select } from 'antd';

import { Chat } from '@types';
import { BlueButton, OrangeOutlineButton, Scroll } from '@components';
import { chatHttp } from '@network';
import { useRequest } from '@hooks';
import { useSettingsContext } from '@providers';
import { ChatMessages } from './chat-messages';
import { ChatTeams } from './chat-teams';
import { UserIcon } from './user-icon';

interface Props {
  creditId: number;
  height?: number;
  isPopup?: boolean;
  closeChat?: () => void;
  readonly?: boolean;
}

export const CreditChat = (props: Props) => {
  const height = props.height || 228;
  const readonly = !!props.readonly;
  const cls = useStyles();
  const { admin, lastMessage, refreshUnreaded, websocketConnect } = useSettingsContext();
  const [chat, setChat] = useState<Chat>();
  const [message, setMessage] = useState('');
  const messageRef = useRef<any>();
  const adminsRef = useRef<any>();
  const [adminsOpen, setAdminsOpen] = useState(false);

  const messages = chat?.messages || [];
  const admins = Object.values(chat?.managers?.adminsById || {});

  useEffect(() => {
    const lastChar = message[message.length - 1];
    if (lastChar === '@') {
      setAdminsOpen(true);
      setTimeout(() => void adminsRef.current?.focus(), 100);
    }
  }, [message]);

  // upload chat by credit ID
  useEffect(() => {
    (async () => {
      const newChat = await chatHttp.makeCreditChat(props.creditId);
      setChat(newChat);
    })();
    websocketConnect();
  }, [props.creditId]);

  // new message to chat makes request to read this chat
  useEffect(() => {
    if (chat && chat.messages.length > 0) {
      (async () => {
        await chatHttp.readChat(chat.id);
        await refreshUnreaded();
      })();
    }
  }, [chat]);

  // other admin manager posted a message to this chat
  useEffect(() => {
    if (chat && lastMessage && admin
      && lastMessage.chatId === chat.id
      && lastMessage.adminId !== admin.id) {
      setChat({ ...chat, messages: [...chat.messages, lastMessage] });
    }
  }, [lastMessage]);

  // post message
  const { loading, submit: postMessage, error } = useRequest(async () => {
    if (readonly) return;
    if (!message || !chat) {
      return messageRef.current?.focus();
    }
    const newMessage = await chatHttp.postMessage({ chatId: chat.id, message });
    setMessage('');

    // update Chat
    chat.messages = [...chat.messages, newMessage];
    chat.managers = newMessage.managers;
    setChat(chat);
  });

  // message box cursor
  const getCursorPos = (): number | undefined =>
    messageRef.current?.resizableTextArea?.textArea?.selectionStart;

  // on select chosen after mention "@" typed
  const onMention = (adminId: string) => {
    if (readonly) return;
    setAdminsOpen(false);
    const admin = admins.find((admin) => admin.id === +adminId);
    const cursorPos = getCursorPos();

    if (cursorPos !== undefined && admin) {
      const nextMessage = [
        message.slice(0, cursorPos),
        admin.name,
        message.slice(cursorPos),
      ].join('');
      setMessage(nextMessage);
      messageRef.current?.focus();
    }
  };

  // open popup select
  const onMessageKey = (e: any) => {
    if (readonly) return;
    if (e.key === '@') {
      setAdminsOpen(true);
      setTimeout(() => void adminsRef.current?.focus(), 100);
    } else if (adminsOpen) {
      setAdminsOpen(false);
    }
  };

  const myName = admin?.name || '';
  const invitedTeam = chat?.managers?.invited || [];
  const showLeaveBtn = invitedTeam.includes(myName);

  // leave chat
  const { loading: leaveLoading, submit: leaveChat } = useRequest(async () => {
    if (readonly) return;
    if (chat) {
      const chatManagers = await chatHttp.leaveChat(chat.id);
      await refreshUnreaded();
      // update Chat
      chat.managers = chatManagers;
      setChat(chat);

      props.closeChat && props.closeChat();
    }
  });

  return (
    <div className={cls.box}>
      {chat && <ChatTeams isPopup={props.isPopup} chat={chat} />}
      <div className={cls.messages}>
        <Scroll height={height} scrollBottom>
          <ChatMessages managers={chat?.managers} messages={messages} adminId={admin?.id} />
        </Scroll>
      </div>
      <div className={cls.errorBox}>
        {error && <Alert type="error" icon message={error} />}
      </div>

      <Select
        ref={adminsRef}
        value={null}
        style={{ width: '100%', visibility: adminsOpen ? 'visible' : 'hidden' }}
        onChange={onMention}
        showAction={['focus']}
        showSearch
        optionFilterProp="children"
        filterSort={(a, b) => `${a.label}`.localeCompare(`${b.label}`)}
        filterOption={(input, option) =>
          `${option?.label}`.toLowerCase().includes(input.toLowerCase())}
        onDropdownVisibleChange={open => !open && setAdminsOpen(false)}
      >
        {admins.map((admin) => (
          <Select.Option value={admin.id} label={`${admin.name} ${admin.email}`} key={admin.id}>
            <div className={cls.mentionOption}>
              <div className={cls.mentionIcon}><UserIcon /></div>
              <div className={cls.mentionName}>
                <span>{admin.name}</span>
                {admin.email && <span className={cls.mentionEmail}>{admin.email}</span>}
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>

      <Input.TextArea
        ref={messageRef}
        value={message}
        onChange={e => setMessage(e.target.value)}
        placeholder="Write a comment"
        className={cls.messageText}
        status={error ? 'error' : undefined}
        maxLength={520}
        onPressEnter={postMessage}
        onKeyPress={onMessageKey}
        onBeforeInputCapture={readonly ? e => e.preventDefault() : undefined}
      />

      <div className={cls.controls}>
        <BlueButton onClick={postMessage} extraPadding
          className={cls.messageBtn} disabled={loading || readonly}>
          Add comment
        </BlueButton>
        {showLeaveBtn && (
          <OrangeOutlineButton onClick={leaveChat}
            className={cls.leaveBtn} disabled={leaveLoading || readonly}>
            Leave conversation
          </OrangeOutlineButton>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
    position: 'relative',
  },
  messages: {
    borderRadius: 8,
    border: '1px solid #002A77',
    background: '#FFF',
    padding: '0 15px',
    paddingRight: 10,
    overflow: 'hidden',
  },
  mentionOption: {
    display: 'flex',
    alignItems: 'center',
  },
  mentionIcon: {
    paddingLeft: 2,
    paddingTop: 3,
  },
  mentionName: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: 15,
  },
  mentionEmail: {
    marginLeft: 20,
    fontSize: 12,
    color: 'steelblue',
  },
  errorBox: {
    height: 15,
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  messageText: {
    borderRadius: 8,
    border: '1px solid #002A77',
    backgroundColor: '#EEE',
    height: '80px !important',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  messageBtn: {
    marginTop: 15,
    marginRight: 15,
  },
  leaveBtn: {
    marginTop: 15,
    marginRight: 15,
    textTransform: 'none',
    borderRadius: 8,
    paddingLeft: 30,
    paddingRight: 30,
  },
});
