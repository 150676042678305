import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import moment from 'moment-timezone';
import { useNotify } from 'react-admin';
import SaveIcon from '@mui/icons-material/Save';
import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import {
  AutoComplete,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  UploadFile,
} from 'antd';

import { financeDepositHttp, s3http } from '@network';
import { moneyFormatter, showAxiosError } from '@utils';
import {
  CreateFinanceDepositParams,
  FinanceDeposit,
  FinanceDepositAccountOption,
  FinanceDepositOptions,
  FinanceDocument,
} from '@types';
import { FinanceDocuments } from '@pages/finance-withdrawal/FinanceDocuments';
import cls from './finance-deposit-form.module.css';
import { InfoTooltip } from '@components';

interface Props {
  financeDeposit?: FinanceDeposit | null;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  clientName: string;
  setClientName: Dispatch<SetStateAction<string>>;
  submitted: boolean;
  setSubmitted: Dispatch<SetStateAction<boolean>>;
  onDelete?: () => void;
  options: FinanceDepositOptions;
  initial: CreateFinanceDepositParams;
  onSubmit: (params: CreateFinanceDepositParams) => void;
  form: FormInstance<CreateFinanceDepositParams>;
}

interface OptionProps {
  key: number;
  value: string;
  label: JSX.Element;
}

export const FinanceDepositForm = ({
  financeDeposit,
  loading,
  setLoading,
  onDelete,
  onSubmit,
  options,
  initial,
  form,
  clientName,
  setClientName,
  submitted,
  setSubmitted,
}: Props) => {
  const hasId = !!financeDeposit?.id;
  const isAirtable = !!financeDeposit?.airtableId;
  const [receipts, setReceipts] = useState<FinanceDocument[]>(financeDeposit?.receipts || []);
  const [invoices, setInvoices] = useState<FinanceDocument[]>(financeDeposit?.invoices || []);
  const usedFiles = useRef<Record<string, boolean>>({});
  const notify = useNotify();
  const labelCol = { xs: 24, sm: 24, md: 8, lg: 6, xl: 8, xxl: 6 };
  const [account, setAccount] = useState<FinanceDepositAccountOption | null>(null);
  const [accountsList, setAccountsList] = useState<OptionProps[]>([]);
  const [creditsList, setCreditsList] = useState<OptionProps[]>([]);
  const [date, setDate] = useState<string>(initial.paymentDate);

  const readonly = false;

  useEffect(() => {
    if (financeDeposit) {
      setReceipts(financeDeposit.receipts);
      setInvoices(financeDeposit.invoices);
    }
  }, [financeDeposit]);

  const renderOption = (
    {
      idx,
      code,
      disabled,
      balance,
      showAmount,
    }: {idx: number; code: string; disabled?: boolean; balance?: number; showAmount?: boolean},
  ): OptionProps => ({
    key: idx,
    value: disabled
      ? ''
      : showAmount
        ? `${code} – ${moneyFormatter.format(balance || 0)}`
        : code,
    label: (
      <span className={clsx(cls.option, disabled && cls.disabledOption)}>
        {showAmount ? `${code} – ${moneyFormatter.format(balance || 0)}` : code}
      </span>
    ),
  });

  const onAccountSearch = (searchText: string) => {
    setAccountsList(
      !searchText
        ? options.accounts.map((a, idx) =>
          renderOption({ idx, code: a.code, balance: a.balance }),
        )
        : options.accounts
          .filter(a => a.code.toLowerCase().includes(searchText.toLowerCase()))
          .map((a, idx) =>
            renderOption({ idx, code: a.code, balance: a.balance })),
    );
  };

  const onAccountSelect = (data: string) => {
    const selected = options.accounts.find(a => a.code === data);
    setAccount(selected ? selected : null);
    form.setFieldValue('accountId', selected?.id || null);
  };

  const onAccountChange = (data: string) => {
    !data && onAccountClear();
  };

  const onAccountClear = () => {
    form.setFieldValue('accountId', null);
    setAccount(null);
    setAccountsList(options.accounts.map((a, idx) =>
      renderOption({ idx, code: a.code, balance: a.balance })),
    );
  };

  const onCreditSearch = (searchText: string) => {
    setCreditsList(
      !searchText
        ? options.credits.map((a, idx) =>
          renderOption({ idx, code: a.code, balance: a.balance, showAmount: true }))
        : options.credits
          .filter(a => a.code.toLowerCase().includes(searchText.toLowerCase()))
          .map((a, idx) =>
            renderOption({ idx, code: a.code, balance: a.balance, showAmount: true })));
  };

  const onCreditSelect = (data: string) => {
    const [code] = data.split('–');
    const selected = options.credits.find(a => a.code === code.trim());
    setClientName(selected?.accredited || '');
    form.setFieldValue('creditId', selected?.id || null);
  };

  const onCreditClear = () => {
    form.setFieldValue('creditId', null);
    setCreditsList(options.credits.map((a, idx) =>
      renderOption({ idx, code: a.code, balance: a.balance, showAmount: true })),
    );
    setClientName('');
  };

  const onCreditChange = (data: string) => {
    !data && onCreditClear();
  };

  const onDateChange = async (dt: moment.Moment | null) => {
    setDate(moment(dt).format('DD-MM-YYYY'));
  };

  const submit = async () => {
    if (readonly) {
      return notify('Read only permission');
    }
    setSubmitted(true);
    try {
      setLoading(true);
      await form.validateFields();
      const params = form.getFieldsValue();
      params.paymentDate = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      onSubmit(params);
    } catch (e) {
      // @ts-ignore
      if (e.errorFields?.length) {
        notify('Please fill in all required fields of the form', { type: 'error' });
      }
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAccountsList(options.accounts.map((a, idx) =>
      renderOption({ idx, code: a.code, balance: a.balance })),
    );
    setCreditsList(options.credits.map((a, idx) =>
      renderOption({ idx, code: a.code, balance: a.balance, showAmount: true })),
    );
  }, [options]);

  useEffect(() => {
    if (initial.accountId) {
      const item = options.accounts.find(a => a.id === initial.accountId);
      item && setAccount(item);
    }
  }, []);

  const onUpload = (type: 'invoice' | 'receipt') => async (uploadFiles: UploadFile[]) => {
    if (!financeDeposit) {
      return notify('Please save Deposit first!');
    }
    if (readonly) {
      return notify('Only Superadmins are allowed to Upload Invoice/Receipt document');
    }
    // check unique
    const files: UploadFile[] = [];
    uploadFiles.forEach(file => {
      const uid = file.uid;
      if (!usedFiles.current[uid]) {
        usedFiles.current[uid] = true;
        files.push(file);
      }
    });
    if (files.length === 0) {
      return;
    }

    // upload files
    try {
      const s3files = await Promise.all(
        files.map(file => s3http.uploadFinanceFile(file.originFileObj as File)),
      );
      const updatedDocuments = await financeDepositHttp.addDocument({
        id: financeDeposit.id,
        fileIds: s3files.map(file => file.id),
        type,
      });
      type === 'invoice' ? setInvoices(updatedDocuments) : setReceipts(updatedDocuments);
    } catch (err: any) {
      console.error(err);
      showAxiosError(err);
    } finally {
      setLoading(false);
    }
  };

  const onRemove = (type: 'invoice' | 'receipt') => async (documentId: number) => {
    if (!financeDeposit) {
      return notify('Please save Deposit first!');
    }
    try {
      const updatedDocuments = await financeDepositHttp.removeDocument({
        id: financeDeposit.id,
        documentId,
      });
      type === 'invoice' ? setInvoices(updatedDocuments) : setReceipts(updatedDocuments);
    } catch (err: any) {
      console.error(err);
      showAxiosError(err);
    }
  };

  const initAccount = options.accounts.find(a => a.id === form.getFieldValue('accountId'));
  const initCredit = options.credits.find(c => c.id === form.getFieldValue('creditId'));

  return (
    <div className={cls.formWrap}>
      <Row gutter={{ xl: 80, xxl: 120 }} className={cls.infoWrapper}>
        <Col xs={24} xl={12} className={cls.infoCol}>
          <div className={cls.fieldRow}>
            <div className={cls.fieldCol}>
              <Form.Item
                label="Concept*"
                name="concept"
                className={clsx(cls.formItem, cls.formItemHorizontal, readonly && cls.disabled)}
                labelCol={{ xs: 24, sm: 8, lg: 6, xl: 8, xxl: 6 }}
                wrapperCol={{ xs: 24, sm: 16, lg: 12, xl: 7, xxl: 6 }}
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  name="concept"
                  className={cls.selectField}
                  disabled={readonly}>
                  {options.concept.map(value =>
                    <MenuItem key={value} value={value}>{value}</MenuItem>,
                  )}
                </Select>
              </Form.Item>
            </div>
            <div className={cls.fieldCol}>
              <div className={cls.conceptValue}>
                <span className={cls.conceptValueMonth}>
                  {financeDeposit?.conceptMonth || options.conceptMonth}
                </span>
                <span className={cls.conceptValueSplitter}>-</span>
                <span className={cls.conceptValueNumber}>
                  {financeDeposit?.conceptNumber || options.conceptNumber}
                </span>
              </div>
            </div>
          </div>
          <Form.Item
            label="Financial I"
            name="accountId"
            className={clsx(cls.formItem, readonly && cls.disabled)}
            labelCol={labelCol}
          >
            <>
              <InfoTooltip title="Finance Accounts with no User attached" formItem />
              <AutoComplete
                defaultValue={initAccount?.code}
                options={accountsList}
                disabled={readonly}
                className={clsx(
                  cls.selectField,
                  cls.autocompleteField,
                )}
                popupClassName={cls.popup}
                notFoundContent="Code is not found"
                allowClear
                onSelect={onAccountSelect}
                onSearch={onAccountSearch}
                onChange={onAccountChange}
                onClear={onAccountClear}
                placeholder="Search Account code"
              />
            </>
          </Form.Item>
          <Form.Item
            label="Credit code"
            name="creditId"
            className={clsx(cls.formItem, readonly && cls.disabled)}
            labelCol={labelCol}
          >
            <>
              <InfoTooltip title="Credits with User which has Finance Account attached" formItem />
              <AutoComplete
                defaultValue={initCredit
                  ? `${initCredit.code} – ${moneyFormatter.format(initCredit.balance || 0)}`
                  : null}
                options={creditsList.map(option => {
                  const [label] = option.label?.props?.children?.split('–') || [];

                  return ({
                    value: option.value,
                    label,
                  });
                })}
                disabled={readonly}
                className={clsx(
                  cls.selectField,
                  cls.autocompleteField,
                )}
                popupClassName={cls.popup}
                notFoundContent="Code is not found"
                allowClear
                onSelect={onCreditSelect}
                onSearch={onCreditSearch}
                onChange={onCreditChange}
                onClear={onCreditClear}
                placeholder="Search User code"
              />
            </>
          </Form.Item>
          <Form.Item
            label="Amount*"
            name="amount"
            className={clsx(cls.formItem, readonly && cls.disabled)}
            labelCol={labelCol}
            rules={[
              { required: true, message: '' },
              { type: 'number', message: 'Only numbers are allowed' },
              () => ({
                validator(_, value) {
                  if (value === 0 || value === undefined) {
                    return Promise.reject(new Error(''));
                  }
                  if (value > 999999999) {
                    return Promise.reject(new Error('Max amount is $999,999,999'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              disabled={readonly}
              formatter={(val) => `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(val) => {
                return val ? +val.replace(/\$\s?|(,*)/g, '') : 0.01;
              }}
              bordered={false}
              className={clsx(cls.inputField, cls.moneyField)}
              controls={false}
            />
          </Form.Item>

          <div className={cls.customInputRow}>
            <div className={clsx(
              cls.customInputLabelCol,
              readonly && cls.disabled,
            )}>Payment date*:
            </div>
            <div className={cls.customInputValueCol}>
              <DatePicker
                disabled={readonly}
                format="DD-MM-YYYY"
                placeholder="DD-MM-YYYY"
                onChange={onDateChange}
                defaultValue={moment(date, 'DD-MM-YYYY')}
                className={cls.dateInput}
                bordered={false}
                allowClear={false}
              />
            </div>
          </div>
          <div className={cls.customInputRow}>
            <div className={clsx(cls.customInputLabelCol, cls.disabled)}>Account:</div>
            <div className={cls.customInputValueCol}>
              <Input bordered={false}
                className={cls.inputField}
                disabled
                value={account?.account || ''} />
            </div>
          </div>
          <div className={cls.customInputRow}>
            <div className={clsx(cls.customInputLabelCol, cls.disabled)}>Accredited:</div>
            <div className={cls.customInputValueCol}>
              <Input bordered={false} className={cls.inputField} disabled value={clientName} />
            </div>
          </div>
          <Form.Item
            label="Purpose*"
            name="purpose"
            style={{ marginTop: 22 }}
            className={clsx(cls.formItem, cls.purposeField, readonly && cls.disabled)}
            labelCol={labelCol}
            rules={[{ required: true, message: '' }]}
          >
            <Select name="purpose" className={cls.selectField} disabled={readonly}>
              {options.purpose.map(value =>
                <MenuItem key={value} value={value}>{value}</MenuItem>,
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Details"
            name="details"
            className={clsx(cls.formItem, readonly && cls.disabled)}
            labelCol={labelCol}
            rules={isAirtable ? [] : [
              { max: 40, message: 'Details should be less then 40 characters' },
            ]}
          >
            <Input bordered={false} className={cls.inputField} disabled={readonly} />
          </Form.Item>
          <Form.Item
            name="received"
            valuePropName="checked"
            className={clsx(cls.formItem, cls.checkboxItem)}
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox
              className={clsx(cls.checkboxField, !hasId && cls.disabled)}
              disabled={!hasId}
            >Received</Checkbox>
          </Form.Item>
        </Col>

        <Col xs={24} xl={12} className={cls.infoCol}>
          <div className={cls.fileFieldWrap}>
            <div className={cls.fileFieldLabel}>Receipts</div>
            <div className={cls.fileField}>
              <FinanceDocuments
                code={financeDeposit?.code}
                documents={financeDeposit ? receipts : undefined}
                onUpload={onUpload('receipt')}
                onRemove={onRemove('receipt')}
                canSave={!!financeDeposit}
              />
            </div>
          </div>
          <div className={cls.fileFieldWrap}>
            <div className={cls.fileFieldLabel}>Invoices</div>
            <div className={cls.fileField}>
              <FinanceDocuments
                code={financeDeposit?.code}
                documents={financeDeposit ? invoices : undefined}
                onUpload={onUpload('invoice')}
                onRemove={onRemove('invoice')}
                canSave={!!financeDeposit}
              />
            </div>
          </div>
        </Col>
      </Row>

      <div className={cls.actions}>
        <Button
          variant="contained"
          color="secondary"
          className={cls.save}
          disabled={loading}
          onClick={submit}
        >
          <SaveIcon /> Save
        </Button>
        {readonly && onDelete ? (
          <Button variant="contained" className={cls.delete} onClick={onDelete}>Delete</Button>
        ) : null}
      </div>
    </div>
  );
};
