import { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';

import { http } from '@network';

interface SelectOption {
  key: string,
  label: string,
}

interface Props {
  source: string;
  reference?: string;
  optionKey?: string;
  optionLabel?: string;
  noload?: boolean;
  label?: string;
  sortable?: boolean;
}

export const ManyField = ({ source, reference, optionKey, optionLabel, noload, label }: Props) => {
  const classes = useStyles();
  const record = useRecordContext();
  const data: any = record[source];
  const isObject = Array.isArray(data) && (data[0])?.id;
  const isIds = !isObject || (Array.isArray(data) && Number.isInteger(data[0]));

  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (isIds && !noload) {
      (async () => {
        const response = await http.get<any>(reference || source);
        const nextOptions: SelectOption[] = [];
        if (response.data && Array.isArray(response.data)) {
          (response.data ?? []).forEach((model: any) => {
            nextOptions.push({
              key: model[optionKey ?? 'id'],
              label: label ? label : model[optionLabel ?? 'nameMx'] || model['name'] || model['title'],
            });
          });
        }
        setOptions(nextOptions);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isObject) {
    return (
      <div className={classes.chips}>
        {data && data.map((model: any) => (
          <div key={model.id}>
            <Chip
              label={model['nameMx'] || model['name'] || model['title']}
              className={classes.chip}
            />
          </div>
        ))}
      </div>
    );
  }

  return record ? (
    <div className={classes.chips}>
      {data && (data as number[]).map(key => (
        <div key={key}>
          <Chip
            label={options.find(o => o.key.toString() === key.toString())?.label}
            className={classes.chip}
          />
        </div>
      ))}
    </div>
  ) : null;
};

const useStyles = makeStyles({
  chips: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 3,
  },
});
