import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { clsx } from 'clsx';

import cls from './input-select.module.css';

interface InputSelectProps {
  name: string;
  label: string;
  selectedValue: string | string[] | null;
  values: string[];
  onChange: (event: SelectChangeEvent<string | null>) => void;
  labelWidth?: number;
  error?: string;
  className?: string;
  centered?: boolean;
  multiple?: boolean;
  displayEmpty?: boolean;
}

export const InputSelect = ({
  label,
  selectedValue,
  values,
  onChange,
  name,
  labelWidth,
  error,
  className,
  centered,
  multiple,
  displayEmpty,
}: InputSelectProps) => {
  return (
    <div className={cls._}>
      <label
        className={cls.label}
        style={{ minWidth: labelWidth ? labelWidth : 100 }}
      >
        {label}
      </label>
      <FormControl
        variant="standard"
        className={clsx(cls.form, className, centered && cls.centered)}
      >
        <Select
          name={name}
          value={selectedValue as any}
          onChange={onChange}
          multiple={multiple}
          displayEmpty={displayEmpty}
        >
          {displayEmpty && <MenuItem key={''} value={''}>&nbsp;</MenuItem>}
          {values.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
        </Select>

        {error && <span className={cls.error}>{error}</span>}
      </FormControl>
    </div>
  );
};
