import { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';

import { Credit } from '@types';
import { InitialPage, Loader } from '@components';
import { creditHttp } from '@network';
import { axiosErrorToString } from '@utils';
import { Alert } from '@mui/material';

export const CreditRiskRedirect = ({ credit }: {credit: Credit}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [error, setError] = useState<string>();

  useEffect(() => {
    (async () => {
      try {
        const risk = await creditHttp.getRiskUpdateStatus(credit.id);
        setError(undefined);
        if (risk) {
          redirect(`/credit-risk/${risk.id}`);
        }
      } catch (e) {
        const errMessage = axiosErrorToString(e);
        notify(errMessage);
        setError(errMessage);
      }
    })();
  }, [credit.id]);

  const backTo = `/credit/${credit.id}?report`;

  return (
    <InitialPage title="Risk summary" backTo={backTo} backText="Back to Send to Analysis">
      {error && <Alert>{error}</Alert>}
      {!error && <Loader />}
    </InitialPage>
  );
};
