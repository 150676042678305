import { BlueOutlineButton } from '@components';
import cls from './DownloadBlock.module.css';
import { Badge } from 'antd';

interface Props {
  title: string;
  onClick: () => void;
  isLoading: boolean;
  counter?: number;
}

export const DownloadBlock = ({ title, onClick, isLoading, counter }: Props) => {
  return (
    <div className={cls.block}>
      <div className={cls.title}>{title}</div>
      <div className={cls.actions}>
        <BlueOutlineButton
          className={cls.action}
          onClick={onClick}
          disabled={isLoading || counter === 0}
        >
          {counter !== undefined
            ? <Badge count={counter} showZero color="#042E6B">
              {isLoading ? 'Loading' : 'Download File'}
            </Badge>
            : title}
        </BlueOutlineButton>
      </div>
    </div>
  );
};
