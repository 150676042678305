export const exportToCsv = (data: any[], type: string) => {
  let preparedData: any[];
  switch (type) {
    case 'credits':
      preparedData = data.map(item => ({
        'Credit Code': item.code,
        'Accredited': item.fullName,
        'Credit': item.bank_approval,
        'Status': item.status,
      }));
      break;

    case 'leads':
      preparedData = data.map(item => ({
        'Name': item.name,
        'Stage': item.stage || 'unknown',
        'Phone': item.phone,
      }));
      break;

    case 'users':
      preparedData = data.map(item => ({
        'Name': item.fullName,
        'User Code': item.code,
        'Status': item.status,
      }));
      break;

    case 'associates':
      preparedData = data.map(item => ({
        'Name': item.name,
        'ID': item.id,
        'Phone': item.phone,
      }));
      break;

    case 'retailers':
      preparedData = data.map(item => ({
        'Name': item.name,
        'ID': item.id,
      }));
      break;

    case 'buyers':
      preparedData = data.map(item => ({
        'Name': item.name,
        'ID': item.id,
        'Tier': item.tier,
      }));
      break;

    default:
      preparedData = data;
      break;
  }

  const csvRows = [];

  const headers = Object.keys(preparedData[0]);
  csvRows.push(headers.join(','));

  for (const row of preparedData) {
    const values = headers.map(header => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"');

      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }

  const csvString = csvRows.join('\n');

  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${type}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
