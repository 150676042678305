import React, { useEffect, useState } from 'react';
import { Button, Toolbar } from '@mui/material';
import {
  SaveButton,
  DeleteButton,
  Link,
  SimpleForm,
} from 'react-admin';
import { Row, Col } from 'antd';
import { makeStyles } from '@mui/styles';
import { RcFile } from 'antd/lib/upload';
import { useController, useWatch } from 'react-hook-form';

import {
  BlueOutlineButton,
  DateInput,
  FileControlled,
  FileUpload,
  InitialCard,
  InitialPage,
  InitialRow,
  ReferenceInput,
  Scroll,
  SelectInput,
  TextAreaInput,
} from '@components';
import {
  CreditCall,
  CreditCallHistory,
  CreditFarmFile,
  DocumentFile,
} from '@types';
import { s3http } from '@network';
import { dateFormatter, showAxiosError } from '@utils';
import { CallRiskField, callRiskOptions } from '@pages/credit-call/CallRiskField';
import { CallStatusField, callStatusOptions } from '@pages/credit-call/CallStatusField';
import './call-widget.css';

interface CallWidgetProps {
  creditId?: number;
  call?: CreditCall;
  editable: boolean;
}

export const CallWidget = ({ creditId, call, editable }: CallWidgetProps) => {
  const cls = useStyles();
  const backTo = creditId ? `/credit/${creditId}?calls` : '/credit-call';
  const backText = creditId ? 'Back to task by credit' : 'All Tasks';

  const EditActions = () => {
    return editable ? (
      <Toolbar className={cls.toolbar}>
        <SaveButton label="Save" type="button" />
        <Link to={backTo}>
          <Button variant="outlined" color="secondary" className={cls.returnBtn}>
            Return to task by user
          </Button>
        </Link>
        <DeleteButton />
      </Toolbar>
    ) : null;
  };

  return (
    <InitialPage title="Individual task" backTo={backTo} backText={backText} gap={20}>
      <InitialCard extraPadding contentClass={cls.content}>
        <SimpleForm toolbar={<EditActions />}>
          <Row gutter={[20, 20]} className={cls.form}>
            <Col sm={24} md={14}>
              <div className={cls.info}>
                <div className={cls.infoTitle}>{call?.title}</div>
                <CallForm call={call} creditId={creditId} />
              </div>
            </Col>
            <Col sm={24} md={10}>
              <div className={cls.history}>
                <div className={cls.title}>History</div>
                <CallHistory history={call?.history || []} />
              </div>
            </Col>
          </Row>

          <Row gutter={[20, 20]} style={{ width: '100%', marginTop: 20 }}>
            <Col sm={24} md={12}>
              <div className={cls.title}>Observation</div>
              <TextAreaInput
                source="observation"
                placeholder="Here, the responsible manager will add any observation made during call"
                className={cls.textarea}
              />
            </Col>
            <Col sm={24} md={12}>
              <div className={cls.title}>Satellite Picture</div>
              <CallPicture picture={call?.picture} />
            </Col>
          </Row>
        </SimpleForm>
      </InitialCard>
    </InitialPage>
  );
};

const CallForm = ({ call, creditId }: {call?: CreditCall; creditId?: number}) => {
  const [withDate, setWithDate] = useState(false);

  const cls = useStyles();
  const callStatus = useWatch({ name: 'status' });
  const callRisk = useWatch({ name: 'risk' });
  const {
    field: { onChange: setCreditId },
  } = useController({ name: 'creditId' });
  const {
    field: { value: contactAt, onChange: setContactAt },
  } = useController({ name: 'contactAt' });

  useEffect(() => {
    if (!call && creditId) {
      setCreditId(creditId);
    }

    if (call) {
      setWithDate(!!call.contactAt);
    }
  }, [creditId, call]);

  return (
    <div id="CallForm">
      {!call ? (
        <InitialRow title="Credit:">
          <ReferenceInput
            source="creditId"
            reference="credit"
            sort={{ field: 'code', order: 'ASC' }}
            perPage={999999}
            isRequired
          >
            <SelectInput optionText="code" label="" required />
          </ReferenceInput>
        </InitialRow>
      ) : null}
      <InitialRow title="Task admin:">
        <ReferenceInput
          source="responsibleAdminId"
          reference="admin/task-admin"
          isRequired
        >
          <SelectInput optionText="name" label="" required />
        </ReferenceInput>
      </InitialRow>
      <Row className={cls.manualFormRow}>
        <Col xs={24} xxl={10}>
          <InitialRow title="Contact by:">
            {(!call || call?.isManual) ? (
              <DateInput source="contactBy" label="" />
            ) : (
              <span>{call.contactBy && dateFormatter.toDateSlash(call.contactBy)}</span>
            )}
          </InitialRow>
          <InitialRow title="Contact at:">
            {(!call || call?.isManual) ? (
              <>
                {(withDate || contactAt) ? (
                  <>
                    <DateInput source="contactAt" label="" />
                    <span className={cls.clearDate} onClick={() => {
                      setContactAt(null);
                      setWithDate(false);
                    }}>+</span>
                  </>
                ) : (
                  <Button className={cls.setDate} onClick={() => setWithDate(true)}>
                    Set date
                  </Button>
                )}
              </>
            ) : (
              <span>{call.contactAt && dateFormatter.toDateSlash(call.contactAt)}</span>
            )}
          </InitialRow>
        </Col>
        <Col xs={24} xxl={2}/>
        <Col xs={24} xxl={12} className="CallFormSelects">
          <InitialRow title="Call status:">
            <SelectInput source="status"
              choices={callStatusOptions}
              required
              label="" />
            <div className="CallFormBadge">
              <CallStatusField source="risk" badgeMode callStatus={callStatus} />
            </div>
          </InitialRow>
          <InitialRow title="Risk:">
            <SelectInput source="risk" choices={callRiskOptions} required label="" />
            <div className="CallFormBadge">
              <CallRiskField source="risk" badgeMode callRisk={callRisk} />
            </div>
          </InitialRow>
        </Col>
      </Row>
    </div>
  );
};

const CallHistory = (props: {history: CreditCallHistory[]}) => {
  const cls = useStyles();
  const [showAll, setShowAll] = useState(false);
  const initialMaxRecords = 2;
  const allItems = (props.history || []).sort((a, b) => b.id - a.id);
  const showItems = allItems.slice(0, showAll ? undefined : initialMaxRecords);
  const showMoreBtn = !showAll && allItems.length > showItems.length;

  return (
    <Scroll height={showAll ? 150 : showItems.length * 75}>
      {showItems.map(history => (
        <div className={cls.item} key={history.id}>
          <div className={cls.header}>
            <div className={cls.by}>by {history.adminName}</div>
            <div>{dateFormatter.toDateTime(history.createdAt)}</div>
          </div>
          <div className={cls.footer}>
            <div>{history.title}</div>
            {(history.statusBefore || history.statusAfter) ? (
              <div className={cls.status}>
                {(history.statusBefore || 'null').slice(0, 20)}
                <span style={{ margin: '0 4px 0' }}>→</span>
                {(history.statusAfter || 'null').slice(0, 20)}
              </div>
            ) : null}
          </div>
        </div>
      ))}
      {showMoreBtn ? (
        <BlueOutlineButton onClick={() => setShowAll(true)} className={cls.showMoreBtn}>
          Show more ({allItems.length - showItems.length})
        </BlueOutlineButton>
      ) : null}
    </Scroll>
  );
};

const CallPicture = (props: {picture?: DocumentFile | null}) => {
  const cls = useStyles();
  const [picture, setPicture] = useState<CreditFarmFile | null>(props.picture || null);
  const [loading, setLoading] = useState(false);
  const {
    field: { value: pictureId, onChange: changePictureId },
  } = useController({ name: 'pictureId' });

  const uploadPicture = async (rcFile: RcFile) => {
    setLoading(true);

    try {
      const file = await s3http.uploadFile(rcFile as File);
      setPicture(file);
      changePictureId(file.id);
      return file.adminUrl;
    } catch (err: any) {
      showAxiosError(err);

      return '';
    } finally {
      setLoading(false);
    }
  };

  const removePicture = async () => {
    setPicture(null);
    changePictureId(null);
    console.log('...remove');
  };

  return (
    <>
      {picture ? (
        <FileControlled
          adminUrl={picture.adminUrl}
          downloadFilename={'Satellite Picture.jpg'}
          onDelete={removePicture}
          wrapClass={cls.fileWrap}
          zoom
          fullWidth
          height={200}
        />
      ) : (
        <FileUpload uploadAction={uploadPicture} className={cls.uploadBox} type="image" />
      )}
    </>
  );
};

const useStyles = makeStyles({
  content: {
    paddingTop: 10,
    fontFamily: 'Outfit, sans-serif',
    color: '#000',

    '& .MuiCardContent-root': {
      marginTop: '0 !important',
    },
  },
  info: {
    padding: '18px 30px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
    fontFamily: 'Outfit, sans-serif',
    color: '#000',
  },
  infoTitle: {
    fontSize: 22,
    fontWeight: 600,
  },
  form: {
    width: '100% !important',
  },
  history: {
    marginRight: -20,
    padding: '5px 30px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: 'white',
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 5,
  },
  textarea: {
    borderRadius: 8,
    border: '1px solid #002A77',
    height: '200px !important',
    padding: '8px 12px',
    backgroundColor: '#E1E5ED',
    fontSize: 12,
    lineHeight: 1.1,

    '> div::before': {
      display: 'none',
    },

    '&::placeholder': {
      color: '#74706B',
      fontSize: 12,
      lineHeight: 1.1,
    },
  },
  uploadBox: {
    height: 200,
    width: '100%',
  },
  fileWrap: {
    justifyContent: 'flex-start',
  },
  item: {
    marginBottom: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#002A77',
    fontSize: 12,
    fontWeight: 400,
    borderBottom: '1px solid rgba(4, 46, 107, 0.50)',
    paddingBottom: 4,
  },
  by: {},
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#000',
    fontSize: 13,
    fontWeight: 400,
  },
  status: {
    fontSize: 12,
  },
  showMoreBtn: {
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 8,
    textTransform: 'none',
    fontWeight: 400,
  },
  toolbar: {
    justifyContent: 'flex-start !important',
    padding: '15px 0 0 !important',
    '& button': {
      margin: '0 25px 0 0 !important',
      order: '0 !important',
    },
  },
  returnBtn: {
    textTransform: 'none',
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 8,
  },
  manualFormRow: {
    width: '100%',
  },
  clearDate: {
    color: 'red',
    position: 'absolute',
    transform: 'translateX(10px) rotate(45deg)',
    fontSize: 24,
    lineHeight: '15px',
    fontWeight: 300,
    cursor: 'pointer',
    zIndex: 10,
  },
  setDate: {
    position: 'relative',
    top: -10,
  },
});
