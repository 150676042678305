import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Select, DatePicker, Input } from 'antd';
import { useRequest } from '@hooks';
import { useNotify } from 'react-admin';
import { useListContext } from 'ra-core';
import IMask from 'imask';
import moment from 'moment-timezone';

import { creditHttp, bankHttp, adminHttp } from '@network';
import { BlueButton, BlueOutlineButton } from '@components';
import { getCreditStatusOptions } from '@pages/credit/CreditStatusField';
import { useModalContext } from '@providers';
import { rejectReasonOptions } from '@pages/credit/CreditEdit/CreditInfo';

const propsList = ['Credit status', 'Bank', 'Tasks', 'Read/Unread records', 'Responsible Manager', 'Reason for rejection'];
type PropType = 'Credit status' | 'Bank' | 'Tasks' | 'Read/Unread records' | 'Responsible Manager' | 'Reason for rejection';
type Option = {value: number | string; label: string};

export const CreditListActions = () => {
  const { selectedIds, refetch } = useListContext();
  const [prop, setProp] = useState<PropType>();
  const { hideModal, setTitle } = useModalContext();
  const notify = useNotify();
  const cls = useStyles();

  const creditStatusOptions = getCreditStatusOptions();
  const statusOptions = creditStatusOptions.map(o => ({
    value: o.id,
    label: o.name,
    disabled: o.disabled,
  }));
  const [status, setStatus] = useState<string>();

  const [bankOptions, setBankOptions] = useState<Option[]>([]);
  const [bankId, setBankId] = useState<number>();

  const [visitOptions, setVisitOptions] = useState<Option[]>([]);
  const [visitAction, setVisitAction] = useState<string>('');

  // tasks
  const [responsibleManagerOptions, setResponsibleManagerOptions] = useState<Option[]>([]);
  const [responsibleOptions, setResponsibleOptions] = useState<Option[]>([]);
  const [responsibleAdminId, setResponsibleAdminId] = useState<number>();
  const [newResponsibleAdminId, setNewResponsibleAdminId] = useState<number | undefined>();
  const [rejectReason, setRejectReason] = useState<string | undefined>();
  const [contactBy, setContactBy] = useState<any>();
  const [observation, setObservation] = useState<string>();

  const resetValues = () => {
    setStatus(undefined);
    setBankId(undefined);
    setResponsibleAdminId(undefined);
    setContactBy(undefined);
    setObservation(undefined);
  };

  const onCancel = () => {
    setProp(undefined);
    resetValues();
    hideModal();
  };

  const { loading, submit } = useRequest(async () => {
    if (!Array.isArray(selectedIds) || selectedIds.length === 0) {
      return notify('No Users are selected');
    }
    if (prop === 'Credit status' && !status) {
      return notify('No Credit status is selected');
    }
    if (prop === 'Bank' && !bankId) {
      return notify('No Bank is selected');
    }
    if (prop === 'Tasks' && !responsibleAdminId) {
      return notify('No Task admin is selected');
    }
    if (prop === 'Tasks' && !contactBy) {
      return notify('No Contact by is selected');
    }
    if (prop === 'Read/Unread records' && !visitAction) {
      return notify('No read/unread action is selected');
    }
    if (prop === 'Responsible Manager' && !newResponsibleAdminId) {
      return notify('No responsible manager is selected');
    }
    if (prop === 'Reason for rejection' && !rejectReason) {
      return notify('No reason for rejection is selected');
    }

    const updatedTotal = await creditHttp.updateBulk({
      creditIds: selectedIds,
      status,
      bankId,
      responsibleAdminId,
      newResponsibleAdminId,
      contactBy,
      observation,
      visitAction,
      rejectReason,
    });

    notify(`Updated ${updatedTotal} credits`);
    onCancel();
    refetch();
  });

  useEffect(() => void resetValues(), [prop]);

  useEffect(() => {
    // load banks
    (async () => {
      const banks = await bankHttp.getAll();
      const sortedBanks = banks.map(bank => ({
        value: bank.id,
        label: bank.nameMx as string,
      })).sort((a, b) => a.label > b.label ? 1 : -1);
      setBankOptions(sortedBanks);
    })();
    // load task admins
    (async () => {
      const taskAdmins = await adminHttp.getTaskAdmins();
      const sortedTaskAdmins = taskAdmins.map(admin => ({
        value: admin.id,
        label: admin.name,
      })).sort((a, b) => a.label > b.label ? 1 : -1);
      setResponsibleOptions(sortedTaskAdmins);
    })();
    // load task admins
    (async () => {
      const responsibleManagers = await adminHttp.getResponsibleManagers();
      const sortedResponsibleManagers = responsibleManagers.map(admin => ({
        value: admin.id,
        label: admin.name,
      })).sort((a, b) => a.label > b.label ? 1 : -1);
      setResponsibleManagerOptions(sortedResponsibleManagers);
    })();

    setVisitOptions([{
      value: 'visit',
      label: 'Read records',
    }, {
      value: 'unvisit',
      label: 'Unread records',
    }]);
  }, []);

  useEffect(() => {
    setTitle(prop === 'Tasks' ? `Create tasks for ${selectedIds.length} credits` : undefined);
  }, [prop]);

  const showPropSelect = prop !== 'Tasks';

  return (
    <div className={cls.box}>
      {showPropSelect && (
        <>
          <div className={cls.title}>Properties to update</div>
          <Select value={prop} onChange={v => setProp(v)} className={cls.select}
            placeholder="Select a property to edit">
            {propsList.map(propItem => (
              <Select.Option key={propItem} value={propItem}>
                {propItem}
              </Select.Option>
            ))}
          </Select>
        </>
      )}

      <div className={cls.content}>
        {prop && showPropSelect && <div className={cls.title}>{prop}</div>}
        {prop === 'Credit status' && (
          <Select value={status}
            onChange={v => setStatus(v)}
            className={cls.select}
            placeholder="Select Credit status"
            showSearch
            optionFilterProp="children"
            status={!status ? 'error' : undefined}
            filterOption={(input, option: any) => {
              return option.props?.children?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {statusOptions.map(option => (
              <Select.Option key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )}
        {prop === 'Bank' && (
          <Select value={bankId}
            onChange={v => setBankId(v)}
            className={cls.select}
            placeholder="Select Bank"
            showSearch
            optionFilterProp="children"
            status={!bankId ? 'error' : undefined}
            filterOption={(input, option: any) => {
              return option.props?.children?.toString()
                .toLowerCase().includes(input.toLowerCase());
            }}
          >
            {bankOptions.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )}
        {prop === 'Tasks' && (
          <div className={cls.tasks}>
            <div className={cls.taskGroup}>
              <div className={cls.title}>Task admin</div>
              <Select value={responsibleAdminId}
                onChange={v => setResponsibleAdminId(v)}
                className={cls.select}
                placeholder="Select Task admin"
                showSearch
                optionFilterProp="children"
                status={!responsibleAdminId ? 'error' : undefined}
                filterOption={(input, option: any) => {
                  return option.props?.children?.toString()
                    .toLowerCase().includes(input.toLowerCase());
                }}
              >
                {responsibleOptions.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className={cls.taskGroup}>
              <div className={cls.title}>Contact by</div>
              <DatePicker
                className={cls.date}
                value={contactBy ? moment(contactBy) : null}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  const input = event.target as HTMLInputElement;
                  input.value = MASKED.resolve(input.value);
                  setTimeout(() => {
                    const momentDate = moment(input.value, DATE_FORMAT, true);
                    momentDate.isValid() && setContactBy(momentDate.format('YYYY-MM-DD'));
                  }, 200);
                }}
                onChange={selectedValue => {
                  const momentDate = moment(selectedValue, DATE_FORMAT, true);
                  momentDate.isValid() && setContactBy(momentDate.format('YYYY-MM-DD'));
                }}
                picker="date"
                format={DATE_FORMAT}
                clearIcon={false}
                status={!contactBy ? 'error' : undefined}
              />
            </div>
            <div className={cls.taskGroup}>
              <div className={cls.title}>Observation</div>
              <Input.TextArea
                className={cls.textarea}
                onChange={e => setObservation(e.target.value)}
                placeholder="Here, the responsible manager will add any observation made during call."
              />
            </div>
          </div>
        )}
        {prop === 'Read/Unread records' && (
          <Select
            value={visitAction}
            onChange={v => setVisitAction(v)}
            className={cls.select}
            placeholder="Select action"
            showSearch
            optionFilterProp="children"
            status={!visitAction ? 'error' : undefined}
          >
            {visitOptions.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )}
        {prop === 'Responsible Manager' && (
          <Select
            value={newResponsibleAdminId}
            onChange={v => setNewResponsibleAdminId(+v)}
            className={cls.select}
            placeholder="Select responsible manager"
            showSearch
            optionFilterProp="children"
            status={!newResponsibleAdminId ? 'error' : undefined}
          >
            {responsibleManagerOptions.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )}
        {prop === 'Reason for rejection' && (
          <Select
            value={rejectReason}
            onChange={v => setRejectReason(v)}
            className={cls.select}
            placeholder="Select reason for rejection"
            showSearch
            optionFilterProp="children"
            status={!rejectReason ? 'error' : undefined}
          >
            {rejectReasonOptions.map(option => (
              <Select.Option key={option.name} value={option.name}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>

      <div className={cls.controls}>
        <BlueButton className={cls.btn} onClick={submit} disabled={loading}>
          Update
        </BlueButton>
        <BlueOutlineButton className={cls.btn} onClick={onCancel}>
          Cancel
        </BlueOutlineButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  box: {},
  title: {
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 500,
  },
  select: {
    marginTop: 10,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E7EE',
    backgroundColor: '#F3F7F9',
    '& .ant-select-selector': {
      backgroundColor: 'transparent !important',
    },
  },
  content: {
    marginTop: 15,
  },
  tasks: {
    marginTop: -15,
  },
  taskGroup: {
    marginBottom: 15,
  },
  textarea: {
    marginTop: 10,
    padding: '10px 19px 12px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
  },
  date: {
    marginTop: 10,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E7EE',
    backgroundColor: '#F3F7F9',
    height: '34px !important',
    '& .ant-picker-input ': {
      borderBottomColor: 'transparent !important',
      marginLeft: 10,
      marginRight: 10,
    },
  },
  controls: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    margin: '0 7px',
    padding: '5px 29px !important',
    height: 34,
    borderRadius: 8,
    textTransform: 'none',
  },
});

const DATE_FORMAT = 'DD/MM/YYYY';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: new Date().getFullYear() },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});
