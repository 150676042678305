import { useRedirect } from 'react-admin';
import { Button } from '@mui/material';
import { Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { rbac, showAxiosError } from '@utils';
import { User } from '@types';
import { creditHttp } from '@network';
import { InitialPage } from '@components';
import { UserTimeTracker } from '@pages/user/UserInitial/user-time-tracker';
import { UserCard } from './user-card';
import { UserOrders } from './user-orders';
import { UserLinks } from './user-links';
import { UserCredits } from './user-credits';

import cls from './user-initial.module.css';

export const UserInitial = ({ user }: {user: User}) => {
  const redirect = useRedirect();

  const createDraftCredit = async () => {
    try {
      const draft = await creditHttp.createCredit({ userId: user.id, isAdminDraft: true });
      if (draft) {
        redirect(`/credit/${draft.id}`);
      }
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  const hasDraftCreditRole = rbac.hasDraftCredit();
  const hasCredits = !!user?.credits?.length;
  const hasDraftCredits = !!user?.credits?.find(c => c.isAdminDraft);

  const showAddDraftButton = !hasDraftCredits && hasDraftCreditRole;

  return (
    <InitialPage title="Individual User" backTo="/user" backText="Back to all registered users">
      <Row gutter={[20, 20]} className={cls.infoline}>
        <Col xs={24} sm={12} md={8} className={cls.infolineCard}>
          <UserCard user={user} />
        </Col>
        <Col xs={24} sm={12} md={8} className={cls.infolineCard}>
          <UserOrders user={user} />
        </Col>
        <Col xs={24} sm={12} md={8} className={cls.infolineCard}>
          <UserLinks user={user} />
        </Col>
        <Col xs={24} sm={12} md={8} className={cls.infolineCard}>
          <UserTimeTracker user={user} />
        </Col>
      </Row>
      <div className={cls.creditsWrap}>
        <div className={cls.creditsHead}>
          <div className={cls.title}>Credits</div>

          {showAddDraftButton && (
            <Button variant="contained" className={cls.addDraft}
              onClick={createDraftCredit}>
              <PlusOutlined /> Create Draft
            </Button>
          )}
        </div>

        {hasCredits ? <UserCredits user={user} /> : <div>No credits yet</div>}
      </div>
    </InitialPage>
  );
};
