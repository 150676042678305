import { Admin, Resource } from 'react-admin';
import { useEffect } from 'react';
import RegionIcon from '@mui/icons-material/Language';
import DeliveryIcon from '@mui/icons-material/ShoppingBasket';

import { AppLayout, theme, MenuIcon } from './layout';
import {
  authProvider,
  checkSuperadmin,
  customDataProvider,
  SettingsProvider,
} from '@providers';
import { LocalStorage, rbac } from '@utils';
import './app.css';

// PAGES
import { Dashboard } from '@pages/dashboard';
import { UserList } from '@pages/user';
import { FeedbackList, FeedbackEdit } from '@pages/feedback';
import { PageList, PageEdit, PageCreate } from '@pages/page';
import { CityList, CityEdit } from '@pages/city';
import { StateList, StateEdit } from '@pages/state';
import { CountryList, CountryEdit } from '@pages/country';
import { CropCategoryList, CropCategoryEdit, CropCategoryCreate } from '@pages/crop-category';
import { CropList, CropEdit, CropCreate } from '@pages/crop';
import { DealerList } from '@pages/dealer';
import { DoctypeList, DoctypeEdit, DoctypeCreate } from '@pages/doctype';
import { BankList, BankEdit, BankCreate } from '@pages/bank';
import { QuestionList, QuestionCreate, QuestionEdit } from '@pages/question';
import { AdminList, AdminCreate, AdminEdit } from '@pages/admin';
import { CreditEdit, CreditList } from '@pages/credit';
import { ProductList, ProductCreate, ProductEdit } from '@pages/product';
import { DivisionList, DivisionCreate, DivisionEdit } from '@pages/division';
import { OrderCreate, OrderEdit, OrderList } from '@pages/order';
import { LoginTryList, LoginTryEdit } from '@pages/login-try';
import { JobCreate, JobEdit, JobList } from '@pages/job';
import { ResumeEdit, ResumeList } from '@pages/resume';
import { WebinarEdit, WebinarCreate, WebinarList } from '@pages/webinar-list';
import { WebinarSignupCreate, WebinarSignupEdit, WebinarSignupList } from '@pages/webinar-signup';
import { BlogEdit, BlogCreate, BlogList } from '@pages/blog';
import { DeliveryCreate, DeliveryEdit, DeliveryList } from '@pages/delivery';
import { ManagerCreate, ManagerEdit, ManagerList } from '@pages/manager';
import { RoleCreate, RoleEdit, RoleList } from '@pages/role';
import { PermissionCreate, PermissionEdit, PermissionList } from '@pages/permission';
import { SmsCreate, SmsEdit, SmsList } from '@pages/sms';
import { SeoCreate, SeoEdit, SeoList } from '@pages/seo';
import { CommentList, CommentEdit } from '@pages/comment';
import { FaqList, FaqEdit, FaqCreate } from '@pages/faq';
import { ProductTypeList, ProductTypeCreate, ProductTypeEdit } from '@pages/product-type';
import { ProductBrandList, ProductBrandCreate, ProductBrandEdit } from '@pages/product-brand';
import { UserEdit } from '@pages/user/UserEdit';
import { CreditCallList, CreditCallEdit, CreditCallCreate } from '@pages/credit-call';
import { CreditRiskList, CreditRiskEdit } from '@pages/credit-risk';
import { RiskEdit, RiskList } from '@pages/risk';
import { LeadCreate, LeadEdit, LeadList } from '@pages/lead';

// FINANCE PAGES
import {
  FinanceAccountCreate,
  FinanceAccountEdit,
  FinanceAccountList,
} from '@pages/finance-account';
import {
  FinanceDepositList,
  FinanceDepositEdit,
  FinanceDepositCreate,
} from '@pages/finance-deposit';
import {
  FinanceWithdrawalCreate,
  FinanceWithdrawalEdit,
  FinanceWithdrawalList,
} from '@pages/finance-withdrawal';
import { FinanceDashboardPage } from '@pages/finance-dashboard/FinanceDashboard';
import {
  FinanceSettings,
  FinanceCostTypeEdit,
  FinanceActivationSettingsEdit,
} from '@pages/finance-settings';
import {
  FinanceCostCategoryCreate,
  FinanceCostCategoryEdit,
} from '@pages/finance-cost-category';
import {
  FinanceActivationCreate,
  FinanceActivationEdit,
  FinanceActivationList,
} from '@pages/finance-activation';
import {
  FinanceCampaignList,
  FinanceCampaignCreate,
  FinanceCampaignEdit,
} from '@pages/finance-campaign';
import {
  FinanceCreditCreate,
  FinanceCreditEdit,
  FinanceCreditList,
} from '@pages/finance-credit';
import { FinanceCostCreate, FinanceCostEdit, FinanceCostList } from '@pages/finance-cost';
// import { FinanceCostCreate, FinanceCostEdit, FinanceCostList } from '@pages/finance-cost--DEMO';

// APP
export const App = () => {
  useEffect(() => {
    LocalStorage.remove('undefined');
  }, []);

  return (
    <SettingsProvider>
      <Admin
        theme={theme}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={customDataProvider}
        layout={AppLayout}
      >
        {permissions => {
          const isSuperadmin = checkSuperadmin();
          const showRiskAdmin = isSuperadmin || rbac.hasRiskAdminReadonlyPerm();

          return (
            <>
              {isSuperadmin === true && (
                <>
                  <Resource name="position" options={{ isHidden: true }} />

                  {/* ADMIN */}
                  <Resource
                    name="admins"
                    icon={() => <MenuIcon name="admins" />}
                    options={{ isMenuParent: true, label: 'Admin' }}
                  />
                  <Resource
                    name="admin"
                    list={AdminList}
                    edit={AdminEdit}
                    create={AdminCreate}
                    icon={() => <MenuIcon name="admin" />}
                    options={{ menuParent: 'admins', label: 'Administrators' }}
                  />
                  <Resource
                    name="role"
                    list={RoleList}
                    edit={RoleEdit}
                    create={RoleCreate}
                    icon={() => <MenuIcon name="role" />}
                    options={{ menuParent: 'admins', label: 'Roles' }}
                  />
                  <Resource
                    name="permission"
                    list={PermissionList}
                    edit={PermissionEdit}
                    create={PermissionCreate}
                    icon={() => <MenuIcon name="permission" />}
                    options={{ menuParent: 'admins', label: 'Permissions' }}
                  />
                  <Resource
                    name="login-try"
                    list={LoginTryList}
                    edit={LoginTryEdit}
                    icon={() => <MenuIcon name="login-try" />}
                    options={{ menuParent: 'admins', label: 'Login Try' }}
                  />
                </>
              )}

              {/* COMMERCIAL */}
              <Resource
                name="commercial"
                icon={() => <MenuIcon name="commercial" />}
                options={{ isMenuParent: true, label: 'Pipe' }}
              />
              <Resource
                name="user"
                list={UserList}
                edit={UserEdit}
                icon={() => <MenuIcon name="user" />}
                options={{ menuParent: 'commercial', label: 'Registered Users' }}
              />
              <Resource
                name="lead"
                list={LeadList}
                edit={LeadEdit}
                create={LeadCreate}
                icon={() => <MenuIcon name="lead" />}
                options={{ menuParent: 'commercial', label: 'Leads' }}
              />
              <Resource
                name="credit"
                list={CreditList}
                edit={CreditEdit}
                create={undefined}
                icon={() => <MenuIcon name="credit" />}
                options={{
                  menuParent: 'commercial', label: 'Credits',
                  notification: 'visitCredits',
                }}
              />
              <Resource
                name="credit-call"
                list={CreditCallList}
                edit={CreditCallEdit}
                create={CreditCallCreate}
                icon={() => <MenuIcon name="tasks" />}
                options={{ menuParent: 'commercial', label: 'Tasks' }}
              />
              <Resource
                name="order"
                list={OrderList}
                edit={OrderEdit}
                create={OrderCreate}
                icon={() => <MenuIcon name="order" />}
                options={{ menuParent: 'commercial', label: 'Orders' }}
              />
              <Resource
                name="delivery"
                list={DeliveryList}
                edit={DeliveryEdit}
                create={DeliveryCreate}
                icon={DeliveryIcon}
                options={{ menuParent: 'commercial', label: 'Shipping' }}
              />

              {/* PARTNERS */}
              <Resource
                name="partners"
                icon={() => <MenuIcon name="partners" />}
                options={{ isMenuParent: true, label: 'Partners' }}
              />
              <Resource
                name="manager"
                list={ManagerList}
                edit={ManagerEdit}
                create={ManagerCreate}
                icon={() => <MenuIcon name="managers-empresas" />}
                options={{ menuParent: 'partners', label: 'Empresas' }}
              />
              <Resource
                name="manager-retailer"
                list={ManagerList}
                edit={ManagerEdit}
                create={ManagerCreate}
                icon={() => <MenuIcon name="managers-negocios" />}
                options={{ menuParent: 'partners', label: 'Negocios' }}
              />
              <Resource
                name="manager-associate"
                list={ManagerList}
                edit={ManagerEdit}
                create={ManagerCreate}
                icon={() => <MenuIcon name="managers-socios" />}
                options={{ menuParent: 'partners', label: 'Socios' }}
              />
              <Resource
                name="dealer"
                list={DealerList}
                icon={() => <MenuIcon name="dealer" />}
                options={{ menuParent: 'partners', label: 'Buyers' }}
              />
              <Resource
                name="dealer-retailer"
                list={DealerList}
                icon={() => <MenuIcon name="retailer" />}
                options={{ menuParent: 'partners', label: 'Retailers' }}
              />
              <Resource
                name="dealer-associate"
                list={DealerList}
                icon={() => <MenuIcon name="associate" />}
                options={{ menuParent: 'partners', label: 'Associates' }}
              />

              {/* CREDIT */}
              <Resource
                name="creditParent"
                icon={() => <MenuIcon name="credit" />}
                options={{ isMenuParent: true, label: 'Credit' }}
              />
              {showRiskAdmin && (
                <Resource
                  name="risk"
                  edit={RiskEdit}
                  list={RiskList}
                  icon={() => <MenuIcon name="creditRisk" />}
                  options={{ menuParent: 'creditParent', label: 'Risk Admin' }}
                />
              )}
              <Resource
                name="credit-risk"
                list={CreditRiskList}
                edit={CreditRiskEdit}
                icon={() => <MenuIcon name="riskManager" />}
                options={{ menuParent: 'creditParent', label: 'Risk Manager' }}
              />
              <Resource
                name="question"
                list={QuestionList}
                edit={QuestionEdit}
                create={QuestionCreate}
                icon={() => <MenuIcon name="question" />}
                options={{ menuParent: 'creditParent', label: 'Survey' }}
              />
              <Resource
                name="doctype"
                list={DoctypeList}
                edit={DoctypeEdit}
                create={DoctypeCreate}
                icon={() => <MenuIcon name="doctype" />}
                options={{ menuParent: 'creditParent', label: 'Documents' }}
              />

              {/* CATALOG */}
              <Resource
                name="catalog"
                icon={() => <MenuIcon name="catalog" />}
                options={{ isMenuParent: true, label: 'Catalog' }}
              />
              <Resource
                name="product"
                list={ProductList}
                edit={ProductEdit}
                create={ProductCreate}
                icon={() => <MenuIcon name="products" />}
                options={{ menuParent: 'catalog', label: 'Products' }}
              />
              <Resource
                name="product_comment"
                edit={CommentEdit}
                list={CommentList}
                icon={() => <MenuIcon name="productComment" />}
                options={{ menuParent: 'catalog', label: 'Comments', notification: 'visitComments' }}
              />
              <Resource
                name="division"
                list={DivisionList}
                edit={DivisionEdit}
                create={DivisionCreate}
                icon={() => <MenuIcon name="productCategory" />}
                options={{ menuParent: 'catalog', label: 'Categories' }}
              />
              <Resource
                name="product-type"
                list={ProductTypeList}
                edit={ProductTypeEdit}
                create={ProductTypeCreate}
                icon={() => <MenuIcon name="productSubcategory" />}
                options={{ menuParent: 'catalog', label: 'Subcategories' }}
              />
              <Resource
                name="product-brand"
                list={ProductBrandList}
                edit={ProductBrandEdit}
                create={ProductBrandCreate}
                icon={() => <MenuIcon name="productBrand" />}
                options={{ menuParent: 'catalog', label: 'Brands' }}
              />

              {/* CROP */}
              <Resource
                name="crops"
                icon={() => <MenuIcon name="crops" />}
                options={{ isMenuParent: true, label: 'Crops' }}
              />
              <Resource
                name="crop"
                list={CropList}
                edit={CropEdit}
                create={CropCreate}
                icon={() => <MenuIcon name="crop" />}
                options={{ menuParent: 'crops', label: 'Crops' }}
              />
              <Resource
                name="crop-category"
                list={CropCategoryList}
                edit={CropCategoryEdit}
                create={CropCategoryCreate}
                icon={() => <MenuIcon name="crop-category" />}
                options={{ menuParent: 'crops', label: 'Categories' }}
              />

              {/* MARKETING */}
              <Resource
                name="marketing"
                icon={() => <MenuIcon name="marketing" />}
                options={{ isMenuParent: true, label: 'Marketing' }}
              />
              <Resource
                name="seo"
                create={SeoCreate}
                list={SeoList}
                edit={SeoEdit}
                icon={() => <MenuIcon name="seo" />}
                options={{ menuParent: 'marketing', label: 'SEO' }}
              />
              <Resource
                name="blog"
                list={BlogList}
                edit={BlogEdit}
                create={BlogCreate}
                icon={() => <MenuIcon name="blog" />}
                options={{ menuParent: 'marketing', label: 'Blogs' }}
              />
              <Resource
                name="sms"
                list={SmsList}
                edit={SmsEdit}
                create={SmsCreate}
                icon={() => <MenuIcon name="sms" />}
                options={{ menuParent: 'marketing', label: 'SMS' }}
              />
              <Resource
                name="faq"
                list={FaqList}
                edit={FaqEdit}
                create={FaqCreate}
                icon={() => <MenuIcon name="faq" />}
                options={{ menuParent: 'marketing', label: 'FAQ' }}
              />
              <Resource
                name="feedback/contact"
                list={FeedbackList}
                edit={FeedbackEdit}
                icon={() => <MenuIcon name="feedback" />}
                options={{ menuParent: 'marketing', label: 'Contact us' }}
              />

              {/* FINANCE */}
              <Resource
                name="finance"
                icon={() => <MenuIcon name="finance" />}
                options={{ isMenuParent: true, label: 'Finance' }}
              />
              <Resource
                name="finance-cost-category"
                create={FinanceCostCategoryCreate}
                edit={FinanceCostCategoryEdit}
                options={{ isHidden: true }}
              />
              <Resource name="finance-activation-status" options={{ isHidden: true }} />
              <Resource name="finance-origin-option" options={{ isHidden: true }} />
              <Resource
                name="finance-cost-type"
                edit={FinanceCostTypeEdit}
                options={{ isHidden: true }}
              />
              <Resource
                name="finance-activation-settings"
                edit={FinanceActivationSettingsEdit}
                options={{ isHidden: true }}
              />
              <Resource
                name="finance-account"
                list={FinanceAccountList}
                edit={FinanceAccountEdit}
                create={FinanceAccountCreate}
                icon={() => <MenuIcon name="financeAccounts" />}
                options={{ menuParent: 'finance', label: 'Accounts' }}
              />
              <Resource
                name="finance-deposit"
                list={FinanceDepositList}
                edit={FinanceDepositEdit}
                create={FinanceDepositCreate}
                icon={() => <MenuIcon name="financeDeposits" />}
                options={{ menuParent: 'finance', label: 'Deposits' }}
              />
              <Resource
                name="finance-withdrawal"
                list={FinanceWithdrawalList}
                edit={FinanceWithdrawalEdit}
                create={FinanceWithdrawalCreate}
                icon={() => <MenuIcon name="financeWithdrawals" />}
                options={{ menuParent: 'finance', label: 'Withdrawals' }}
              />
              <Resource
                name="finance-balance"
                list={FinanceDashboardPage}
                icon={() => <MenuIcon name="financeBalance" />}
                options={{ menuParent: 'finance', label: 'Balance' }}
              />
              <Resource
                name="finance-cost"
                list={FinanceCostList}
                edit={FinanceCostEdit}
                create={FinanceCostCreate}
                icon={() => <MenuIcon name="financeCost" />}
                options={{ menuParent: 'finance', label: 'Cost Center', iconInverse: true }}
              />
              <Resource
                name="finance-activation"
                create={FinanceActivationCreate}
                edit={FinanceActivationEdit}
                list={FinanceActivationList}
                icon={() => <MenuIcon name="financeActivations" />}
                options={{ menuParent: 'finance', label: 'Activations', iconInverse: true }}
              />
              <Resource
                name="finance-campaign"
                create={FinanceCampaignCreate}
                edit={FinanceCampaignEdit}
                list={FinanceCampaignList}
                icon={() => <MenuIcon name="financeCost" />}
                options={{ menuParent: 'finance', label: 'Campaigns', iconInverse: true }}
              />
              <Resource
                name="finance-credit"
                list={FinanceCreditList}
                edit={FinanceCreditEdit}
                create={FinanceCreditCreate}
                icon={() => <MenuIcon name="financeCost" />}
                options={{ menuParent: 'finance', label: 'Other Credits', iconInverse: true }}
              />
              <Resource
                name="finance-settings"
                icon={() => <MenuIcon name="financeSettings" />}
                list={FinanceSettings}
                options={{ menuParent: 'finance', label: 'Finance Settings', iconInverse: true }}
              />

              {/* CORPORATE */}
              <Resource
                name="corporate"
                icon={() => <MenuIcon name="corporate" />}
                options={{ isMenuParent: true, label: 'Corporate' }}
              />
              <Resource
                name="legal-manager"
                icon={() => <MenuIcon name="legalManager" />}
                options={{ menuParent: 'corporate', label: 'Legal Manager' }}
              />
              <Resource
                name="bank"
                list={BankList}
                edit={BankEdit}
                create={BankCreate}
                icon={() => <MenuIcon name="bank" />}
                options={{ menuParent: 'corporate', label: 'NBFI\'s' }}
              />
              <Resource
                name="page"
                list={PageList}
                edit={PageEdit}
                create={PageCreate}
                icon={() => <MenuIcon name="page" />}
                options={{ menuParent: 'corporate', label: 'Pages' }}
              />

              {/* LOCATION */}
              <Resource
                name="location"
                icon={() => <MenuIcon name="location" />}
                options={{ isMenuParent: true, label: 'Location' }}
              />
              <Resource
                name="state"
                list={StateList}
                edit={StateEdit}
                icon={RegionIcon}
                options={{ menuParent: 'location', label: 'States' }}
              />
              <Resource
                name="city"
                list={CityList}
                edit={CityEdit}
                icon={() => <MenuIcon name="city" />}
                options={{ menuParent: 'location', label: 'Cities' }}
              />
              <Resource
                name="country"
                list={CountryList}
                edit={CountryEdit}
                icon={() => <MenuIcon name="country" />}
                options={{ menuParent: 'location', label: 'Countries' }}
              />

              {/* ESG */}
              <Resource
                name="esg"
                icon={() => <MenuIcon name="esg" />}
                options={{ isMenuParent: true, label: 'ESG' }}
              />
              <Resource
                name="job"
                list={JobList}
                edit={JobEdit}
                create={JobCreate}
                icon={() => <MenuIcon name="job" />}
                options={{ menuParent: 'esg', label: 'Careers' }}
              />
              <Resource
                name="resume"
                list={ResumeList}
                edit={ResumeEdit}
                icon={() => <MenuIcon name="resume" />}
                options={{ menuParent: 'esg', label: 'Resumes' }}
              />
              <Resource
                name="webinar-list"
                list={WebinarList}
                edit={WebinarEdit}
                create={WebinarCreate}
                icon={() => <MenuIcon name="webinar" />}
                options={{ menuParent: 'esg', label: 'Webinars List' }}
              />
              <Resource
                name="webinar-signup"
                list={WebinarSignupList}
                edit={WebinarSignupEdit}
                create={WebinarSignupCreate}
                icon={() => <MenuIcon name="webinar" />}
                options={{ menuParent: 'esg', label: 'Webinar Signups' }}
              />
            </>
          );
        }}
      </Admin>
    </SettingsProvider>
  );
};
