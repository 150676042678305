import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Col, Row } from 'antd';

import { ReachToWomenData, reportsHttp } from '@network';
import { InitialCard } from '@components';
import { querySerialize } from '@utils';
import { LinkBlockBtn } from '@pages/dashboard/LinkBlockBtn';

export const ReachToWomenReport = ({ range }: {range: string}) => {
  const [numbers, setNumbers] = useState<ReachToWomenData>();
  const cls = useStyles();

  const loadNumbers = async () => {
    try {
      const nextNumbers = await reportsHttp.reachToWomen({ range });
      setNumbers(nextNumbers);
    } catch (e) {}
  };

  useEffect(() => void loadNumbers(), [range]);

  const registeredWomenLink = '/user?' + querySerialize({
    filter: JSON.stringify({
      gender: 'Mujer',
      createdAt: range || undefined,
    }),
  });
  const signedCreditsLink = '/credit?' + querySerialize({
    filter: JSON.stringify({
      gender: 'Mujer',
      status: ['signed', 'delay', 'repaid', 'restructure'],
      createdAt: range || undefined,
    }),
  });
  const activeCreditsLink = '/credit?' + querySerialize({
    filter: JSON.stringify({
      gender: 'Mujer',
      status: ['signed', 'delay', 'restructure'],
      createdAt: range || undefined,
    }),
  });

  const blocks: {title: string, link: string, total: number}[] = [
    {
      title: 'Registrations',
      total: numbers?.registeredWomen || 0,
      link: registeredWomenLink,
    },
    {
      title: 'Credits',
      total: numbers?.signedCredits || 0,
      link: signedCreditsLink,
    },
    {
      title: 'Active credits',
      total: numbers?.activeCredits || 0,
      link: activeCreditsLink,
    },
  ];

  return (
    <InitialCard left="Verqor's reach to women" extraPadding autoHeight mt={22}>
      <br />
      <Row gutter={[20, 20]}>
        <Col sm={24} md={3} />
        {blocks.map(block => (
          <Col key={block.title} sm={24} md={6} className={cls.col}>
            <LinkBlockBtn key={block.title} {...block} />
          </Col>
        ))}
        <Col sm={24} md={3} />
      </Row>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  btn: {
    padding: 0,
    textTransform: 'none',
    display: 'block',
    width: '100%',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    color: '#000',
  },
  content: {
    paddingTop: 30,
    paddingBottom: 17,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  counter: {
    marginTop: 5,
    fontSize: 50,
    fontWeight: 700,
    color: '#000',
  },
});
