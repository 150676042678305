import React, { useEffect, useState } from 'react';
import {
  ListProps,
  TextField,
  ReferenceField,
  RowClickFunction,
  Identifier,
  useRedirect,
  RaRecord,
  useNotify,
} from 'react-admin';

import {
  PhoneField,
  TextInput,
  Grid,
  GridList,
  Forbidden,
  BooleanField,
  SelectInput,
  ReferenceInput,
  SelectArrayInput,
  DateField,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import cls from './LeadList.module.css';
import {
  leadStageOptions,
} from '@types';
import { cropHttp, stateHttp } from '@network';
import { axiosErrorToString } from '@utils';

export const LeadList = (props: ListProps) => {
  const exporter = useExporter({
    filename: 'Verqor Leads',
    props: {
      'id': {
        label: 'ID',
      },
      'name': {
        label: 'Name',
      },
      'phone': {
        label: 'Phone',
        transform: 'phone',
      },
      'email': {
        label: 'Email',
      },
      'stage': {
        label: 'Stage',
      },
      'stateId': {
        label: 'State',
        prop: 'state.name',
      },
      'responsibleAdmin.name': {
        label: 'Manager',
        prop: 'responsibleAdmin.name',
      },
      'cropNames': {
        label: 'Crops',
      },
      'archived': {
        label: 'Archived',
        transform: 'boolean',
      },
      'createdAt': {
        label: 'Date of creation',
        transform: 'date',
      },
      'updatedAt': {
        label: 'Last modification',
        transform: 'date',
      },
    },
  });
  
  const rbacList = useRbacList();
  const redirect = useRedirect();
  const notify = useNotify();

  const [cropOptions, setCropOptions] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const crops = cropHttp.getAll();
        const states = stateHttp.getAll();
        const [loadedCrops, loadedStates] = await Promise.all([crops, states]);

        setCropOptions(loadedCrops
          .sort((a, b) => a.nameMx.localeCompare(b.nameMx))
          .map(crop => ({
            id: crop.id,
            name: crop.nameEn || crop.nameMx || `Crop id: ${crop.id}`,
          })));
        setStateOptions(loadedStates
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(state => ({
            id: state.id,
            name: state.name || state.name || `State id: ${state.id}`,
          })),
        );
      } catch (e) {
        notify(axiosErrorToString(e));
      }
    })();
  }, []);

  const userRowClick: RowClickFunction = (id: Identifier, resource: string, record: RaRecord) => {
    redirect(`/${resource}/${id}`);
    return false;
  };

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList
      {...props}
      exporter={exporter}
      filters={[
        ...Filters,
        <SelectArrayInput
          key="cropIds"
          source="cropIds"
          choices={cropOptions}
          alwaysOn
          className="MS-field"
          label="Crops"
          style={{ width: 268 }}
        />,
        <SelectArrayInput
          key="stateIds"
          source="stateIds"
          choices={stateOptions}
          alwaysOn
          className="MS-field"
          label="State"
          style={{ width: 268 }}
        />,
      ]}
      sort={{ field: 'id', order: 'DESC' }}
      className={cls.gridList}
    >
      <Grid
        rowClick={userRowClick}
        defaultColumns={'id name phone email stageName createdAt'.split(' ')}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <PhoneField source="phone" />
        <TextField source="email" />
        <TextField source="stageName" label="Stage" />
        <ReferenceField reference="user" source="addedByUserId" label="Added by User Id">
          <TextField source="fullName" />
        </ReferenceField>
        <TextField source="state.name" label="State" />
        <TextField source="responsibleAdmin.name" label="Manager" />
        <TextField source="cropNames" label="Crops" />
        <BooleanField source="archived" label="Archived" />
        <DateField source="createdAt" label="Date of creation" />
        <DateField source="updatedAt" label="Last Modification" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <ReferenceInput
    key="responsibleAdminId"
    source="responsibleAdminId"
    reference="admin"
    label="Manager"
    allowEmpty
    perPage={1000}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" label="Manager" style={{ minWidth: 180 }} />
  </ReferenceInput>,
  <SelectInput
    key="stage"
    source="stage"
    choices={leadStageOptions}
    alwaysOn
  />,
];
