import React, { useState } from 'react';
import { Create, SimpleForm } from 'react-admin';
import { makeStyles } from '@mui/styles';

import {
  Row,
  Col,
  Container,
  SelectInput,
  ReferenceInput,
  Forbidden,
} from '@components';
import OrderProducts from '@components/Orders/order-products';
import { moneyFormatter } from '@utils';
import { Credit } from '@types';
import { creditHttp } from '@network';
import { useRbacCreate } from '@hooks';

export const OrderCreateOld = () => {
  const cls = useStyles();
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [credits, setCredits] = useState<Credit[]>([]);
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  const onUserSelect = async (e: any): Promise<void> => {
    const userId = e.target.value;
    await setSelectedUser(userId);
    const nextCredits = await creditHttp.getCreditsByUser(userId);
    await setCredits(nextCredits);
  };

  const setCreditValue = (record: Credit) => (
    <span>
      <b>{record.code}</b> - <i>{moneyFormatter.format(record.balance)}</i>
    </span>
  );

  return (
    <Create title="Create order">
      <SimpleForm className={cls.form}>
        <Container max>
          <Row>
            <Col>
              <ReferenceInput
                source="userId"
                filter={{ forFilter: 'true' }}
                reference="user"
                label="User"
                allowEmpty
                alwaysOn
                perPage={10000}
                sort={{ field: 'fullName', order: 'ASC' }}
              >
                <SelectInput optionText="fullName" onChange={onUserSelect} />
              </ReferenceInput>
            </Col>
            <Col>
              <SelectInput
                source="creditId"
                optionText={setCreditValue}
                disabled={!selectedUser || !credits.length}
                choices={credits}
              />
            </Col>
          </Row>
          <Row title="Positions">
            <Col><OrderProducts source="composition" /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  form: {
    padding: 24,
  },
  select: {
    display: 'flex',
    borderBottom: '1px solid black',
    marginTop: 12,
  },
});
