import { useEffect, useRef } from 'react';
import { useGetList } from 'react-admin';
import { clsx } from 'clsx';

import { CropCategory } from '@types';
import { InputText } from '@components/inputs/InputText';
import { InputSelect } from '@components/inputs/InputSelect';
import cls from './crop-form.module.css';

interface CropCreatorFormProps {
  formValues: any;
  setFormValues: any;
  showErrors: boolean;
}

export const CropForm = ({
  formValues, setFormValues, showErrors,
}: CropCreatorFormProps) => {
  const selectedCategory = useRef<CropCategory | null>(null);
  const { data: cropCategories } = useGetList('crop-category');

  const onChangeHandler = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const onCategoryChange = (e: any) => {
    const categoryName = e.target.value;
    const category = cropCategories?.find(cc => cc.nameMx === categoryName);

    selectedCategory.current = category;
    setFormValues({
      ...formValues,
      categoryId: category?.id || null,
    });
  };

  useEffect(() => {
    if (cropCategories) {
      selectedCategory.current = cropCategories.find(cc => cc.id === formValues.categoryId);
    }
  }, [cropCategories]);

  const showNameMxError = formValues.nameMx?.length === 0 && showErrors;
  const showCategoryError = !formValues.categoryId && showErrors;
  const categories = cropCategories?.map(c => c.nameMx) || [];

  return (
    <div className={cls.form}>
      <div className={cls.row}>
        <InputText
          name="id"
          label="Id"
          labelWidth={135}
          centered
          value={formValues.id || ''}
          onChange={() => {}}
        />
      </div>
      <div className={cls.row}>
        <InputText
          name="nameMx"
          label="Name  mx*"
          labelWidth={135}
          centered
          onChange={onChangeHandler}
          className={clsx(showNameMxError && cls.error)}
          value={formValues.nameMx || ''}
        />
      </div>
      <div className={cls.row}>
        <InputText
          name="nameEn"
          label="Name en"
          labelWidth={135}
          centered
          onChange={onChangeHandler}
          value={formValues.nameEn || ''}
        />
      </div>
      <div className={cls.row}>
        <InputSelect
          labelWidth={135}
          label="Category*"
          name="category"
          values={categories}
          selectedValue={selectedCategory?.current?.nameMx || ''}
          className={clsx(showCategoryError && cls.error)}
          onChange={onCategoryChange}
          centered
        />
      </div>
    </div>
  );
};
