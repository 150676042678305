import { Document } from './Document';
import { Admin } from './Admin';

export type GuarantorStatus = 'waiting' | 'approved' | 'denied' | 'empty';

export interface Guarantor {
  id: number;
  creditId: number;
  status: GuarantorStatus;
  statusEn: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  fullName: string;
  email: string;
  phone: string;
  marriage: string;
  profession: string;
  relation: string;
  workSince: string;
  isMain: boolean;
  isApproved: boolean;
  isDenied: boolean;
  comment: string | null;
  commentAdmin: Admin | null;
  documents: Document[];
  type: GuarantorType | null;
  companyShare: number | null;
}

export enum GuarantorType {
  Apoderado = 'Apoderado',
  Accionista = 'Accionista',
}

export const guarantorTypes = [GuarantorType.Apoderado, GuarantorType.Accionista];

