import React, { useEffect, useState } from 'react';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { Progress } from 'antd';

import { surveyHttp } from '@network';
import { Credit, SurveyQuestion } from '@types';
import { BlueButton, InitialCard } from '@components';
import { DocumentCreator } from '@pages/credit/CreditSurvey/document-creator';
import cls from './credit-survey-progress.module.css';

interface Props {
  credit: Credit;
  titlePosition?: 'left' | 'center' | 'right';
}

export const CreditSurveyProgress = ({ credit, titlePosition }: Props) => {
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);

  useEffect(() => {
    (async () => {
      let { surveyQuestions } = await surveyHttp.getCreditSurvey(credit.id);
      surveyQuestions = surveyQuestions.map((q, index) => {
        q.position = index + 1;
        return q;
      });
      setQuestions(surveyQuestions);
    })();
  }, [credit.id]);

  const generateDocx = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const documentCreator = new DocumentCreator();
    const doc = documentCreator.createFromQeustions(questions, credit);

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, `${credit.user.fullName}. Questionario of Credit ${credit.code}.docx`);
      console.log('Document created successfully');
    });
  };

  const completedQuestions = questions
    .filter(q => q.customAnswer || q.answerIds.length > 0)
    .length;
  const totalQuestions = questions.length;
  const percent = Math.floor(100 * completedQuestions / totalQuestions);

  return (
    <InitialCard
      center={!titlePosition || titlePosition === 'center' ? 'Survey' : undefined}
      left={titlePosition === 'left' ? 'Survey' : undefined}
      right={titlePosition === 'right' ? 'Survey' : undefined}
      link={`/credit/${credit.id}/?survey`}
      extraPadding
      autoHeight
      minHeight={277}
    >
      <div className={cls.box}>
        <Progress
          percent={percent}
          format={(percent) => <span className={cls.progressFormat}>{percent}%</span>}
          type="circle"
          showInfo={true}
          width={107}
          strokeWidth={16}
          strokeColor="#0C5541"
          trailColor="rgba(25, 160, 107, 0.25)"
          className="survey-progress"
        />
        <div className={cls.progressText}>
          {completedQuestions}/{totalQuestions} completed
        </div>
        <BlueButton onClick={generateDocx} outlined className={cls.btn}>
          Export Docx
        </BlueButton>
      </div>
    </InitialCard>
  );
};
