import { clsx } from 'clsx';
import { Col, Row, Form } from 'antd';
import React, { useState } from 'react';
import { axiosErrorToString } from '@utils';
import { useCreateProps, useRbacCreate } from '@hooks';
import { Create, CreateProps, useNotify, useRedirect } from 'react-admin';

import { leadHttp } from '@network';
import { LeadFormProps } from '@types';
import { LeadForm } from '@pages/lead/LeadForm';
import { Container, Forbidden, PageHeader } from '@components';
import cls from './LeadCreate.module.css';

export const LeadCreate = (props: CreateProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();
  const [form] = Form.useForm<LeadFormProps>();
  const [loading, setLoading] = useState(false);
  const [formValues] = useState<LeadFormProps>({
    name: '',
    phone: '',
    email: '',
    stage: '',
    stateId: null,
    cropIds: [],
    activationId: null,
    responsibleAdminId: null,
  });

  const submit = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const params = form.getFieldsValue();
      await leadHttp.create(params);
      form.resetFields();
      notify('Account has been created');
      redirect('/lead');
    } catch (e) {
      // @ts-ignore
      if (e.errorFields?.length) {
        notify('Form is wrong', { type: 'error' });
      } else {
        notify(axiosErrorToString(e), { type: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <PageHeader
        title="Individual lead"
        linkText="Back to all records"
        linkUrl="/lead"
      />

      <Container max>
        <Row gutter={24} className={cls.wrapper}>
          <Col xs={24} className={cls.wrapperCol}>
            <div className={cls.block}>
              <div className={cls.blockHeader}>Lead information</div>
              <Form
                form={form}
                layout="horizontal"
                onFinish={submit}
                initialValues={formValues}
              >
                <LeadForm form={form} loading={loading} setLoading={setLoading} />
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Create>
  );
};
