import { InitialCard, InitialRow, TextInput } from '@components';
import cls from './CropProblems.module.css';
import React from 'react';

export const CropProblems = () => {
  return (
    <InitialCard
      left="Crop Problems"
      autoHeight
      extraPadding
      wrapperClass={cls.cardWrapper}
      contentClass={cls.card}
    >
      <InitialRow title="" titleClassName={cls.title} fullWidth>
        <TextInput
          multiline rows={3}
          name="cropProblems"
          source="cropProblems"
          label={false}
          type="text"
          placeholder="Comment"
          className={cls.formTextarea}
        />
      </InitialRow>
    </InitialCard>
  );
};
