import React from 'react';
import { TextInputProps } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useController } from 'react-hook-form';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment-timezone';
import IMask from 'imask';
import { clsx } from 'clsx';

const { RangePicker } = DatePicker;
const DATE_FORMAT = 'DD/MM/YYYY';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: new Date().getFullYear() },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

export const DateRangeInput = (props: TextInputProps) => {
  const cls = useStyles();
  const {
    field: { value, onChange: setRange },
  } = useController({ name: props.source });

  const [start, end] = (value || '').split('-');

  const handleChange = (range: any) => {
    const dateStart: Moment = range && range[0];
    const dateEnd: Moment = range && range[1];
    const nextValues = [];
    if (dateStart) {
      nextValues.push(dateStart.format('DD.MM.YYYY'));
    }
    if (dateEnd) {
      nextValues.push(dateEnd.format('DD.MM.YYYY'));
    }
    const nextRange = nextValues.join('-');
    setRange(nextRange);
  };

  return (
    <div>
      <label className={cls.label}>
        <span>{props.label}</span>
      </label>
      <div className={clsx('MuiInputBase-root', cls.box)}>
        <RangePicker
          onChange={handleChange}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            const input = event.target as HTMLInputElement;
            input.value = MASKED.resolve(input.value);
          }}
          defaultValue={[
            start ? moment(start, DATE_FORMAT) : null,
            end ? moment(end, DATE_FORMAT) : null,
          ]}
          format={DATE_FORMAT}
          placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
          allowEmpty={[true, true]}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  label: {
    display: 'block',
    color: '#042E6B',
    fontSize: 12,
    lineHeight: 1,
    marginTop: 4,
    marginLeft: 14,
    marginBottom: 3,
  },
  box: {
    backgroundColor: 'white',
    overflow: 'hidden',
    padding: '8px 12px 8px 8px',

    '& .ant-picker-range': {
      border: 'none',
      boxShadow: 'none',
    },

    '& .ant-picker-separator': {
      fontSize: 18,
      color: '#002A77',
    },

    '& .ant-picker-suffix': {
      fontSize: 16,
      color: '#002A77',
    },

    '& .ant-picker-input': {
      border: 'none !important',
    },

    '& .ant-picker-input > input': {
      border: 'none !important',
      padding: '7px !important',
    },

    '& .ant-picker-input > input::placeholder': {
      color: 'rgba(0, 42, 119, 0.50)',
      fontFamily: 'Outfit, sans-serif',
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 'normal',
    },

    '& .ant-picker-range .ant-picker-active-bar': {
      height: 1,
      width: '75px !important',
      backgroundColor: '#002A77',
      bottom: -2,
      marginLeft: 7,
    },

    '& .ant-picker-range .ant-picker-clear': {
      right: -3,
      fontSize: 23,
    },
  },
});
