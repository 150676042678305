import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import { reportsHttp } from '@network';
import { moneyFormatter, querySerialize } from '@utils';
import { InitialCard } from '@components';
import { useSettingsContext } from '@providers/settings-provider';
import { LinkBlockBtn } from './LinkBlockBtn';

type ReportKey = 'total' | 'signed' | 'repaid' | 'delay' | 'restructured';
type ReportValue = number | Record<ReportKey, string>

export const GeneralNumbersReport = ({ range }: {range: string}) => {
  const cls = useStyles();

  const { admin } = useSettingsContext();
  const [numbers, setNumbers] =
    useState<Record<string, ReportValue>>({});

  const loadNumbers = async () => {
    try {
      const nextNumbers = await reportsHttp.generalNumbers({ range });
      setNumbers(nextNumbers);
    } catch (e) {}
  };

  useEffect(() => {
    if (admin) {
      loadNumbers();
    }
  }, [range]);

  const blocks: {key: ReportKey, title: string, link: string}[] = [
    {
      key: 'total',
      title: 'Historic credits',
      link: '/credit?' + querySerialize({
        filter: JSON.stringify({
          status: ['signed', 'repaid', 'delay', 'restructure'],
          createdAt: range ? `createdAt:${range}` : undefined,
        }),
      }),
    },
    {
      key: 'signed',
      title: 'Signed credits',
      link: '/credit?' + querySerialize({
        filter: JSON.stringify({
          status: ['signed'],
          createdAt: range ? `createdAt:${range}` : undefined,
        }),
      }),
    },
    {
      key: 'repaid',
      title: 'Repaid credit',
      link: '/credit?' + querySerialize({
        filter: JSON.stringify({
          status: ['repaid'],
          createdAt: range ? `createdAt:${range}` : undefined,
        }),
      }),
    },
    {
      key: 'delay',
      title: 'Delayed credits',
      link: '/credit?' + querySerialize({
        filter: JSON.stringify({
          status: ['delay'],
          createdAt: range ? `createdAt:${range}` : undefined,
        }),
      }),
    },
    {
      key: 'restructured',
      title: 'Restructured credits',
      link: '/credit?' + querySerialize({
        filter: JSON.stringify({
          status: ['restructure'],
          createdAt: range ? `createdAt:${range}` : undefined,
        }),
      }),
    },
  ];

  return (
    <InitialCard left="General Numbers" extraPadding autoHeight mt={22}>
      <br />
      <div className={cls.list}>
        {blocks.map(block => {
          const { amounts, financeCredits } = numbers as any;
          const financeCreditsAmount = amounts?.financeCredits || 0;
          const amount = amounts ? amounts[block.key] : undefined;
          const tooltip = financeCredits > 0 && (block.key === 'total' || block.key === 'repaid')
            ? `Includes ${financeCredits} "Other credits" records of CALERA with Credit ${moneyFormatter.format(financeCreditsAmount)}`
            : undefined;

          return (
            <div key={block.key} className={cls.item}>
              <LinkBlockBtn
                tooltip={tooltip}
                key={block.title}
                title={block.title}
                link={block.link}
                total={numbers[block.key] as number || 0}
                amount={amount}
              />
            </div>
          );
        })}
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  list: {
    width: '100%',
    display: 'flex',
    gap: 20,
  },
  item: {
    width: '20%',
  },
});
