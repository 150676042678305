import React, { PropsWithChildren, SVGProps } from 'react';

import cls from './DownloadZone.module.css';

interface Props {
  title?: string;
  icon?: SVGProps<SVGSVGElement>;
}

export const DownloadZone = ({ title, icon, children }: PropsWithChildren<Props>) => {
  return (
    <div className={cls.block}>
      <div className={cls.header}>
        <div className={cls.headerIcon}>{icon ? icon : (
          <svg xmlns="http://www.w3.org/2000/svg" width="46" height="60" viewBox="0 0 46 60" fill="none">
            <g clipPath="url(#clip0_1401_5512)">
              <path d="M45.9823 14.3294C45.9823 14.3294 45.9823 14.2588 45.9646 14.2235C45.9292 14.0647 45.8585 13.9412 45.7523 13.8176L32.1115 0.264706C31.9523 0.105882 31.7223 0 31.4923 0H4.42308C1.98154 0 0 1.97647 0 4.41176V55.5882C0 58.0235 1.98154 60 4.42308 60H41.5769C44.0185 60 46 58.0235 46 55.5882V14.4353C46 14.4353 45.9823 14.3647 45.9823 14.3118V14.3294ZM32.3238 2.96471L42.9746 13.5706H34.9777C33.5092 13.5706 32.3238 12.3882 32.3238 10.9235V2.94706V2.96471Z" fill="#042E6B"/>
              <path d="M9.5 28.9234V44.8057C9.5 45.2998 9.88923 45.6881 10.3846 45.6881H35.1892C35.6846 45.6881 36.0738 45.2998 36.0738 44.8057V28.9234C36.0738 28.4292 35.6846 28.041 35.1892 28.041H10.3846C9.88923 28.041 9.5 28.4292 9.5 28.9234ZM23.6715 29.8057H34.3046V35.9822H23.6715V29.8057ZM23.6715 37.7469H34.3046V43.9234H23.6715V37.7469ZM11.2692 29.8057H21.9023V35.9822H11.2692V29.8057ZM11.2692 37.7469H21.9023V43.9234H11.2692V37.7469Z" fill="#CAD2E1"/>
            </g>
            <defs>
              <clipPath id="clip0_1401_5512">
                <rect width="46" height="60" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        )}</div>
        <div className={cls.headerTitle}>{title ? title : 'Download zone'}</div>
        <div className={cls.headerChildren}>{children}</div>
      </div>
    </div>
  );
};
