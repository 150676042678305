import { useEffect, useState } from 'react';
import { Modal, Select, notification } from 'antd';
import Button from '@mui/material/Button';

import { useRequest } from '@hooks';
import { creditHttp } from '@network';
import { Document, DocumentStatus, documentStatusMap } from '@types';
import cls from './document-statuses.module.css';

interface Props {
  creditId: number;
  document?: Document;
  setDocument: (document: Document) => void;
  editable: boolean;
}

export const DocumentStatuses = ({
  creditId, 
  document,
  setDocument,
  editable,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status, setStatus] = useState<DocumentStatus | undefined>(document?.status);
  const [statusOptions, setStatusOptions] = useState<{key: string; label: string}[]>([]);
  const changeStatus = (nextStatus: DocumentStatus) => setStatus(nextStatus);

  const { submit, loading } = useRequest(async () => {
    if (!status || !document || !editable) {
      return;
    }
    const updatedDocument = await creditHttp.changeDocumentStatus({
      creditId,
      document,
      status,
    });
    hideModal();
    notification.success({ message: 'Status updated!' });
    setDocument(updatedDocument);
  });

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  useEffect(() => {
    const options = [];
    for (const [key, label] of Object.entries(documentStatusMap)) {
      options.push({ key, label });
    }

    setStatusOptions(options);
  }, [documentStatusMap, document?.doctypeId]);

  if (!document) {
    return null;
  }

  return (
    <>
      <Button onClick={showModal} variant="outlined" color="secondary"
        className={cls.btnChange} disabled={!editable}>
        <span className={cls.btnChangeText}>Status Changed</span>
        <svg xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none">
          <path d="M6.02586 6.99931C6.23088 6.99423 6.42667 6.90904 6.57554 6.76016L11.7473 1.50832C11.9597 1.29066 12.0468 0.969849 11.9756 0.666227C11.9043 0.362605 11.6852 0.121895 11.4011 0.0347553C11.117 -0.0523848 10.8104 0.02694 10.5965 0.243422L6 4.91381L1.40345 0.243423C1.18956 0.0269408 0.882954 -0.0523839 0.598879 0.0347562C0.314434 0.121896 0.0957454 0.362606 0.0244497 0.666228C-0.046846 0.96985 0.0403342 1.29067 0.252744 1.50832L5.42446 6.76016C5.58626 6.92233 5.80311 7.00869 6.02549 6.99931L6.02586 6.99931Z"
            fill="black" />
        </svg>
      </Button>
      <Modal
        open={isModalVisible}
        title="Change status of document"
        onCancel={hideModal}
        footer={[
          <Button key="1" variant="contained" color="primary" disabled={loading} onClick={submit}>
            Save status
          </Button>,
        ]}
      >
        <Select onChange={changeStatus} className={cls.select} value={status}>
          {statusOptions.map(opt => (
            <Select.Option value={opt.key} key={opt.key}>
              {opt.label}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};
