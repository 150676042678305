import { useRecordContext, Link } from 'react-admin';
import { Col } from '@components';

interface OrderLinkOption {
  source: string;
  label: string;
  linkPrefix?: string;
  linkText: string;
  linkTarget: string;
}

export const OrderLinkField = (props: OrderLinkOption) => {
  const { source, label, linkPrefix, linkText, linkTarget } = props;
  const record = useRecordContext();

  if (!record || !record[source]) {
    return null;
  }

  return (
    <Col label={label}>
      <Link to={`/${linkPrefix ? linkPrefix : ''}${record[source][linkTarget]}`}>
        {record[source][linkText]}
      </Link>
    </Col>
  );
};
