import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';

import { InitialPage, InitialCard, InitialJson } from '@components';
import { User } from '@types';
import { ProfileLink } from '@pages/user/ProfileLink';
import { dateFormatter } from '@utils';

export const UserHubspotPage = ({ user }: {user: User}) => {
  const cls = useStyles();
  const backTo = `/user/${user.id}?initial`;

  return (
    <InitialPage title="Hubspot Export" backTo={backTo} backText="Back to Individual User" gap={24}>
      <Row gutter={[25, 25]}>
        <Col xs={24} sm={24} md={12}>
          <InitialCard left="Profile" autoHeight extraPadding>
            <div className={cls.block}>
              <ProfileLink
                url={user.hubspotLink}
                text={user.hubspotLink ? `Link to Profile # ${user.hubspotId}` : '–'}
              />
            </div>
            <Row gutter={[30, 30]} className={cls.footer}>
              Hubspot exported at:
              <span className={cls.footerDate}>
                {user.hubspotAt ? dateFormatter.toDateTime(user.hubspotAt, true) : '–'}
              </span>
            </Row>
          </InitialCard>

          <InitialJson title="Hubspot request" json={user.hubspotRequest} mt={24} />
        </Col>

        <Col xs={24} sm={24} md={12}>
          <InitialJson title="Hubspot response" json={user.hubspotResponse} />
        </Col>
      </Row>
    </InitialPage>
  );
};

const useStyles = makeStyles({
  block: {
    marginTop: 15,
  },
  footer: {
    marginTop: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerDate: {
    color: '#000',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 16,
    marginLeft: 20,
    fontWeight: 400,
  },
});
