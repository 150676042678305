import { Http } from './http';
import { DocumentFile } from '@types';

class S3Http extends Http {

  async uploadFile(fileObj: File, isPublic = false): Promise<DocumentFile> {
    const formData = new FormData();
    formData.append('file', fileObj);

    const res = await this.post<DocumentFile>(`/s3?isPublic=${isPublic ? '1' : '0'}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    return res.data;
  }

  async uploadFinanceFile(fileObj: File): Promise<DocumentFile> {
    const formData = new FormData();
    formData.append('file', fileObj);

    const res = await this.post<DocumentFile>('/s3?isFinance=1', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    return res.data;
  }
}

export const s3http = new S3Http();
