import { Create, CreateProps, SimpleForm } from 'react-admin';
import {
  Row,
  Col,
  Container,
  TextInput,
  BooleanInput, Forbidden, ManyInput,
} from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

export const RoleCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps}>
      <SimpleForm>
        <Container max>
          <Row>
            <Col><TextInput source="name" required autoFocus /></Col>
            <Col>
              <BooleanInput source="isEnabled" defaultValue={true} />
            </Col>
            <Col />
            <Col />
          </Row>
          <Row>
            <Col flex={4}>
              <ManyInput
                source="permissionIds"
                reference="permission?method=ALL"
                label="Permissions"
                className="multirow"
              />
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
