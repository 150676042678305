import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNotify, useRedirect, useRefresh } from 'react-admin';
import { clsx } from 'clsx';
import { Col, notification, Row } from 'antd';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { axiosErrorToString } from '@utils';
import { userHttp } from '@network';
import { Address, User } from '@types';
import { useDebounce } from '@hooks';
import { InitialPage } from '@components';
import { InputText } from '@components/inputs/InputText';
import cls from '@pages/user/UserAddresses/user-addresses.module.css';

interface UserAddressesListProps {
  user: User;
  withNewAddress?: boolean;
}

interface UserAddressesItemProps {
  location: Address | null;
  number?: number;
  setActiveAddressId?: (arg: number | null) => void;
  setIsDialogOpen?: (arg: boolean) => void;
  add: (values: UserAddress) => void;
  update: (values: UserAddress) => void;
}

type UserAddress = Pick<Address, 'postalCode' | 'state' | 'city' | 'neighbourhood' | 'street' | 'house' | 'apartment' | 'extended'>

const google_api_key = process.env.REACT_APP_GOOGLE_MAPS_API as string;
const initialLocation: UserAddress = {
  postalCode: '',
  state: '',
  city: '',
  neighbourhood: '',
  street: '',
  house: '',
  apartment: '',
  extended: '',
};

const UserAddressesItem = ({
  location, number, setActiveAddressId, setIsDialogOpen, add, update,
}: UserAddressesItemProps) => {
  const notify = useNotify();

  const [values, setValues] = useState<UserAddress>(location || initialLocation);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'postalCode') {
      const postalCode = e.target.value.replace(/\D/g, '');
      setValues({
        ...values,
        postalCode: postalCode.slice(0, 5),
      });

      return;
    }

    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const debouncedPostalCode = useDebounce(values.postalCode, 500);

  const isNeighbourhoodOptional = debouncedPostalCode?.length === 5 && !values.neighbourhood;

  const submit = () => {
    if (values.postalCode?.length === 0
        || values.state?.length === 0
        || values.city?.length === 0
        || isNeighbourhoodOptional ? false : values.neighbourhood?.length === 0
        || !values.street
        || values.street?.length === 0
        || values.house?.length === 0
    ) {
      setShowErrors(true);
      notify('Please, fill required fields', { type: 'error' });

      return;
    }
    isNewAddress ? add(values) : update(values);
  };

  useEffect(() => {
    const onPostalCodeChange = () => {
      const postalCode = values.postalCode || [];

      if (postalCode.length === 5) {
        const getByCode = async () => {
          try {
            const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=mexico%20${postalCode}&key=${google_api_key}&region=MX&language=es`);

            return res.json();
          } catch (e) {
            console.error(e);
          }
        };

        getByCode().then(async (res) => {
          const [data] = res?.results || [];

          if (data) {
            const { address_components } = data;
            const components = address_components.filter((i: any) => !i.types.includes('country'));

            if (components.length) {
              const state = address_components?.find((i: any) => i.types.includes('administrative_area_level_1'));
              const municipality = address_components?.find((i: any) => i.types.includes('locality'));
              const neighbourhood = address_components?.find((i: any) => i.types.includes('sublocality'));

              setValues({
                ...values,
                state: state?.long_name || '',
                city: municipality?.long_name || '',
                neighbourhood: neighbourhood?.long_name || '',
              });
            } else {
              notification.error({ message: 'Zip code not found. Please type a correct zip code.', duration: 5 });
            }
          }
        });
      }
    };

    onPostalCodeChange();
  }, [debouncedPostalCode]);

  const isNewAddress = !location;

  const showPostalCodeError = values.postalCode?.length === 0 && showErrors;
  const showCityError = values.city?.length === 0 && showErrors;
  const showStateError = values.state?.length === 0 && showErrors;
  const showNeighbourhoodError = isNeighbourhoodOptional
    ? false
    : values.neighbourhood?.length === 0 && showErrors;
  const showStreetError = !values.street?.length && showErrors;
  const showHouseError = values.house?.length === 0 && showErrors;
  const labelWidth = 150;

  return (
    <Col xs={24} sm={12} md={12}>
      <div className={cls.address}>
        <div className={cls.addressTitle}>
          {isNewAddress ? 'New address' : `Address #${number}`}
        </div>

        <div className={cls.addressForm}>
          <InputText
            name="postalCode"
            label="Zip code"
            labelWidth={labelWidth}
            value={values.postalCode || ''}
            onChange={onChangeHandler}
            className={clsx(showPostalCodeError && cls.error)}
          />
          <InputText
            name="state"
            label="State"
            labelWidth={labelWidth}
            value={values.state || ''}
            disabled
            className={clsx(showStateError && cls.error)}
          />
          <InputText
            name="city"
            label="Municipality"
            labelWidth={labelWidth}
            value={values.city || ''}
            disabled
            className={clsx(showCityError && cls.error)}
          />
          <InputText
            name="neighbourhood"
            label="Neighbourhood"
            labelWidth={labelWidth}
            value={values.neighbourhood || ''}
            placeholder={isNeighbourhoodOptional ? 'Is not specified for this zip code' : ''}
            disabled
            className={clsx(showNeighbourhoodError && cls.error)}
          />
          <InputText
            name="street"
            label="Street"
            labelWidth={labelWidth}
            value={values.street || ''}
            onChange={onChangeHandler}
            className={clsx(showStreetError && cls.error)}
          />
          <InputText
            name="house"
            label="House number"
            labelWidth={labelWidth}
            value={values.house || ''}
            onChange={onChangeHandler}
            className={clsx(showHouseError && cls.error)}
          />
          <InputText
            name="apartment"
            label="Apartment number"
            labelWidth={labelWidth}
            value={values.apartment || ''}
            onChange={onChangeHandler}
          />
          <InputText
            name="extended"
            label="Special indications"
            labelWidth={labelWidth}
            value={values.extended || ''}
            onChange={onChangeHandler}
          />

          <div className={cls.addressActions}>
            <Button variant="contained" className={cls.addressSave} onClick={submit}>
              {isNewAddress ? 'Add address' : 'Save address'}
            </Button>

            {!isNewAddress && (
              <Button variant="outlined" className={cls.addressRemove} onClick={() => {
                setActiveAddressId && setActiveAddressId(location?.id || null);
                setIsDialogOpen && setIsDialogOpen(true);
              }}>
                Remove address
              </Button>
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};

export const UserAddressesList = ({ user, withNewAddress }: UserAddressesListProps) => {
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [activeAddressId, setActiveAddressId] = useState<number | null>(null);

  const backTo = `/user/${user?.id}`;
  const locations = user?.location?.sort((a, b) => (a.id || 0) - (b.id || 0)) || [];

  const addNewAddress = () => {
    redirect(`/user/${user.id}?addresses=new`);
  };

  const add = async (values: UserAddress) => {
    const addressValues = Object.values(values).filter(Boolean) || [];

    if (addressValues.length === 0) {
      notification.error({ message: 'Fill in at least one field', duration: 5 });
      return;
    }

    try {
      await userHttp.addAddress({ userId: user.id, location: values });
      notification.success({ message: 'Address has been added', duration: 5 });
      redirect(`/user/${user.id}?addresses`);
      refresh();
    } catch (err: any) {
      const { data } = err.response;
      notification.error({ message: data.description || 'Address save error', duration: 5 });
    }
  };

  const update = async (values: UserAddress) => {
    try {
      await userHttp.updateAddressById({ location: values });
      notification.success({ message: 'Address has been saved', duration: 5 });
      refresh();
    } catch (err: any) {
      const { data } = err.response;
      notification.error({ message: data.description || 'Address save error', duration: 5 });
    }
  };

  const remove = async (locationId: number | null) => {
    if (locationId) {
      try {
        await userHttp.removeAddress(locationId);
        notification.success({ message: 'Address has been removed', duration: 5 });
        refresh();
      } catch (err: any) {
        axiosErrorToString(err);
      } finally {
        setIsDialogOpen(false);
      }
    }
  };

  const showAddAddressButton = !withNewAddress && locations.length < 10;

  return (
    <InitialPage title="Addresses" backTo={backTo} backText="Back to Individual User">
      <Row gutter={[20, 20]} className={cls._}>
        {withNewAddress && (
          <UserAddressesItem location={null} add={add} update={update} />
        )}
        {locations.map((location, index) => (
          <UserAddressesItem
            key={location.id}
            location={location}
            number={index + 1}
            setActiveAddressId={setActiveAddressId}
            setIsDialogOpen={setIsDialogOpen}
            add={add}
            update={update}
          />
        ))}
      </Row>

      {showAddAddressButton && (
        <Button variant="contained" className={cls.add} onClick={addNewAddress}>Add address</Button>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete this address?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>
            No
          </Button>
          <Button onClick={() => remove(activeAddressId)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </InitialPage>
  );
};
