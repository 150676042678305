import { makeStyles } from '@mui/styles';
import { InputNumber } from 'antd';
import { DollarCircleOutlined } from '@ant-design/icons';
import { useEffect, useRef } from 'react';
import { clsx } from 'clsx';

import { SwitchControlled } from '@components';

interface Props {
  amount: number;
  setAmount: (value: number) => void;
  isManualBalance: boolean;
  setManualBalance: (value: boolean) => void;
  editable: boolean;
}

export const BalanceAmount = ({
  amount, setAmount, isManualBalance, setManualBalance, editable,
}: Props) => {
  const cls = useStyes();
  const amountRef = useRef<any>();

  useEffect(() => {
    if (isManualBalance && editable) {
      setTimeout(() => amountRef.current?.focus(), 100);
      setTimeout(() => amountRef.current?.focus(), 300);
    }
  }, [isManualBalance, editable]);

  return (
    <div className={cls.box}>
      <SwitchControlled
        value={isManualBalance}
        onChange={editable ? setManualBalance : undefined}
      />
      <div className={cls.title}>Override Balance amount</div>
      <InputNumber
        ref={amountRef}
        prefix={<DollarCircleOutlined className={cls.amountIcon} />}
        value={amount}
        controls={editable ? undefined : false}
        onKeyDown={editable ? undefined : e => e.preventDefault()}
        onChange={cost => setAmount(+`${cost}`)}
        className={clsx(cls.amountInput, !isManualBalance && cls.amountHidden)}
        min={-999999999} max={999999999}
      />
    </div>
  );
};

const useStyes = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: -80,
  },
  title: {
    fontSize: 18,
    marginLeft: 15,
    marginRight: 15,
  },
  amountInput: {
    width: 160,
    height: 37,
    lineHeight: '37px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    overflow: 'hidden',
  },
  amountIcon: {
    color: '#CCC',
    marginRight: 10,
  },
  amountHidden: {
    visibility: 'hidden',
    opacity: 0,
  },
});
